.btn {
  font-weight: 500;

  &:focus {
    outline: 0;
  }
}

.btn-lg {
  font-size: 115%;
  i {
    font-size: 125%;
    padding-right: 5px;
  }
}

.btn-link {
  &.red {
    color: $red-700;
    &[disabled] {
      color: $red-500;
    }
  }

  &[disabled] {
    color: $gray-500;
  }

  &.btn-link-icon {
    i {
      margin-right: 2px;
    }
  }
}

.btn-icon {
  i {
    margin-right: 2px;
  }

  &:hover {
    color: $gray-500;
  }
}

.btn-no-padding {
  padding: 0;
}

.btn.btn-link,
.btn.btn-link:focus,
.btn.btn-link:hover,
.btn.btn-link:active {
  outline: 0;
}

// FIMXE: This adjusts padding for default, non-link, non-custom buttons only.
// Remove the need for this kind of specificity.
.btn:not(.btn-xs):not(.btn-sm):not(.btn-lg) {
  &.btn-default,
  &.btn-primary,
  &.btn-info,
  &.btn-success,
  &.btn-warning,
  &.btn-danger,
  &.btn-light,
  &.btn-dark &.btn-error {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.btn-light-inverse {
  @include button-variant($gray-A1, $gray-400, $btn-light-border);
}

button.screenshots-button {
  border-top: 1px solid $gray-50;
  border-right: 1px solid $gray-50;
  padding: 10px 15px;
  display: block;
  line-height: 2em;
  cursor: pointer;
  font-size: 0.9em;

  &:hover,
  &:focus,
  &:active,
  &:active:hover,
  &:focus:hover {
    border-top: 1px solid $gray-100;
    border-right: 1px solid $gray-100;
    text-decoration: none;
    background-color: $gray-50;
  }

  &:last {
    border-right: 0;
  }

  &:disabled,
  &[disabled],
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active,
  &:disabled:active:hover,
  &:disabled:focus:hover {
    color: $gray-500;
    cursor: disabled;
    background-color: $gray-A1;
  }
}
