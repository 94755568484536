.projects-nav {
  border-right: 1px solid $gray-100;
  background-color: $gray-50;
}

#run-show > .empty {
  margin-left: 24px;
  margin-right: 24px;
}

.run-tabs-wrapper {
  padding-left: 24px;
  padding-right: 24px;
}

#project-settings {
  .project-run-delay {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .project-run-timeout {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .run-cancel-config-span {
    width: 20em;
    margin-left: auto;
    right: 0;
    .project-run-autofail-config {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.private-lock {
  color: $gray-200;
}

.projects-nav-placeholder {
  height: 58px;
}

.transfer-owner-modal {
  .modal-body {
    p {
      margin: 0 16px 16px;
    }
  }
}

#project-setup {
  margin-bottom: 24px;

  .panel-body h3 {
    margin: 0 0 30px;
  }
}
