.create-org {
  background-color: #fff;
  .page-header stacked-layout--header {
    width: 100%;
  }
  .create-org-inner {
    width: 90%;
    max-width: 700px;
    margin: 0 auto;
  }
  .h3 {
    margin-top: 64px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 45px;
    color: #000;
  }
  .h5 {
    margin-top: 0;
    margin-bottom: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $gray-600;
  }
  .create-org-form {
    .input-form-group-container .input-form-group {
      grid-template-columns: 1fr;
      border: none;
    }

    .input-form-group-container .danger {
      background-color: transparent;
    }

    .input-form-group > div {
      margin: 0;
    }

    .modal-footer {
      border: none;
      padding-left: 0;
      padding-right: 0;
    }

    .cancel-button {
      display: none;
    }

    .create-button {
      width: 100%;
      margin-bottom: 20px;
      margin-left: 0;
    }
  }
}

@media (max-width: 700px) {
  .create-org {
    .h3 {
      margin-top: 32px;
    }
    .h5 {
      margin-bottom: 16px;
    }
  }
}
