@import '@frontend/design-system/src/styles/_variables';

.test-results-action-bar {
  :global {
    svg {
      height: 16px;
      width: 16px;
    }
  }
}

.testReplayIcon {
  > div:first-child {
    background-color: $indigo-500 !important;
    outline: none;
    color: $gray-600;

    path {
      fill: $gray-A1;
    }
  }
  &:hover,
  &:active {
    > div:first-child {
      background-color: $indigo-700 !important;
    }
  }
  &[disabled] {
    > div:first-child {
      background-color: $gray-50 !important;
      path {
        fill: $gray-300 !important;
      }
    }
  }
}

.videoIcon {
  path {
    stroke: currentColor;
  }
}

.screenshotIcon {
  &[disabled] {
    path {
      fill: $gray-200 !important;
    }
  }
}

.outputIcon {
  path.icon-light {
    fill: currentColor;
  }
}

.jiraIcon {
  &[disabled] {
    path {
      fill: $gray-300;
    }
  }
}
