@import '@frontend/design-system/src/styles/_variables';

.notification-setting-filter-toggle {
  font-size: 14px;
  span {
    font-weight: 600;
  }
  button.filter.dropdown-toggle {
    @extend .noshadow-filter;
  }
  .notification-setting--wider {
    min-width: 216px;
  }
}
