@import '@frontend/design-system/src/styles/_variables';

.container {
  padding: 16px;
  border-bottom: 1px solid $gray-100;
  pre:last-of-type {
    margin-bottom: 0;
  }
}

.lastContainer {
  border-bottom: none;
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px 0;
  i {
    margin: 0 8px 0 0;
  }
  h5 {
    margin: 0;
    color: $red-500;
    font-weight: 600;
  }
  small {
    color: $gray-600;
  }
}
