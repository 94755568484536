#project-settings {
  margin-left: 0;
  margin-right: 0;
  padding: 24px 24px 40px;

  // Adjust bottom padding for pendo badge.
  .pendo-active & {
    padding-bottom: 54px;
  }
}

#project-settings,
.readme-config-modal {
  code.purple-code {
    color: $purple-600;
    background-color: $purple-100;
    border: none;
  }

  code {
    background-color: $gray-100;
    border-radius: 3px;
    color: $gray-600;
    font-family: $font-mono;
    padding: 3px;
    border: 1px solid $gray-200;
  }

  .warning {
    color: $red-700;

    button,
    button:hover,
    button:focus,
    button:active {
      border-color: $red-700;
      color: $red-700;
    }
  }
}

.project-settings-heading {
  background-color: $gray-50;
  display: flex;
  h3 {
    margin: 0 8px 0 0;
  }
  a {
    font-size: 13px;
  }
}

.project-settings-savings-wrapper {
  border-top: 1px solid $gray-200;
  padding: 16px;
}

.transfer-orgs-list,
.transfer-users-list {
  @include list-columns(100%);
  -webkit-padding-start: 0;

  & > li {
    overflow: auto;
    list-style: none;
    width: 100%;

    label {
      width: 100%;
      cursor: pointer;
    }

    .radio {
      margin: 0;
      padding: 10px;
      cursor: pointer;

      input[type='radio'] {
        margin: 3px 0 0 -19px;
      }
    }
  }
}

.users-access-list {
  -webkit-padding-start: 0;
  padding-bottom: 16px;
  display: inline-block;

  img {
    border: 1px solid $gray-A1;
    margin-right: -12px;
    &:last-of-type {
      margin-right: 8px;
    }
  }

  div:last-child {
    margin-top: 8px;
  }
}

.user-tooltip {
  .tooltip-name {
    line-height: 16px;
    vertical-align: bottom;
  }
}

.readme-config-footer {
  text-align: left;

  .label {
    display: inline-block;
    color: $gray-800;
    font-size: 16px;
    font-weight: normal;
    padding-left: 0;
    margin: 0;

    .copy-markdown {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .description {
    color: $gray-600;
    font-size: 13px;
  }

  .markdown {
    background-color: $gray-100;
    border-radius: 3px;
    color: $gray-600;
    font-family: $font-mono;
    padding: 10px;
    border: 1px solid $gray-200;
    margin-top: 10px;
    word-wrap: break-word;
  }
}

.badge-preview .input-form-group-contents {
  display: block !important;
}

.configure-badge-disabled {
  pointer-events: none;
}

.text-muted {
  code {
    color: $purple-600 !important;
    background-color: $purple-100 !important;
  }
}

.section-heading {
  color: $gray-600;
  font-size: 14px;
  font-weight: 600;
}

.cy-status-warning-icon {
  color: $orange-400;
  margin-right: 8px;
}

.status-check-help-icon {
  margin-left: 8px;
  color: $indigo-100;
  .question-mark-path {
    fill: $indigo-500;
  }
}
