.committers-filter {
  &__label {
    display: inline-flex;
    align-items: center;
  }
  .user-avatar {
    margin: 0 4px 1px 0;
  }
}

#filter-runs-committers-filter {
  display: flex;
  align-items: center;
}
