.org-uuid {
  font-family: Consolas, Monaco, 'Andale Mono', monospace;
  display: inline-block;
  margin-left: 10px;
}

.input-group-addon.copy-uuid {
  cursor: pointer;
  width: 40px;
  display: inline-block;
  border: 1px solid $gray-200;
  border-radius: 4px;
  padding: 6px 10px;
}
