.loader-page.stacked-layout {
  .stacked-layout--header .stacked-layout--loader {
    width: 300px;
    height: 32px;
    z-index: 100;
  }

  .stacked-layout--content .stacked-layout--loader {
    width: 600px;
    height: 3 * 85px;
    margin: 0;
  }

  .stacked-layout--loader {
    transition: opacity 300ms;
    opacity: 0;
  }

  &.stacked-layout--is-loading {
    .stacked-layout--loader {
      opacity: 1;
    }
  }
}
