$test-results-drawer-spacing: 16px;

.test-results-drawer {
  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
  }
  &__error-container {
    height: 100%;
    display: flex;
    align-items: center;
    background: $gray-50;
  }
  .empty {
    padding: 10px;
    border: none;
    box-shadow: none;
  }
  &__test-result-runtime-env {
    .text-empty-state {
      font-style: normal;
    }
  }
  &__definition {
    .banner {
      padding: 16px;
    }
  }
  &__not-available {
    color: $gray-500;
  }
  &__commit-message {
    margin: 0 0 $test-results-drawer-spacing 0;
    white-space: pre-wrap;
  }
  &__commit-detail-list {
    color: $gray-500;
    .horizontal-list {
      flex-wrap: wrap;
    }
  }
  &__empty-commit-details {
    color: $gray-500;
  }
  &__empty-commit-cta {
    font-weight: 500;
  }
  &__action-bar,
  &__commit-details,
  &__test-result-runtime-env,
  &__artifacts,
  &__performance,
  &__muted,
  &__previous-runs {
    padding: $test-results-drawer-spacing;
  }
  &__muted {
    .muted-body {
      margin: 0 0 8px 0;
    }
    .muted-title {
      font-weight: 500;
      color: $gray-900;
    }
    .muted-subtitle {
      margin: 0;
      font-size: 12px;
      color: $gray-600;
    }
  }
  &__error {
    padding: 8px $test-results-drawer-spacing 8px $test-results-drawer-spacing;
    background: $red-50;
    font-size: 12px;
    font-weight: 500;
    color: $red-800;
  }
  &__error-header {
    display: flex;
    justify-content: space-between;
    color: $red-700;
    padding: 8px $test-results-drawer-spacing 8px $test-results-drawer-spacing;
    .error-header-icon {
      margin: 0 8px 0 0;
    }
    .error-header-impacted-test-cases {
      color: $gray-600;
      b {
        color: $red-700;
      }
    }
  }
  &__error-code-frame {
    background: $red-50;
    padding: 12px;
    .error-codeframe-header {
      background: $gray-50;
      &__file-name {
        padding: 8px;
        word-break: break-all;
        i {
          color: $gray-300;
          padding: 0 8px;
        }
        a {
          align-self: center;
          font-size: 12px;
          color: $gray-800;
          word-break: break-all;
          span {
            padding: 4px;
            color: $gray-500;
          }
        }
        a:hover {
          text-decoration: underline;
          color: $indigo-500;
        }
      }
    }
  }
  &__error-stack {
    padding: 8px $test-results-drawer-spacing 8px $test-results-drawer-spacing;
    background: $red-50;
    font-size: 12px;
    font-weight: 500;
    color: $red-800;
    .stack-toggle {
      padding: 0;
      width: 100%;
      display: flex;
      align-items: center;
      background: transparent;
      border-top: none;
      border-right: none;
      border-bottom: none;
      border-left: none;
      outline: none;
      text-align: left;
      font-size: 13px;
      color: $gray-900;
      &__caret {
        margin: 0 4px 0 0;
        color: $gray-900;
      }
    }
  }
  &__error-summary {
    margin: 0;
    position: relative;
    code {
      background: $red-50;
      padding: 2px;
      border-radius: 4px;
    }
  }
  &__error-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
  }
  &__error-collapse {
    width: 100%;
  }
  &__spec-file {
    margin: 0;
    padding: 0 0 0 2px;
  }
  &__loader {
    padding: 16px;
    text-align: center;
  }
  &__action-bar {
    align-items: flex-start;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
      min-width: 80px;
    }
    border-bottom: 1px solid $gray-200;
  }
}
