.accordion {
  color: $gray-600;
  &__toggle {
    width: 100%;
    display: flex;
    align-items: center;
    background: $gray-A1;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid $gray-200;
    border-left: none;
    padding: 8px;
    outline: none;
    text-align: left;
    font-size: 10px;
  }
  &__chevron {
    margin: -4px 4px 0 0;
  }
}
