.color-breakdown-container {
  padding: 32px 64px;
}

.color-breakdown-row__stat {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.color-breakdown-row__stat--subtle {
  color: $gray-600;
  font-weight: 400;
}

.color-breakdown-row {
  display: flex;
  align-items: center;
  &__container {
    display: inline-block;
    padding: 0;
    width: 100%;
    margin: 0 0 16px 0;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
    &:last-of-type {
      margin: 0;
    }
  }
  &--greyed-out {
    opacity: 0.25;
  }
  &__color {
    flex: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 16px 0 0;
  }
  &__title {
    @extend .color-breakdown-row__stat;
    flex: 8;
    margin: 0;
    text-align: left;
  }
  &__total {
    @extend .color-breakdown-row__stat;
    flex: 2;
    padding: 0 4px;
    display: flex;
    justify-content: flex-end;
  }
  &__total-count {
    margin: 0 4px 0 0;
  }
  &__percentage {
    flex: 1;
    text-align: right;
    min-width: 54px;
    @extend .color-breakdown-row__stat;
    @extend .color-breakdown-row__stat--subtle;
  }
}
