@import '@frontend/design-system/src/styles/_variables';

.valueContainer {
  width: 80%;
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
  i {
    font-size: 22px;
    color: $gray-500;
  }
  input {
    opacity: 1 !important;
  }
}
