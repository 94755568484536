.ll-container {
  background: $gray-1000;
  background: linear-gradient(
    to bottom,
    $gray-1000 0%,
    $gray-900 80%,
    $gray-800 100%
  );
  position: relative;
  overflow: hidden;
  will-change: transform;

  .ll-recommendations {
    &.is-collapsed {
      .ll-recommendation {
        display: none;
      }
    }

    .ll-recommendation {
      display: flex;
      color: $gray-50;

      .parallel-table {
        border-left: 1px solid $gray-900;
        width: 66.66%;

        table {
          width: 100%;
        }

        th {
          padding: 5px 12px;
          font-size: 0.9em;
          text-transform: uppercase;
          color: $gray-500;

          .btn-close {
            color: $gray-500;
            transition: color 0.2s ease;
            font-weight: 500;

            &:hover {
              text-decoration: none;
              color: $gray-100;
            }
          }

          &:last-child {
            text-align: right;
          }
        }

        tr {
          border-bottom: 1px solid $gray-900;
          width: 100%;
          color: $gray-500;

          &:last-child {
            border-bottom: 0;
          }

          td {
            padding: 8px 12px;
            font-size: 0.9em;

            .label {
              color: $gray-900;
              background-color: $gray-100;
              text-transform: uppercase;
              font-weight: 500;
              position: relative;
              top: -2px;
            }

            &:last-child {
              padding-left: 0;
            }
          }

          &.parallelization-recommendation--current {
            color: $gray-200;
            font-weight: 500;

            .label {
              background-color: $gray-200;
            }

            progress::-webkit-progress-value {
              background: $gray-100;
            }
          }

          &.parallelization-recommendation--fastest {
            color: $jade-400;
            font-weight: 500;

            .label {
              background-color: $jade-400;
            }

            progress::-webkit-progress-value {
              background: $jade-300;
            }
          }

          progress {
            height: 12px;
            -webkit-appearance: none;
            border: none;

            &::-webkit-progress-bar {
              background: $gray-900;
              border-radius: 2px;
              padding: 1px;
            }

            &::-webkit-progress-value {
              border-radius: 2px;
              background: $gray-600;
            }
          }
        }
      }
    }
  }

  .ll-max-machines-message {
    font-style: italic;
  }

  // Drawer animations
  //
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }

  $ll-duration: 300ms;
  .ll-fade-container {
    animation-duration: $ll-duration;
    animation-fill-mode: forwards;

    top: 0;
    left: 0;
  }
  .ll-hidden {
    animation-name: fadeOut;
    max-height: 0;
    opacity: 1;
    overflow: hidden;
  }
  .ll-visible {
    animation-name: fadeIn;
    opacity: 0;
  }

  .ll-expand-drawer {
    transform-origin: top left;
    transition: all 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .ll-expand-drawer-enter {
    opacity: 0.01;
    transform: scaleY(0.9);
    max-height: 50px;
  }
  .ll-expand-drawer-enter-active {
    opacity: 1;
    transform: scaleY(1);
    max-height: 600px;
  }
  .ll-expand-drawer-exit {
    opacity: 1;
    transform: scaleY(1);
    max-height: 600px;
  }
  .ll-expand-drawer-exit-active {
    opacity: 0.01;
    transform: scaleY(0.9);
    max-height: 0px;
  }
}
