.github-integration-organization {
  overflow-x: visible;
  h4 {
    margin-top: 32px;
  }

  .github-card-footer {
    padding: 16px 0;
    border-top: 1px solid $gray-100;
  }

  .table {
    text-align: left;
    margin: 8px 0 0;
    max-width: 100%;
    table-layout: fixed;

    tbody {
      tr {
        min-height: 48px;
      }
    }

    thead,
    tbody {
      $configure-width: 160px;

      th,
      td {
        padding: 8px;

        &:first-child {
          padding-left: 0 !important;
        }
        &:last-child {
          padding-right: 0 !important;
        }
      }

      th:last-child,
      td:last-child {
        text-align: right;
        width: $configure-width;
      }
      .repo-url {
        color: rgba(55, 55, 60, 0.5);
        font-size: 13px;
        line-height: 13px;
      }
      .truncate-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
      }
    }
  }
}

.repos-popover {
  line-height: 24px;

  a {
    cursor: pointer;
  }

  .view-more {
    width: 100%;
    text-align: center;
  }
}

.sample-status-checks-popover {
  border-radius: 4px;
  padding: 0;
  width: 264px;
  min-height: 100px;
  .popover-content {
    padding: 0;
  }
  .sample-status-checks-popover--row {
    padding: 8px;
    font-size: 13px;
    &.info {
      color: $gray-600;
    }
    &.status-check {
      border-top: 1px solid $gray-200;
      border-bottom: 1px solid $gray-200;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $gray-600;
      .cy-cypress-icon {
        color: $gray-1000;
      }
    }
    &.more-checks {
      text-align: center;
    }
  }
  .alert {
    margin-bottom: 0;
    border-radius: 0;
    display: grid;
    grid-template-columns: 22px auto;
    align-items: center;
  }
}

.sample-status-checks-help {
  display: flex;
  cursor: pointer;
  .fa-question-circle {
    margin-left: 4px;
  }
}

.github-landing-after-installation {
  text-align: center;
}

.info-link-icon {
  &:hover {
    text-decoration: none !important;
  }
}
