@import '@frontend/design-system/src/styles/_variables';

.artifactControls {
  list-style-type: none;
  color: $gray-600;

  display: flex;
  align-items: stretch;
  gap: 8px;

  height: 32px;

  li {
    flex: 0 0 auto;
  }
}

button.squareButton {
  color: $gray-600;

  &:disabled {
    color: $gray-300;
  }
}

.disabledBtn {
  background-color: white !important;
  color: $gray-300 !important;
  border-color: $gray-100 !important;
  cursor: default !important;

  &.testReplayBtn path {
    fill: $gray-400 !important;
  }

  &.testReplayBtn:hover {
    border-color: $gray-200 !important;
  }
}

.select-option {
  width: 100%;
  height: 22px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: $gray-900;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  svg {
    color: $gray-600;
    fill: $gray-100 !important;
  }
  &:hover {
    color: $indigo-600;
    svg {
      color: $indigo-400;

      :global {
        .icon-fill-light {
          fill: $indigo-100;
        }
      }
    }
  }
}

.disabledArtifact {
  color: $gray-600;
  cursor: default;
  svg {
    color: $gray-400;
  }
  .testReplayBtn {
    path {
      fill: $gray-400;
    }
  }
  &:hover {
    color: $gray-600;
    svg {
      color: $gray-400;
    }
  }
}

.videoArtifact {
  &:hover {
    color: $indigo-600;
    path {
      stroke: $indigo-400;
    }
  }
}

.disabledVideoArtifact {
  color: $gray-600;
  cursor: default;
  path {
    stroke: $gray-400;
  }
  &:hover {
    color: $gray-600;
  }
}

.moreSelect {
  :global {
    .single-select-custom-element {
      border-color: $gray-100 !important;

      transition-property: box-shadow;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;

      &:hover {
        box-shadow: 0 0 #0000, 0 0 #0000, 0 0 0 2px $gray-100 !important;
        border-color: $gray-200 !important;
      }

      &:focus-within,
      &:focus {
        box-shadow: 0 0 #0000, 0 0 #0000, 0 0 0 3px $gray-100 !important;
        border-color: $gray-200 !important;
      }
    }

    .single-select-custom-element {
      height: 32px;

      .custom-control {
        min-height: 0;
        height: 100%;

        .custom-value-container {
          height: 100%;
          width: 32px;
          padding: 0 !important;

          .custom-placeholder,
          .custom-single-value {
            left: 50%;
            transform: translate3d(-50%, -50%, 0) !important;
            margin: 0px !important;
          }

          .custom-single-value {
            width: auto !important;
            min-width: 0;
            max-width: 32px;
          }
        }
      }
    }

    .custom-opts-container {
      border: none;
      padding: 8px;
    }
  }
}

.artifactControls.variant-large {
  li {
    button {
      &:hover {
        box-shadow: 0 0 0 2px $gray-100;
      }
    }
  }

  .disabledBtn.testReplayBtn:hover {
    border-color: $gray-200 !important;
  }
}

.artifactControls.variant-small {
  height: 24px;

  .testReplayBtn {
    height: 24px;
    padding: 0 4px;

    color: $gray-600 !important;
    path {
      fill: $gray-600 !important;
    }
  }

  .disabledBtn {
    border-color: $gray-50 !important;

    &.testReplayBtn path {
      fill: $gray-300 !important;
    }

    &:hover,
    &:focus {
      border-color: $gray-50 !important;
      box-shadow: none !important;
    }
  }

  .chevronBtn {
    height: 24px;
    width: 24px;
    padding: 0 4px;
  }

  .moreSelect {
    :global {
      .single-select-custom-element {
        height: 24px;

        &:hover {
          box-shadow: 0 0 #0000, 0 0 #0000, 0 0 0 2px $gray-50 !important;
          border-color: $gray-200 !important;
        }

        &:focus-within,
        &:focus {
          box-shadow: 0 0 #0000, 0 0 #0000, 0 0 0 3px $gray-50 !important;
          border-color: $gray-200 !important;
        }

        .custom-control {
          .custom-value-container {
            width: 24px;
          }
        }
      }
    }
  }
}
