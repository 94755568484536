.integration {
  color: $gray-700;
  box-shadow: none;

  .enterprise-sso {
    margin: -16px;

    .section-heading:first-of-type,
    .section-heading + .input-form-group-container .input-form-group {
      border-top: none;
    }

    .input-group .form-control:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .input-group-btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .btn {
        padding-top: 7px !important;
        padding-bottom: 6px !important;
        background-color: $gray-A1;
        color: $gray-900;
        border: 1px solid $input-border;

        &:hover {
          background-color: $gray-50;
        }
      }
    }
    input[type='file'] {
      padding: 6px 12px;
      border-radius: 4px;
      border: 1px solid $input-border;
    }

    .table {
      margin-bottom: 0;

      th {
        height: auto;
      }
    }
  }
}

.learn-more-link {
  &:hover {
    cursor: pointer;
  }
}

.integrations-page {
  section {
    margin-bottom: 40px;
    h2 {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }
  .panel {
    margin-bottom: 16px;
  }
  hr {
    border-color: $gray-200;
  }

  .chart-empty-state {
    height: 200px;
  }
}

.integrations-page-section {
  h2 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: -15px;
  }
  hr {
    border-color: $gray-50;
  }
  .integration-slat-title {
    color: $indigo-500 !important;
  }
}
.integration-page-v2-icon-container {
  position: relative;
  height: 24px;
  width: 24px;
  flex-shrink: 0;
  svg {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.divider {
  border-right: 1px solid $gray-100;
  height: 38px;
}

@mixin integrations-slat {
  .integrations-slat {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    font-weight: normal;
    column-gap: 16px;
    font-size: 14px;
    color: $gray-600;
    width: 100%;
    border-radius: 4px;
    &.panel-heading {
      background-color: white !important;
    }
    &.with-icon {
      grid-template-columns: 32px 1fr auto;
      img {
        max-width: 32px;
        width: 32px;
      }
    }
    .panel-title {
      font-weight: normal;
      font-size: 20px;
      color: $gray-900;
    }
    .contact-owner {
      font-weight: 500;
    }
  }
}

.integrations-page,
.integration-page {
  @include integrations-slat();
}

.section-container {
  .panel {
    margin-bottom: 16px;
  }
  @include integrations-slat();
  .integrations-slat {
    border-radius: 4px;
    min-height: 64px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    .integration-action-button {
      float: right;
      margin-left: auto;
      flex-shrink: 0;
    }
  }
}
.with-hover-interaction {
  transition: 0.3s ease;
  &:hover {
    cursor: pointer;
    border-color: $indigo-300;
    box-shadow: 0 0 2px 2px $indigo-100;
    transition: 0.3s ease;
  }
}

.ci-section {
  margin-top: 32px;
  border-top: 1px solid $gray-50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ci-info {
    margin-top: 32px;
    color: $gray-600;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 560px;
    text-align: center;
  }
  button {
    margin-top: 24px;
    background-color: $gray-A1;
    border: 1px solid $gray-100;
    color: $indigo-500;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    transition: 0.3s ease;
    .cy-icon {
      color: $gray-500;
      margin-right: 8px;
      .icon-light-fill {
        display: none;
      }
    }
    &:hover,
    &:active,
    &:focus,
    &:visited {
      background-color: $gray-A1 !important;
      outline: none !important;
      border: 1px solid $gray-100;
      color: $indigo-500;
      box-shadow: 0 0 2px 2px $gray-100;
      transition: 0.3s ease;
    }
  }
}

.slat-info {
  h3 {
    font-size: 16px !important;
    color: $gray-700 !important;
    font-weight: 600 !important;
    line-height: 24px !important;
  }
  font-size: 14px !important;
  color: $gray-600 !important;
}

.with-left-border {
  border-left: 1px solid $gray-100;
  padding-left: 17px;
}

.integration-action-button {
  padding: 0 !important;
  height: 32px;
  width: 140px;
  display: flex;
  align-items: center;
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    .icon {
      margin: auto;
    }
  }
  .label-container {
    display: flex;
    align-items: center;
    margin: auto;
  }
  &.enable {
    background-color: $indigo-500;
    border-color: $indigo-600;
    .icon-container {
      border-right: 1px solid $indigo-600;
    }
  }
  &.configure {
    color: $indigo-500;
    background-color: $gray-A1;
    border: 1px solid $gray-100;
    .icon-container {
      border-right: 1px solid $gray-100;
    }
    .icon-dark-fill {
      fill: $gray-400;
    }
    .icon-light-fill {
      fill: $gray-50;
    }
  }
  &.upgrade {
    background-color: $indigo-500;
    border: 1px solid $indigo-600;
    .icon-container {
      border-right: 1px solid $indigo-600;
    }
    .icon-light-fill {
      fill: rgba($gray-A1, 40%);
    }
    &:hover,
    &:focus {
      background-color: $indigo-500;
      color: $gray-A1;
    }
  }
  &.restricted {
    cursor: default;
    background-color: $gray-50;
    color: $gray-500;
    border: 1px solid $gray-100;
    .icon-container {
      border-right: 1px solid $gray-100;
    }
    &:hover,
    &:focus {
      background-color: $gray-50;
      color: $gray-500;
      outline: none;
    }
  }
}

.integration-page {
  h1 {
    width: 100%;
  }
  .panel-title {
    font-weight: 500;
  }
  &__back-link {
    padding-bottom: 24px;
  }
  .beta-badge {
    font-weight: normal;
  }

  &__installation-info {
    color: $gray-900;
    .fa-check {
      color: $jade-300;
    }
  }
}

.slack-integration-organization {
  padding: 24px;
}

.restricted-button {
  // fake disabling the button because of https://github.com/react-component/tooltip/issues/200
  cursor: not-allowed;
  opacity: 0.65;
  &__level {
    text-transform: capitalize;
  }
}

.integrations-page {
  section {
    margin-bottom: 40px;
    h2 {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }
  .panel {
    margin-bottom: 16px;
  }
  hr {
    border-color: $gray-200;
  }

  .chart-empty-state {
    height: 200px;
  }
}

.integrations-slat {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-weight: normal;
  column-gap: 16px;
  font-size: 14px;
  color: $gray-600;
  width: 100%;
  &.with-icon {
    grid-template-columns: 32px 1fr auto;
    > img,
    > svg {
      max-width: 32px;
      width: 32px;
      height: 32px;
      margin-top: 4px;
    }
    &.continuous-integration-slat > svg {
      color: $purple-700;
    }
  }
  .panel-title {
    font-weight: normal;
    font-size: 20px;
    color: $gray-900;
  }
}

.integration-page {
  h1 {
    width: 100%;
  }
  .panel-title {
    font-weight: 500;
  }
  &__back-link {
    padding-bottom: 24px;
  }
  .beta-badge {
    font-weight: normal;
  }
}

.slack-integration-organization {
  padding: 24px;
}

.copy-field-input-group {
  float: right;
  margin-left: auto;
  flex-shrink: 0;
  .copy-btn {
    padding: 6px !important;
    padding-bottom: 6px !important;
  }
}
