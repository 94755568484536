@import '@frontend/design-system/src/styles/_variables';

.newBadge {
  background: $orange-500;
  padding: 4px;
  border-radius: 2px;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
}
