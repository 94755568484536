.well {
  padding: 8px 16px;
  margin-bottom: 0px;
  background-color: $gray-50;
  border-color: $gray-100;
  box-shadow: none;

  .queue-msg {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
