.ms-teams-integration-notification-setting {
  min-width: 115px;
  button.filter.dropdown-toggle {
    @extend .unstyled-filter;
  }
  .open {
    button.filter.dropdown-toggle {
      @extend .unstyled-filter--open;
    }
  }
  .ms-teams-install--wider {
    min-width: 216px;
  }
}
