@import '@frontend/design-system/src/styles/_variables';

$perimeter-padding: 16px;

.container {
  width: 100%;
  height: 64px;
  background-color: $gray-1000;
  color: $gray-300;
  border-radius: 4px 0;
  padding: 0 16px;
  width: 100%;
  min-height: 64px;
  flex-wrap: wrap;

  span {
    display: inline-flex;
  }

  div[class*='runNumberContainer'] {
    gap: 6px;
  }

  span[class*='item-divider'] {
    color: $gray-700;
    margin: 0 8px !important;
  }

  div[class*='branchNameContainer'] {
    gap: 5px;
  }
}

.listItem {
  display: inline-flex;
  align-items: center;
}

.runInfoOverride > div > div {
  padding: 0 12px;

  div:nth-child(2) {
    margin: 4px 16px;
  }
}

.textPadding {
  padding-left: 6px;
}

.os {
  svg {
    g {
      path {
        fill: $gray-500;
      }
    }
  }
}

.browser {
  li {
    background-color: $gray-1000;
  }
}
