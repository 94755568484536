@import '@frontend/design-system/src/styles/_variables';

.configuration-changes-tooltip {
  .rc-tooltip-inner {
    background-color: $gray-A1;
    padding: 12px 16px;
  }

  .rc-tooltip-arrow {
    border-bottom-color: $gray-A1;
    filter: drop-shadow(0px -1px 1px $gray-400);
  }

  .cy-icon {
    color: $gray-500;

    &.right-arrow {
      color: $gray-400;
      width: 12px;
    }

    &.cy-dot-large-icon {
      color: currentColor;
      fill: currentColor;
      margin-right: 4px;
      width: 7px;
    }
  }

  th,
  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-left: 0;
    padding-bottom: 12px;
  }

  tbody {
    tr:last-of-type {
      td,
      th {
        padding-bottom: 0;
      }
    }
  }

  td {
    color: $gray-600;
  }
  th {
    color: $gray-700;
    .cy-icon {
      margin-right: 8px;
    }
  }

  caption {
    font-size: $font-base-size + 2;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 12px;
    padding-top: 0;
    white-space: nowrap;
    color: $gray-700;

    .title {
      padding: 1px 0;
    }

    .build-numbers {
      font-size: $font-base-size;
      display: inline-block;
      padding-left: 10px;
      line-height: 20px;
      .build {
        padding: 1px 4px;
        border: 0.5px solid $gray-100;
        border-radius: 4px;
        font-weight: 500;
      }
      .ellipsis {
        font-weight: 400;
      }
    }
  }

  $icon-offset: 24px;

  .unique-group {
    th {
      padding-left: $icon-offset;
    }
  }

  tr.groups-hidden {
    td {
      padding-left: $icon-offset;
      color: $gray-700;
    }

    .num-groups-hidden {
      font-size: $font-base-size - 2;
      line-height: 16px;
      background-color: $gray-50;
      border-radius: 4px;
      padding: 2px 4px;
    }
  }

  th.group {
    padding-left: 8px;

    span {
      border-left: 1px dotted $gray-300;
      padding: 8px 0 8px 16px;
    }
  }
}
