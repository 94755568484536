.container {
  align-self: baseline;
  font-size: 15px;
  :global(.cy-icon) {
    margin-right: 8px;
    vertical-align: middle;
  }
}

.outlineButton {
  background-color: transparent;
}
