@import '@frontend/design-system/src/styles/_variables';

#add-webhook-modal {
  h3,
  label,
  input {
    color: $gray-900;
  }
  input {
    border-color: $gray-100;
  }
  form {
    overflow: visible;
  }
  .modal-body {
    padding-top: 24px;
    padding-bottom: 14px;
  }
  .input-form-group {
    font-size: 16px;
    line-height: 24px;
    padding-top: 0;
    border-top: none;
    grid-template-columns: 1fr;
    input {
      height: 48px;
      font-size: 16px;
    }
  }
  .form-control:focus {
    border: 1px solid $indigo-300;
  }
  .left-column,
  .input-form-group-contents {
    margin: 0;
  }
  .description {
    margin-bottom: 4px;
  }
  #notification-scope-dropdown {
    [class$='indicatorSeparator'] {
      display: none;
    }
    [class$='control'],
    [class$='option'] {
      cursor: pointer;
    }
  }
  .modal-footer {
    background-color: $gray-50;
    border-radius: 0px 0px 4px 4px;
  }
  .cancel-button {
    background-color: transparent;
  }
  .ms-teams-integration-notification-setting {
    .filter__icon {
      display: none;
    }
    .dropdown {
      width: 100%;
      border: 1px solid $gray-100;
      border-radius: 4px;
    }
    .dropdown.open {
      border: 1px solid $indigo-300;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
        0 0 8px rgb(100 112 243 / 60%);
      .filter__dropdown-caret {
        transform: rotate(180deg);
        color: $indigo-300;
      }
    }
    .dropdown-toggle {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 12.5px;
    }
    .dropdown-menu {
      width: 100%;
    }
    .select__menu {
      border-radius: 4px;
      box-shadow: none !important;
    }
    .multi-select-filter--select {
      box-shadow: none;
      // border: 2px solid rgba(154, 162, 252, 0.35);
    }
    .select__option {
      background-color: white;
      font-size: 16px;
      padding: 10px 20px;
      color: $indigo-500;
      &:first-child {
        padding-top: 15px;
      }
      &:hover,
      &:active {
        background-color: $indigo-100;
      }
      i {
        color: $indigo-500;
      }
    }
    .filter__selected-label {
      color: $gray-900;
      font-weight: 400;
      font-size: 16px;
    }
  }
}
