@import '@frontend/design-system/src/styles/_variables';

.analyticsTable {
  position: relative;
  display: grid;
  // NOTE: grid-template-columns set in .tsx

  :global(.spec-file) {
    color: $gray-500;
    font-size: 12px;
    i {
      text-align: left;
    }
  }

  .row {
    cursor: pointer;
  }
}

.textRight {
  margin-left: auto;
}

.scrollToHelper {
  scroll-margin-top: 100px;
}

.row {
  display: contents;
  > div,
  > h6 {
    align-items: center;
    display: flex;
    padding: 8px;
    &:first-child {
      padding-left: 16px;
    }
    &:last-child {
      padding-right: 16px;
    }
  }
  > h6 {
    background-color: $gray-A1;
    min-height: 48px;
    margin: 0;
    position: sticky;
    top: $page-header-height;
  }
  > div {
    border-top: 1px solid $gray-200;
    font-size: 12px;
    min-height: 70px;
  }
  .title {
    color: $gray-700;
    font-size: 14px;
    line-height: 16px;
  }
}

.rowHasDrillIn {
  cursor: pointer;
  &.active {
    background-color: $gray-50;
  }
}

.footer {
  border-top: 1px solid $gray-200;
  padding: 8px;
  text-align: center;
}

.viewByRowContainer {
  color: $gray-800;
  font-size: 14px;
  width: 100%;
}
.viewByRow {
  align-items: center;
  display: flex;
  :global(.spec-file) {
    color: $gray-800;
    font-size: 14px;
  }
}
.viewByRowLogo {
  margin: 0 8px 0 0;
}

.flakyTestsTable {
  .flakyRateHeader {
    padding-right: 36px;
  }
}

.mutedTestContainer {
  color: $orange-500;
}

.mostCommonErrorsTable {
  grid-template-columns: auto 1fr auto;

  .rowColor {
    width: 12px;
    height: 12px;
    margin: 10px;
    border-radius: 50%;
  }
  .rowErrorMessage {
    margin: 0;
    color: $gray-900;
    font-family: $font-mono;
    font-size: 12px;
    word-break: break-word;
  }
  .rowErrorName {
    margin: 0 0 8px 0;
    color: $red-800;
  }
  .footer {
    grid-column: 1 / 4;
  }
}

.pastRunDurationTable {
  grid-template-columns: repeat(4, auto);
  .titleContainer {
    display: flex;
    align-content: center;
    .titleText {
      display: inline-block;
      margin: 0;
      line-height: 20px;
    }
  }
}

.runDurationTable {
  grid-template-columns: repeat(6, auto);

  .drillDownPanel {
    overflow: hidden;
    grid-column-end: span 6;
    max-height: 100%;
    background-color: $gray-50;
    &.collapsed {
      max-height: 0px;
    }
  }

  .drillDownContent {
    background-color: #fff;
    border-top: 1px solid $gray-100;
    border-right: 1px solid $gray-100;
    margin: 8px;
  }
}

.slowestTestsTable {
  grid-template-columns: auto 1fr auto auto auto;
  .footer {
    grid-column: 1 / 5;
  }
}

.testSuiteSizeTable {
  grid-template-columns: repeat(5, 1fr);
}

.flakyRate {
  display: flex;
  align-items: center;
  width: 100%;
}

.severityTooltip {
  opacity: 1;
  min-width: 207px;
  min-height: 128px;
  :global(.rc-tooltip-arrow) {
    border-left-color: transparent;
  }
  :global(.rc-tooltip-inner) {
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    background: $gray-A1;
    color: $gray-A2;
    .severityDescription {
      padding: 8px;
    }
    .severityRow {
      display: flex;
      justify-content: space-between;
    }
    .tooltipLowSeverity {
      color: $orange-400;
    }
    .tooltipMediumSeverity {
      color: $orange-700;
    }
    .tooltipHighSeverity {
      color: $orange-900;
    }
  }
}

.chevron {
  svg {
    color: $gray-900;
    path {
      stroke-width: 1.5px;
    }
  }
}
