.drawer {
  position: fixed;
  top: 0;
  right: 0;
  background: $gray-A1;
  height: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  transform: translateX(100%);
  transition: transform 0.5s ease;
  z-index: 2;
  &--open {
    transform: translateX(0);
  }
}
