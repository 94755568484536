@import '@frontend/design-system/src/styles/_variables';

.groupButton {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $gray-100;

  &.groupButtonActive {
    background-color: $indigo-100;
  }
}

.group {
  display: grid;
  grid-template-columns: 48px calc(100% - 48px);
  grid-template-rows: auto;
  grid-template-areas: 'groupIcon groupContent';
  padding-right: 16px;
  padding-top: 30px;
}

.groupIcon {
  grid-area: groupIcon;
  text-align: center;
  align-self: center;
}

.groupContent {
  margin-left: 28px;
  grid-area: groupContent;
  align-self: center;

  .groupTitle {
    color: $gray-700;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    display: flex;
    align-items: center;

    .completedStepsCount {
      margin-left: 12px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      padding: 2px 4px;
      border-radius: 4px;
      border: 1px solid $gray-100;

      &.activeCompletedStepsCount {
        background: $indigo-100;
        border: none;
      }
    }

    &.groupTitleExpanded {
      color: $indigo-500;
    }
  }

  .groupDescription {
    color: $gray-600;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .groupExpand {
    cursor: pointer;
    display: flex;
    margin-top: 8px;
    color: $gray-700;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    span {
      text-decoration: underline;
    }
  }
}

.rowsContainer {
  margin-top: 40px;
}

.row {
  display: grid;
  grid-template-columns: 48px calc(100% - 48px);
  grid-template-rows: 25px auto;
  grid-template-areas:
    'iconContainer titleContainer'
    'lineContainer contentContainer';
  padding-right: 16px;
}

.iconContainer {
  grid-area: iconContainer;
  text-align: center;
  align-self: center;
  color: $indigo-500;

  .pendingStep {
    color: $gray-300;
  }
}

.titleContainer {
  cursor: pointer;
  grid-area: titleContainer;
  align-self: center;
  margin-left: 28px;

  .title {
    color: $gray-600;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    &.isActiveTitle {
      color: $indigo-500;
    }
  }
}

.lineContainer {
  grid-area: lineContainer;

  .line {
    min-height: 57px;
    height: 100%;
    background: linear-gradient($indigo-500, $indigo-500) no-repeat center/2px
      100%;

    &.isLastLine {
      height: auto;
    }
  }

  .activeLine {
    min-height: 57px;
    height: 100%;
    background: repeating-linear-gradient(
        to bottom,
        $indigo-500,
        $indigo-500 5px,
        transparent 5px,
        transparent 10px
      )
      no-repeat center/2px 100%;

    &.isLastLine {
      height: auto;
    }
  }

  .pendingLine {
    min-height: 57px;
    height: 100%;
    background: repeating-linear-gradient(
        to bottom,
        $gray-300,
        $gray-300 5px,
        transparent 5px,
        transparent 10px
      )
      no-repeat center/2px 100%;

    &.isLastLine {
      height: auto;
    }
  }
}

.contentContainer {
  grid-area: contentContainer;
  margin-left: 28px;
  min-height: 57px;

  .description {
    color: $gray-600;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
  }

  .learnMore {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;

    a {
      padding: 0;
    }
  }

  .contentComponent {
    margin-top: 24px;
    margin-bottom: 24px;

    &.isLastComponent {
      margin-bottom: 0;
    }
  }
}

.actionButtons {
  display: flex;
  gap: 8px;

  a:hover {
    text-decoration: none;
  }

  .outlineButton {
    background-color: transparent;
  }

  .skip {
    border: none;
  }
}

.remainingDaysBadge {
  margin-left: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 2px 4px;
  border-radius: 4px;
  background: $indigo-500;
  color: white;

  &.remainingDaysBadgeAlert {
    background: $red-500;
  }
}

.trialProgressTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
  }
}

.warningContainer {
  background-color: $orange-50;
  color: $orange-700;
  width: 640px;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;

  .header {
    padding: 16px;
    border-bottom: 1px solid $orange-200;
    font-weight: 500;
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .content {
    padding: 16px;
    font-weight: 400;
  }
}

.latestRuns {
  margin-top: -16px;
  margin-bottom: 16px;
  color: $gray-900;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.latestFailedOrFlakyRun {
  width: 70%;
  border: 1px solid $gray-100;
  border-left: none;
  border-radius: 4px;
  margin-bottom: 16px;
}

.banner {
  max-width: 60%;

  .bannerTitle {
    color: $gray-1000;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  }

  .bannerDescription {
    color: $gray-700;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: grid;
    row-gap: 10px;
  }
}
