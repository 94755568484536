.organization-container {
  padding-left: 0;
  height: 100%;
  > div {
    height: 100%;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .org-uuid {
    font-family: Consolas, Monaco, 'Andale Mono', monospace;
    display: inline-block;
    margin-left: 10px;
  }

  .input-group-addon.copy-uuid {
    width: 40px;
    display: inline-block;
    border: 1px solid $gray-200;
    border-radius: 4px;
    padding: 6px 10px;
  }
}
