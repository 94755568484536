// main create project panel
.create-project-panel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  .create-project-panel-container-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 32px;
      font-weight: 500;
      line-height: 44px;
      margin: 0;
    }
    .create-project-panel-container-header-sub {
      margin: 0;
      margin-bottom: 4px;
      color: $gray-600;
      font-size: 18px;
      font-weight: 400;
    }
    .create-project-panel-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $indigo-500;
      font-size: 18px;
      font-weight: 400;
      margin: 0;
    }
  }
  .create-project-panel {
    ::placeholder {
      font-weight: 300;
      color: $gray-400;
    }
    width: 100%;
    max-width: 640px;
    border: 1px solid $gray-100;
    box-shadow: none;
    .create-project-panel-body {
      padding: 20px;
      .input-field-container:not(:last-child) {
        margin-bottom: 32px;
      }
      .create-project--access-option {
        font-size: 16px;
        color: $gray-700;
        font-weight: 300;
        span {
          font-weight: 500;
        }
      }
    }
    .create-project-panel-footer {
      background-color: $gray-50;
      display: flex;
      justify-content: flex-end;
      &:not(:last-child) {
        margin-right: 16px;
      }
      .btn-create-project {
        font-weight: 400;
        height: 40px;
      }
      .btn-submit {
        padding-top: 9px;
        margin-right: 16px;
        &:disabled {
          background-color: $gray-500;
        }
      }
      button:last-of-type {
        margin-right: 0;
      }
      .btn-back {
        background-color: transparent;
        border: 1px solid $gray-100;
        color: $indigo-600;
      }
    }
  }
  .ci-brand-icon-none {
    color: $gray-200;
    path {
      stroke: $gray-600;
    }
  }
  .ci-brand-icon-question {
    color: $gray-200;
    .question-mark-path {
      fill: $gray-600;
    }
  }
  .create-public-project-label-wrapper {
    display: inline-flex;
    > :not(:first-child) {
      margin-left: 8px;
    }
  }
  .create-project-radio-option {
    label {
      margin: 0;
    }
  }
}

// vertical input form group
.input-field-container {
  .input-field-label {
    margin: 0;
    margin-bottom: 2px;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
  }
  .input-field-description {
    margin: 0 0 8px 0;
    font-size: 14px;
    color: $gray-600;
    font-weight: 400;
  }
  .input-field-error-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $red-400;
    i {
      margin-bottom: -4px;
      margin-right: 4px;
    }
    .input-field-error {
      margin: 0;
      margin-top: 6px;
      font-size: 14px;
      font-weight: 400;
      padding: 0;
    }
  }
}
