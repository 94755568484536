#login,
#signup {
  text-align: left;
  background: #fff !important;
  display: table;
  width: 100%;
  height: 100%;

  h1 {
    color: $gray-800;
    margin-top: 0.5em;
    font-size: 1.4em;
    margin-bottom: 1em;
    text-align: left;
  }

  p {
    text-shadow: none;
  }
}

.login-content,
.signup-content {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: stretch;

  legend {
    font-size: 20px;
    margin-bottom: 40px;
    border-bottom-color: $gray-100;
    color: $gray-800;
    padding: 20px 25px;
    font-weight: 500;
  }

  .login-left,
  .signup-left {
    display: flex;
    overflow-y: auto;
    position: relative;
    flex: 1 1 0%;
    background: white;

    .login-img-wrapper,
    .signup-img-wrapper {
      // visible at small breakpoints
      display: none;
    }

    form {
      width: 100%;
      max-width: 360px;
      margin: auto;

      legend {
        padding: 0;
        border-bottom: none;
      }
      .ipt {
        width: 100%;
        text-align: left;
        white-space: nowrap;
        vertical-align: middle;
        touch-action: manipulation;
        line-height: 24px;
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 400;
        border-radius: 5px;
        background: $gray-100;
        border: 1px solid $gray-100;
        padding: 6px 12px;
      }
      .branch_info {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        width: 100%;
        display: block;
      }
    }

    .login-separator,
    .signup-separator {
      position: relative;
      margin: 20px 0;
      border-bottom: 1px solid $gray-100;
    }
  }

  .login-right,
  .signup-right {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1 1 0%;
    background-color: $gray-900;
    color: $gray-100;
    font-size: 18px;

    p {
      margin-top: 10px;
    }
  }

  .form-controls {
    overflow: auto;
  }

  .log-in-link,
  .sign-up-link {
    text-align: center;

    a {
      color: $indigo-500;
      font-weight: 500;
      margin-left: 4px;
    }
  }

  .message-content {
    .alert {
      margin-top: 10px;
    }
  }

  .btn-submit {
    border-radius: 0;
    background-color: $jade-300;

    &:hover {
      background-color: $jade-500;
    }
  }

  .login-help-links {
    margin-top: 15px;
    p {
      font-size: 13px;
      margin-bottom: 0;
    }
  }
}

.btn-login {
  line-height: 48px;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  color: $gray-800 !important;
  border: 1px solid $gray-300;
  padding: 0;

  .icon-right {
    border-right: 1px solid $gray-300;
    margin-right: 14px;
    width: 48px;

    img {
      max-height: 48px;
      width: 48px;
      padding: 13px;
    }

    i {
      position: relative;
      font-size: 20px;
      padding: 13px;
    }
  }

  &.btn-provider-email .icon-right img {
    padding: 15px;
  }

  &:last-of-type {
    margin-bottom: 30px;
  }

  &:hover {
    background-color: $gray-50;
  }

  &:active,
  &:focus,
  &:visited {
    background-color: $gray-100;
    border: 1px solid $gray-500;
    .icon-right {
      border-right: 1px solid $gray-500;
    }
  }

  &:active,
  &:focus {
    outline: 0;
  }
}

.login-img-wrapper,
.signup-img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.form-controls {
  display: none;
}

#email-signup,
#new-sso,
#email-login {
  .input-form-group {
    border-top: none;
    grid-template-columns: 1fr;

    &.danger {
      background-color: $gray-A1;
    }

    .left-column,
    .input-form-group-contents {
      margin: 0;
    }

    input:focus,
    input:invalid {
      border: 1px solid $gray-200;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }

    .description {
      margin-bottom: 8px;
    }
  }

  .email-group .input-form-group {
    padding: 0 0 10px;
  }

  .btn-primary {
    width: 100%;
    margin-bottom: 20px;
  }
}

#email-signup {
  .PasswordStrengthInput {
    margin-top: 0;
  }

  .email-group input {
    height: 38px;
  }
}

// FIXME [@jtucker] -- Need to better consolidate bootstrap styles. I tried to
// as part of this work, but the risk just got too high. The biggest challenge
// was Bootstrap's handling of the $state-* variables: it uses $state-*-text for
// validated input border colors. Trying to get that to mach button colors
// (which use $brand-* variables) ends up in a fiddly mess of trying to get the
// .alert color contrast good enough while still matching the button color.
//
#sso {
  margin: auto;

  .btn {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .has-feedback.has-success {
    .input-group-addon,
    .form-control,
    .form-control-feedback {
      color: $indigo-600;
      border-color: $jade-600;
    }
    .input-group-addon {
      background-color: $gray-A1;
    }
  }

  .has-feedback.has-error {
    .input-group-addon,
    .form-control,
    .form-control-feedback {
      color: $red-700;
      border-color: $red-700;
    }
    .input-group-addon {
      background-color: $gray-A1;
    }
  }
}

#dashboard-session-login {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 0px;
  }

  .cy-icon {
    margin-left: 8px;
  }
}

// Move to windi.css
.font-marketing {
  font-family: 'Poppins';
}
