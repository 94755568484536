@import '@frontend/design-system/src/styles/_variables';

.onboarding-checklist {
  margin: none;

  .progressive-setup-step-heading {
    align-items: center;
    .progressive-setup-step-heading-step {
      display: flex;
      border-right: 1px solid $gray-100;
      padding: 8px 12px;
    }
  }

  &.step-3 {
    .subheading {
      margin-bottom: 0 !important;
    }
  }

  .step-3-local-run-heading {
    border-radius: 4px;
    margin-bottom: 40px;
    border-bottom: none !important;
  }
}

.project-setup {
  margin: auto;
}

#progressive-project-setup {
  width: 100%;
  max-width: 610px;
  text-align: center;

  .progressive-setup-step-heading {
    display: flex;
    .progressive-setup-step-heading-step {
      margin-right: 16px;
      text-align: center;
      align-self: center;
      h5 {
        color: $indigo-500;
      }
    }
  }

  h3 {
    font-size: 32px;
    line-height: 44px;
    font-weight: 500;
    color: $gray-900;
  }

  h4 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 40px;
    color: $gray-700;
  }
  h5 {
    color: $gray-900;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: normal;
    margin: 0;
  }
  .subheading {
    font-size: 14px;
    line-height: 20px;
    color: $gray-600;
  }

  .progress-bars {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    .progress-bar {
      width: 80px;
      height: 7px;
      box-shadow: none;
      border-radius: 4px;
      margin-right: 2px;
      &:last-child {
        margin-right: 0;
      }
      &.complete {
        background: $jade-300;
      }
      &.incomplete {
        background: $gray-200;
      }
    }
  }

  .panel {
    margin-top: 24px;
    text-align: left;
  }

  .panel-heading {
    padding: 18px !important;
  }

  .panel-body {
    border-top: 0;
    font-size: 16px;
    padding: 0px 24px 20px 24px !important;
  }

  .panel-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      font-weight: 400 !important;
    }

    button {
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
      svg {
        margin-right: 8px;
      }
    }
  }

  &.step-1 {
    code,
    pre {
      font-size: 14px;
    }

    .footer-btn {
      display: inline-flex;
      justify-content: right;
      width: 100%;

      .checkbox {
        margin-right: 16px;
        font-size: 16px;
        padding: 8px 16px;
        border: solid 1px $gray-100;
        border-radius: 4px;
        line-height: 24px;
        margin-top: 0px;
        margin-bottom: 0px;

        &:hover {
          border: solid 1px $gray-200;
        }

        label::before {
          margin-top: 2px;
        }
        label span {
          font-weight: 500;
          padding-left: 12px;
          color: $indigo-500;
        }
      }
    }

    #cypress-config-step {
      .tabbar-component > div:first-child {
        overflow: visible !important;
      }
      p {
        display: none;
      }
      .code-snippet {
        padding-top: 16px;
        .code-snippet-file-name,
        .col-nums {
          background-color: white;
        }
      }
    }
  }

  &.step-3 {
    max-width: 768px;
    .subheading {
      margin-bottom: 32px;
    }

    .panel-body {
      padding-bottom: 0 !important;
    }

    .panel-heading.step-3-local-run-heading {
      padding-bottom: 16px !important;
      padding-top: 16px !important;
      border-bottom: 1px solid $gray-100;
      h5 {
        margin-bottom: 0;
      }
      .subheading {
        margin-bottom: 0;
      }
    }

    .code-sample {
      margin-top: 16px;
      .decorative-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 16px;
        margin-right: 16px;
        border-right: 1px solid $gray-100;
        .cy-technology-terminal-icon {
          display: none;
        }
      }
      button {
        background: none;
        margin: 4px;
        padding: 6px 12px;
        box-shadow: none;
        border: none;
        &:hover,
        &:focus {
          background-color: $indigo-50;
        }
        svg {
          margin-right: 0;
          &:hover,
          &:focus {
            box-shadow: none;
          }
        }
        .copy-text {
          display: none;
        }
      }
      pre {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .spin {
      animation-name: spin;
      animation-duration: 950ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}
