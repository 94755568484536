.test-results-drawer__performance {
  h6 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  &--total-time {
    font-size: 16px;
    padding-bottom: 16px;
  }
  .performance-viz {
    display: grid;
    margin-top: 8px;
    // NOTE: grid-template-columns determined in .tsx
    > div {
      border: 1px solid $gray-A1;
      font-size: 11px;
      text-align: center;
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:nth-last-child(3) {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    &__before {
      grid-area: before;
      background-color: $jade-100;
      height: 16px;
    }
    &__beforeEach {
      grid-area: beforeEach;
      background-color: $red-300;
      height: 16px;
    }
    &__afterEach {
      grid-area: afterEach;
      background-color: $jade-50;
      height: 16px;
    }
    &__after {
      grid-area: after;
      background-color: $purple-300;
      height: 16px;
    }
    &__test {
      grid-area: test;
      background-color: $indigo-400;
      height: 16px;
    }
    &__hooks {
      grid-area: hooks;
      h6 {
        display: inline-block;
      }
    }
    &__body {
      grid-area: body;
    }
  }
}
