$test-result-border-height: 1px;
$test-result-border-radius: 4px;

$test-result-col-1-width: 69%;
$test-result-col-2-width: 2%;
$test-result-col-3-width: 22%;
$test-result-col-4-width: 7%;

.test-results {
  box-shadow: $box-shadow;
  overflow: visible !important;
  margin: 0 0 40px 0;
  &__loader {
    margin: 0;
  }
  &__loader-container {
    position: relative;
  }
  &__pagination-loader {
    text-align: center;
    font-size: 22px;
  }
  &__column-headers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: $gray-50;
    padding: 8px 16px;
    border-bottom: $test-result-border-height solid $gray-200;
  }
  &__test-result-title-container {
    display: flex;
    width: $test-result-col-1-width;
    align-items: center;
    padding: 0 8px 0 0;
    white-space: nowrap;
    overflow: hidden;
    min-height: 22px;
  }
  &__test-result-retry-count {
    padding-left: 8px;
  }
  &__modification-col,
  &__duration-col {
    width: $test-result-col-4-width;
    min-width: 62px;
  }
  &__duration-col {
    margin: 0;
  }
  &__header-icon {
    margin: 0 4px 0 0;
  }
  &__status-border {
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% + #{$test-result-border-height});
    width: 2px;
    background-color: $gray-500;
  }
  &__has-more-results {
    font-style: italic;
    color: $gray-500;
  }
  &__test-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: $gray-A1;
    border-top: none;
    border-right: none;
    border-bottom: $test-result-border-height solid $gray-200;
    border-left: none;
    position: relative;
    &:focus,
    &:hover,
    &.test-results__test-result-active {
      background: $gray-50;
      outline: none;
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
  &__test-result-title {
    display: flex;
    flex: 1;
    margin: 0;
    text-align: left;
    overflow: hidden;
    .horizontal-list {
      display: block;
    }
  }
  &__test-result-title-divider {
    font-size: 10px;
  }
  &__output-cta {
    padding: 0;
    font-weight: normal;
    vertical-align: text-bottom;
  }
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
    div:last-of-type {
      .test-results__test-result:last-of-type {
        border-bottom: $test-result-border-height solid $gray-200;
      }
    }
  }
}
