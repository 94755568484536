@import '@frontend/design-system/src/styles/_variables';

.step-3 {
  #trigger-a-run {
    #step-1 {
      margin-bottom: 40px;
    }
    #step-1,
    #step-2 {
      > p {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;
        > svg {
          margin-right: 8px;
          flex-shrink: 0;
        }
        code {
          color: $gray-700;
          background: $gray-50;
          line-height: 24px;
          margin: 0 4px;
          padding: 0 4px;
          border-radius: 4px;
        }
      }
    }

    #docs-cta-wrapper {
      display: flex;
      margin-top: 20px;
      > a {
        text-decoration: none;
        font-weight: 400 !important; // need !important to override tailwind
        border: 1px solid $gray-100;
        margin-bottom: 24px;
        transition-property: background-color, border-color, color, fill, stroke,
          opacity, box-shadow, transform;
        transition-duration: 150ms;
        border-radius: 0.25rem;
        border-width: 1px;
        border-color: #f3f4f6;
        &:hover,
        &:active {
          border: 2px solid $gray-100;
          text-decoration: none;
        }
      }
      svg {
        margin-right: 11px;
      }
    }

    p {
      color: $gray-700;
    }

    .code-snippet {
      .col-nums {
        background: none;
      }
      .code-snippet-file-name {
        background-color: white;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        .code-snippet-copy-btn {
          background: none !important;
          border: none;
          padding: 6px 12px !important;
          margin: 4px;
          &:hover,
          &:active {
            background: $indigo-50 !important;
          }
        }
      }
    }

    /*
      If the `promote-ci-run-recordings-ab` test wins, add this `.code-sample` style variant as an option in <CliCommandSample/>
    */
    .code-sample {
      margin-top: 0;
      &:hover {
        box-shadow: unset;
      }
      .code-sample-button-container {
        border: none !important;
        &:active,
        &:hover {
          border: none !important;
        }
      }
      .cli-sample-copy-btn {
        background-color: unset;
        border: none !important;
        &:active,
        &:hover {
          border: none !important;
        }

        svg {
          margin-right: 0;
        }
      }
      .copy-text {
        display: none;
      }
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.trigger-a-run-dropdown-wrapper {
  margin-bottom: 32px;
  .trigger-a-run-dropdown {
    padding: 6px 13px !important;
    width: 180px;
    // height: 36px;
    background-color: inherit;
    border: 1px solid $gray-100;
    color: $gray-800;
    font-weight: 400;
    // Hides the dropdown's built-in caret
    > span {
      display: none;
    }
    &:hover,
    &:active,
    &:focus {
      color: $gray-800 !important;
      font-weight: 400 !important;
      background-color: inherit !important;
      border: 1px solid $gray-100 !important;
    }
  }

  .trigger-a-run-dropdown-label {
    display: inline-flex;
    width: 100%;
    align-items: center;
    text-align: center;
    vertical-align: top;
    span {
      margin-left: 9px;
    }
  }

  .trigger-a-run-dropdown-caret {
    right: 0;
    margin-left: auto;
  }

  .trigger-a-run-dropdown-list {
    width: 200px;
    svg {
      margin: 0 !important;
    }
    :hover {
      background-color: $indigo-50;
    }
  }

  .trigger-a-run-dropdown-item {
    padding-left: 17px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: $indigo-500 !important;
    font-size: 14px;
    font-weight: 400 !important;
    cursor: pointer;
    > span {
      margin-right: 40px;
    }
  }

  .trigger-a-run-dropdown-row-provider-name {
    margin-left: 8px;
  }
}

.trigger-a-run-step {
  margin-bottom: 24px;
  > p {
    display: flex;
    margin-bottom: 16px;
    svg {
      flex: none;
    }
    span {
      margin-left: 8px;
      flex: 2;
    }
  }

  code {
    color: $gray-700;
    background-color: $gray-50;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 2px;
  }
}

// Style workaround to address regression
.code-snippet-button-wrapper {
  button {
    color: $indigo-500;
    border: none;
    box-shadow: none !important;
    background-color: transparent !important;
  }
}
