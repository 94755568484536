@mixin responsive-width(
  $width: 100%,
  $padding: 0,
  $border: 0,
  $borderColor: $gray-100
) {
  width: $width;
  box-sizing: border-box;
  @if $padding != 'none' {
    padding: $padding;
  }
  border-width: $border; // these border styles needed to be split out, so you could pass border: 0 1px 5px 2px instead of just one single border argument
  border-color: $borderColor;
  border-style: solid;
  float: left;
}

@mixin el-width($col, $width) {
  #{$col} {
    @include responsive-width($width: $width, $padding: 0);
  }
}

@mixin list-columns($widths...) {
  $n: 1;
  $var: 'li>div:nth-child(' + $n + ')';
  @each $width in $widths {
    @include el-width($var, $width);
    $n: $n + 1;
    $var: 'li>div:nth-child(' + $n + ')';
  }
}

@mixin td-width($col, $width) {
  #{$col} {
    width: $width;
  }
}

@mixin text-overflow-custom {
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
  word-wrap: break-word;
}

@mixin text-overflow-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin loading-shimmer($animationSpeed) {
  background-color: $gray-50;
  border-color: $gray-50;
  overflow: hidden;
  position: relative;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba($gray-100, 0) 0,
      rgba($gray-200, 0.2) 20%,
      rgba($gray-300, 0.5) 60%,
      rgba($gray-400, 0)
    );
    animation: shimmer $animationSpeed infinite;
    content: '';
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
