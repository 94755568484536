html,
body {
  color: $gray-900;
  height: 100%;
  overscroll-behavior-y: none;
  overscroll-behavior-x: inherit;
}

// FIXME: Remove this once we have can remove the preflight: false arg from WindiCSS (it was probably turned off for a reason - ask Shawn)
// Related case: CYCLOUD-720: https://cypress-io.atlassian.net/browse/CYCLOUD-720
button {
  border-style: solid;
  -webkit-appearance: none;
  -moz-appearance: none;
}

a:focus {
  outline: 0;
}

#app {
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;
  }

  strong {
    font-weight: 500;
  }
}

iframe {
  border: 0;
  width: 537px;
  height: 715px;

  .navbar {
    display: none;
  }
}

ul {
  // firefox
  padding-left: 0;
  margin-bottom: 0;
}

.banner.banner-warning,
.banner.banner-danger {
  width: 100%;
  line-height: 32px;

  .btn {
    margin-left: 10px;
  }
}

.app--main > div {
  height: 100%;
}

$sidenav-width: 320px;
.app {
  display: flex;
  height: 100%;

  .app--sidenav {
    position: fixed;
    width: $sidenav-width;
    max-width: $sidenav-width;
    top: 0;
    bottom: 0;
    left: 0;
    right: $sidenav-width;
    z-index: 11;
  }

  .app--main {
    margin-left: $sidenav-width;
    width: calc(100% - #{$sidenav-width});
    min-height: 100%;
    background-color: #ffffff;

    &.hide-sidenav {
      margin-left: 0;
      width: 100%;
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    .app--sidenav {
      z-index: 0;
    }

    .app--main {
      margin: 0;
      width: 100vw;
      transition: transform 300ms ease-in-out;
      z-index: 1;
    }

    &.app--is-viewing-sidenav {
      .app--main {
        margin: 0;
        transform: translateX($sidenav-width);
      }
    }
  }
}

.fa-hourglass-end {
  position: relative;
  top: -1px;

  .fa-size-x16 & {
    top: -3px;
  }
}

.help-block.bg-danger {
  padding: 10px;
  color: $red-800;
  border-radius: 5px;
}

.right-align {
  text-align: right;
}

ul {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
}

label > span {
  color: $gray-400;
  font-weight: normal;
}

.label {
  font-weight: 500;
}

.disabled {
  color: $gray-200;
  cursor: not-allowed;
}

.italic {
  font-style: italic;
}

.green {
  color: $jade-400;

  &:hover,
  &:focus,
  &:active {
    color: $jade-400;
  }
}

.blue {
  color: $indigo-600;
}

a {
  &.red {
    color: $red-500;

    &:hover {
      color: $red-500;
    }
  }
}

.fa.red {
  color: $red-500;
}

.fa.orange {
  color: $orange-300;
}

i.errored {
  color: $orange-300;
}
i.failed {
  color: $red-500;
}
i.noTests {
  color: $orange-300;
}
i.passed {
  color: $jade-400;
}
i.running {
  color: $indigo-500;
}
i.overLimit {
  color: $orange-300;
}
i.timedOut {
  color: $orange-300;
}
i.null {
  color: $gray-300;
}

#not-found {
  position: fixed; // not found page can be rendered inside the application, so make sure we are showing it full screen
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $gray-800;
  display: table;
  width: 100%;
  height: 100%;
  z-index: 10;

  .not-found-content {
    background-color: $gray-100;
    padding: 50px;
  }

  h1 {
    color: $gray-700;
    font-size: 800%;
  }

  h2 {
    color: $gray-600;
    font-size: 200%;
  }

  a {
    font-size: 150%;
    line-height: 40px;
  }
}

#HSBeaconFabButton {
  right: 10px !important;
  bottom: 20px !important;
}

#HSBeaconContainerFrame {
  right: 10px !important;
  bottom: 100px !important;
}

[hidden] {
  display: none !important;
}

@media only percy {
  .hide-in-percy {
    visibility: hidden;
  }
}
