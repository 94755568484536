.analytics-progress-bar {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 98px;
  color: $gray-600;
  margin-left: auto;
  &__percentage {
    min-width: 30px;
    text-align: right;
  }
  .progress {
    height: 12px;
    flex: 1;
    vertical-align: middle;
    margin: 0 0 0 4px;
    background-color: $gray-100;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    .progress-bar {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .progress-bar-flakiness {
      background-color: $orange-400;
    }
  }
}
