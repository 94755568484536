.organization-selection-option {
  width: 100%;
  .alert {
    margin: 12px 0 0 0;
  }
  &__row {
    display: flex;
    @media screen and (max-width: $screen-sm-max) {
      display: block;
    }
  }
  &__description {
    flex: 1;
    text-align: left;
    padding: 8px 20px 0 20px;
    @media screen and (max-width: $screen-sm-max) {
      padding: 8px 0;
    }
    p,
    h2 {
      color: $gray-600;
    }
    h2 {
      margin: 0 8px 0 0;
      font-weight: 600;
    }
    p {
      margin: 0 0 5px 0;
      font-weight: normal;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
  }
  &__button {
    color: $indigo-600;
    border: 1px solid $indigo-600;
    border-radius: 4px;
    padding: 8px 16px;
  }
}

.link-card {
  background-color: $gray-A1;
  border: 1px solid $gray-100;
  border-top: 5px solid $gray-200;
  box-shadow: $box-shadow;
  border-radius: 5px;
  padding: 24px;
  cursor: pointer;
  margin-bottom: 24px;
  color: $gray-300;
  white-space: normal;
  &[disabled]:hover,
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: $indigo-50;
    border-top-color: $indigo-400;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    color: $gray-300;
    outline: none;
  }
}
