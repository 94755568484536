.modal {
  display: block;

  &.modal-lg {
    width: 1000px;
  }

  &.modal-fullscreen .modal-dialog {
    width: auto;
    margin: 40px 100px;
  }

  .instance-title {
    margin-top: 40px;
  }

  .modal-header {
    border-top: none;
    // note: everything has a top border; nothing has a bottom border
    border-bottom: none;
  }

  // body needs a top border if & only if there's a header
  .modal-body {
    border-top: none;
  }

  .modal-header + .modal-body {
    border-top: 1px solid $gray-200;
  }

  button.close {
    color: $gray-500;
    font-weight: 200;
    font-size: 40px;
    margin-top: -10px;
    opacity: 1;
    &[disabled] {
      color: $gray-200;
      cursor: not-allowed;
    }
  }
}

.modal-content {
  border: none;
}

.modal-body {
  p.text-muted {
    margin-top: 16px;
    margin-bottom: 24px;
  }
}

.modal-footer {
  &__back {
    display: flex;
    flex: 1;
  }
  &--destroy {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.modal-backdrop {
  transition: background-color 100ms ease-in-out;

  &.fade {
    background-color: rgba($gray-900, 0);
    opacity: 1;
  }

  &.in {
    background-color: rgba($gray-900, 0.5);
  }
}

.modal {
  // Inset all table content so it aligns with the header padding
  .table {
    margin-bottom: 0;
    th:first-child,
    td:first-child {
      padding-left: 16px !important;
    }
    th:last-child,
    td:last-child {
      padding-right: 16px !important;
    }
  }
}

.modal-dialog.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(10px);
  }
}

@-moz-keyframes shake {
  0%,
  100% {
    -moz-transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -moz-transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    -moz-transform: translateX(10px);
  }
}

@-o-keyframes shake {
  0%,
  100% {
    -o-transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -o-transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    -o-transform: translateX(10px);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

.modal-dialog.shake {
  animation-name: shake;
}
