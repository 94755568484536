@import '@frontend/design-system/src/styles/_variables';

.feedbackButton {
  svg {
    flex-shrink: 0;
  }
}

.purple-dark {
  &:hover,
  &:focus {
    color: $gray-A1 !important;
    text-decoration: none !important;
  }
}

.outline-purple {
  &:hover,
  &:focus {
    color: $purple-500;
    text-decoration: none !important;
  }
}
