.kpis {
  .flaky-tests-kpi-high-severity {
    .kpi--name {
      color: $orange-900;
      opacity: 0.8;
    }
  }
  .flaky-tests-kpi-medium-severity {
    .kpi--name {
      color: $orange-700;
      opacity: 0.8;
    }
  }
  .flaky-tests-kpi-low-severity {
    .kpi--name {
      color: $orange-400;
      opacity: 0.8;
    }
  }
}
