@import '@frontend/design-system/src/styles/_variables';

.row {
  display: contents;
  color: $gray-700;
  > div,
  > h6 {
    align-items: center;
    display: flex;
    padding: 8px;
    &:first-child {
      padding-left: 16px;
    }
    &:last-child {
      padding-right: 16px;
    }
  }
  > h6 {
    background-color: $gray-A1;
    min-height: 48px;
    margin: 0;
    position: sticky;
    top: 0;
  }
  > div {
    border-top: 1px solid $gray-200;
    font-size: 12px;
    min-height: 70px;
  }
  &.active {
    color: $gray-700;
    text-decoration: none;
    > div {
      background-color: $gray-50;
    }
  }
}

.rowLink {
  &.active,
  &:hover {
    color: $gray-700;
    text-decoration: none;
    > div {
      background-color: $gray-50;
    }
  }
}
