.PasswordStrengthInput {
  position: relative;
  margin-top: 16px;

  .ReactPasswordStrength {
    border: 1px solid #dadade !important;
    border-radius: 4px;
    background: $gray-A1;

    .ReactPasswordStrength-input {
      border: none !important;
      float: none;
      padding: 6px 12px;
      font-size: 14px;
      color: #5f6373;

      &.is-password-invalid {
        color: #5f6373;
      }
    }

    .ReactPasswordStrength-input:focus {
      box-shadow: none;
    }

    .ReactPasswordStrength-strength-bar {
      margin-left: 1px;
    }

    &.is-strength-0 .ReactPasswordStrength-strength-bar {
      background: $red-700;
    }

    &.is-strength-1 .ReactPasswordStrength-strength-bar {
      background: $orange-500;
    }

    &.is-strength-2 .ReactPasswordStrength-strength-bar,
    &.is-strength-3 .ReactPasswordStrength-strength-bar {
      background: $jade-600;
    }

    &.is-strength-4 .ReactPasswordStrength-strength-bar {
      width: calc(100%) !important;
      background: $jade-600;
    }

    .ReactPasswordStrength-strength-desc {
      display: none;
    }
  }

  .password-show-hide {
    position: absolute;
    top: -24px;
    right: 2px;
    cursor: pointer;
    color: $indigo-500;
    font-size: 13px;

    &:hover,
    &.active,
    &:active,
    &:focus {
      color: $indigo-700;
    }
  }
}

.input-form-group-container {
  &.password-score-0 .valid .error-message {
    color: $red-700;
  }

  &.password-score-1 .valid .error-message {
    color: $orange-500;
  }

  &.password-score-2 .valid .error-message,
  &.password-score-3 .valid .error-message,
  &.password-score-4 .valid .error-message {
    color: $jade-600;
  }

  .error-message .suggestion {
    margin-top: 4px;
    font-size: 13px;
    color: $gray-500;
  }
}

@media (max-width: 1000px) {
  .PasswordStrengthInput {
    margin-top: 0;
  }
}
