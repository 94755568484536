@import '@frontend/design-system/src/styles/_variables';

.projected-savings-panel {
  border-radius: 4px;
  border: 1px solid $gray-100;
  background-color: $gray-50;
  padding: 18px 24px;
  display: flex;

  p {
    margin: 0px;
  }

  .panel-side-left {
    white-space: nowrap;
    padding-right: 32px;
    border-right: 1px solid $gray-100;
  }

  .panel-side-right {
    width: 100%;
    max-width: 510px;
  }

  .panel-side-right-with-savings {
    padding-left: 32px;
  }

  .savings-placeholder {
    display: inline-block;
  }

  .savings-text {
    font-size: 14px;
    color: $gray-700;
  }

  .savings-text-title {
    font-size: 14px;
    font-weight: 600;
    color: $gray-900;
  }

  .savings-text-time {
    font-weight: 700;
    color: $purple-500;
  }

  .illustration-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .illustration-row-container {
    display: flex;
    align-items: center;
  }

  .illustration-row {
    display: flex;
    width: 100%;
    gap: 2px;
  }

  .illustration-spec {
    height: 16px;
    border-radius: 2px;
    transition: all 0.2s ease-out;
    box-sizing: border-box;

    &.passed {
      background-color: $jade-400;
    }

    &.failed {
      background-color: $red-400;
    }

    &.saved {
      // avoid the "save" spec-item from having a
      // transition otherwise it looks a bit wacky.
      transition: none;
      border: 1px solid $purple-500;
      color: $purple-500;
      font-size: 10px;
      font-weight: 500;
      padding: 0px 4px;
    }
  }
}

// vertical layout in smaller screens so the
// illustration doesn't overflow the screen:
@media (max-width: 600px) {
  .projected-savings-panel {
    flex-direction: column;

    .panel-side-left {
      padding-right: 0px;
      padding-bottom: 24px;
      border-right: none;
      border-bottom: 1px solid $gray-100;
    }

    .panel-side-right {
      padding-left: 0px;
      padding-top: 24px;
    }
  }
}
