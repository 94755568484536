.usage-chart {
  .usage-chart--header {
    display: flex;
    align-items: center;
    min-height: 60px;
  }

  .usage-chart--title {
    flex: 1;
  }

  .usage-chart--modes {
    list-style-type: none;
    li {
      display: inline;
      margin: 0 4px;
    }
  }

  .usage-chart--table {
    th {
      vertical-align: middle;
    }
  }

  .usage-chart--mode-btn {
    padding: 0;
    text-decoration: none;
    font-weight: 400;
  }

  .usage-chart--active-mode {
    font-weight: 600;
  }
}
