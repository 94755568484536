#terms-of-use {
  background-color: $gray-A1;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 16px;
  line-height: 24px;

  h2 {
    font-size: 24px;
    margin-bottom: 25px;
    display: inline-block;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;

    em {
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  h5 {
    margin-top: 0;
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 30px;
  }

  ul > li {
    margin-bottom: 24px;
    font-weight: 300;
  }

  em {
    text-decoration: underline;
    padding-right: 10px;
    font-style: normal;
  }

  strong em {
    text-transform: uppercase;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    color: $gray-800;
  }

  strong {
    color: $gray-800;
  }

  .bordered {
    border: 2px solid $gray-500;
    padding: 15px 15px 0 15px;
    margin-bottom: 25px;
  }
}

.terms-of-use-container {
  footer#footer {
    background-color: $gray-100;
    overflow: auto;
    text-align: center;
    height: 60px;

    a {
      display: inline-block;
      padding: 20px;
      color: $gray-400;
      font-weight: 400;
      cursor: pointer;

      &:hover,
      &.active,
      &:active,
      &:focus {
        text-decoration: none;
        color: $gray-500;
      }
    }
  }
}

.navbar-inverse {
  background-color: $gray-800;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0;
  margin-bottom: 0;

  .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-toggle {
    color: $gray-400;
    background-color: $gray-800;

    &.active,
    &.active:hover,
    &:hover {
      color: $gray-A1;
      background-color: $gray-700;
    }
  }
  ul.navbar-nav {
    margin: 0;

    .user-avatar {
      position: relative;
      top: -1px;
      margin-right: 5px;
    }

    & > li {
      & > a {
        padding-left: 20px;
        padding-right: 20px;
        cursor: pointer;

        i {
          margin-right: 5px;

          &.fa-graduation-cap,
          &.fa-question-circle {
            color: $gray-400;
            margin-right: 0;
          }
        }

        &:hover,
        &.active,
        &.active:hover,
        &:hover > .fa-question-circle {
          color: $gray-A1;
        }

        &.active,
        &.active:hover {
          color: $gray-A1;
          background-color: $gray-700;
        }
      }
    }
  }
}

.nav-img-wrapper {
  display: inline-block;
  padding: 9px 15px 3px 10px;
  color: $gray-A1;
  text-decoration: none;
  font-size: 22px;
  position: relative;

  &:hover,
  &:focus,
  &.active {
    color: $gray-A1;
    text-decoration: none;
  }

  img {
    float: left;
    width: 100px;
    height: 33px;
  }

  .beta-flag {
    position: relative;
    top: -10px;
    right: -5px;
    color: $gray-400;
    font-size: 12px;
  }
}

.navbar-right {
  margin-right: 0;
}
