.commit-author {
  display: flex;
  align-items: center;

  &__avatar {
    margin: 0 4px 0 0;
  }

  &__name {
    margin: 0;
  }

  &__no-data {
    margin: 0 auto auto 0;
    margin-left: 4px;
  }

  &.with-spacing {
    gap: 8px;
  }
}
