.reset-password-page {
  min-height: 100vh;
  background-color: #fff;
  position: relative;

  .reset-password-page-inner {
    width: 90%;
    max-width: 437px;
    margin: 80px auto 0;

    .btn-primary {
      width: 100%;
      margin-bottom: 20px;
    }

    .reset-password-success .btn-link {
      margin-top: 10px;
      padding: 0;
    }
  }
}

#reset-password-request-form,
#reset-password-form {
  .input-form-group {
    border-top: none;
    grid-template-columns: 1fr;

    &.danger {
      background-color: #ffffff;
    }

    .left-column,
    .input-form-group-contents {
      margin: 0;
    }

    .description {
      margin-bottom: 8px;
    }
  }

  .PasswordStrengthInput {
    margin-top: 0;
  }
}
