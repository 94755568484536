.orgs-list {
  background-color: #fff;

  .organization-selection {
    margin-top: 48px;
  }
  .link-to-org {
    .organization-selection-option__button {
      font-size: 16px;
      font-weight: normal;
    }
  }

  .pitch {
    margin: 24px 0;
  }

  .page-header--title h1 {
    width: 100%;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;

    .user-cell {
      padding-top: 4px;

      div {
        font-size: 14px;
        font-weight: normal;
      }
      .user-name {
        font-weight: 500;
      }
    }
  }

  &__footer {
    text-align: center;
    .create-org-wrapper {
      max-width: 700px;
      margin: 0 auto 20px auto;
      .create-org-button {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 25px;
        padding: 10px;
      }
      .create-org-separator {
        $border-color: $gray-100;
        $word-padding: 16px;
        position: relative;
        text-align: center;
        color: $gray-300;
        margin: 20px 0 40px 0;
        &:before {
          content: ' ';
          position: absolute;
          border-bottom: 1px solid $border-color;
          top: 50%;
          left: 0;
          right: calc(50% + #{$word-padding});
        }
        &:after {
          content: ' ';
          position: absolute;
          border-bottom: 1px solid $border-color;
          top: 50%;
          left: calc(50% + #{$word-padding});
          right: 0;
        }
      }
    }
  }

  .pending-invitations {
    margin: 32px 0;
  }
}

.pending-invitations--container {
  table > tbody > tr > td {
    padding: 8px 16px;
    .btn-default {
      margin-right: 8px;
    }
  }
  .pending-invitations--description {
    margin: 0 0 24px 0;
    h1 {
      margin: 0 0 18px 0;
      font-weight: 700;
    }
  }
  .pending-invitations-list {
    background-color: $gray-A1;
  }
  .pending-invitations-table {
    border: 1px solid $gray-100;

    tr:first-of-type {
      td {
        border-top-width: 0;
      }
    }
  }
  .indicator-circle {
    color: $orange-300;
    font-size: 10px;
    padding-right: 8px;
  }
}

.pending-invitation-item {
  display: grid;
  grid-template-columns: 48px 1fr auto;
  grid-template-areas:
    'logo name actions'
    'logo description actions';
  grid-gap: 0 8px;
  padding: 12px 30px;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  svg {
    grid-area: logo;
    align-self: center;
  }
  .pending-invitation-item-name {
    padding: 0 20px;
    grid-area: name;
  }
  .pending-invitation-item-description {
    padding: 0 20px;
    grid-area: description;
  }
  .pending-invitation-item-actions {
    grid-area: actions;
    align-self: center;
    justify-self: flex-end;
    .btn {
      padding: 6px 8px !important;
    }
    .btn-default {
      margin-left: 12px;
    }
  }
}

.pending-invitation-item + .pending-invitation-item {
  margin-top: 16px;
}
