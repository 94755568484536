$bullet-y-offset: 12px;

.test-history {
  margin-left: 16px;
  padding: 16px 0 8px;
  border-left: 2px solid $indigo-600;
  &__no-git-info {
    text-align: center;
    padding: 16px 0;
    div {
      max-width: 350px;
      margin: 0 auto;
      padding-bottom: 8px;
    }
  }
  &__branch {
    padding: 8px 0 0 42px;
    font-size: 12px;
    font-weight: 500;
    color: $indigo-500;
    .on-child-branch & {
      color: $purple-500;
    }
  }
  &__info {
    display: grid;
    grid-template-columns: 20px auto 1fr auto auto;
    align-items: center;
    min-height: 32px;
    .status-icon {
      margin-right: 4px;
    }
  }
  &__commit-summary {
    color: $gray-800;
    text-overflow: ellipsis;
    font-size: 14px;
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 4px 0 0;
  }
  &__modification-and-diff {
    padding: 0 0 8px;
    margin-left: 14px;
  }
  &__modification {
    font-size: 12px;
    color: $gray-500;
    &:focus {
      outline: none;
    }
    .btn-link {
      color: $gray-700;
      &:hover,
      &:focus {
        color: $gray-700;
      }
    }
    .test-history__commit-graph {
      position: relative;
      svg.bullet {
        width: 6px;
        height: 6px;
        position: absolute;
        top: $bullet-y-offset;
        left: -2px;
        .on-parent-branch & {
          left: calc(-4px - 14px);
        }
      }
      svg.commit-graph-child-top {
        width: 2px;
        height: 40px;
        position: absolute;
        top: $bullet-y-offset - 40px;
        left: 0px;
      }
      svg.commit-graph-child-bottom {
        width: 2px;
        height: 60px - $bullet-y-offset;
        position: absolute;
        top: $bullet-y-offset;
        left: 0px;
      }
      svg.commit-graph-branch {
        width: 17px;
        height: 32px;
        position: absolute;
        top: $bullet-y-offset;
        left: -14px;
      }
    }
    .empty-state-text {
      margin-left: 28px;
      margin-top: 4px;
      padding-top: 4px;
      padding-bottom: 16px;
      font-size: 14px;
    }
  }
  &__modification-details {
    overflow: hidden;
    display: inline-block;
    padding-left: 8px;
    width: 100%;
    .horizontal-list {
      margin-left: 20px;
    }
  }
  &__diff {
    padding: 16px 16px 16px 38px;
    &:first-of-type {
      padding-top: 20px;
    }
    &:last-of-type {
      padding-bottom: 0;
    }
    .on-child-branch & {
      border-left: 2px solid $purple-600;
    }
    .on-child-branch.bottom-child-commit & {
      border-left-color: transparent;
    }
    .code-snippet {
      padding: 0;
    }
  }
  .chevron-icon {
    color: $gray-400;
    &.expanded {
      transform: rotate(180deg);
      transition: transform 200ms;
    }
    &.collapsed {
      transform: rotate(0deg);
      transition: transform 200ms;
    }
  }
  .test-result-modification-type-badge {
    margin-left: 8px;
  }
}
