@import '@frontend/design-system/src/styles/_variables';

.container {
  column-gap: 14px;
  display: flex;

  :global(.cy-icon) {
    vertical-align: middle;
  }
}

.switch {
  background-color: $gray-300;
  border: 0px;
  border-radius: 50px;
  box-shadow: 0 0 0 2px transparent;
  display: inline-block;
  outline: none;
  padding: 0;
  transition: background-color 200ms ease-out;

  &:focus,
  &:hover {
    box-shadow: 0 0 0 2px $gray-100;
  }

  &[disabled] {
    background-color: rgba($gray-300, 0.6);
    cursor: not-allowed;

    &:focus,
    &:hover {
      box-shadow: 0 0 0 2px transparent;
    }
  }

  &[aria-checked='true'] {
    background-color: $jade-400;

    &[disabled] {
      background-color: rgba($jade-400, 0.6);

      &:focus,
      &:hover {
        box-shadow: 0 0 0 2px transparent;
      }
    }

    &:focus,
    &:hover {
      box-shadow: 0 0 0 2px $jade-100;
    }
  }
}

.indicator {
  background-color: $gray-A1;
  border-radius: 50px;
  box-shadow: 0px 1px 2px 0px rgba($gray-A2, 0.1);
  display: block;
  transition: transform 200ms ease-out;
}

.x16 {
  width: 16px;
  height: 10px;

  &[aria-checked='true'] {
    .indicator {
      transform: translateX(6px);
    }
  }

  .indicator {
    width: 6px;
    height: 6px;
    margin-left: 2px;
  }
}

.x24 {
  width: 24px;
  height: 12px;

  &[aria-checked='true'] {
    .indicator {
      transform: translateX(12px);
    }
  }

  .indicator {
    width: 8px;
    height: 8px;
    margin-left: 2px;
  }
}

.x32 {
  width: 32px;
  height: 16px;

  &[aria-checked='true'] {
    .indicator {
      transform: translateX(16px);
    }
  }

  .indicator {
    width: 12px;
    height: 12px;
    margin-left: 2px;
  }
}

.x48 {
  width: 48px;
  height: 24px;

  &[aria-checked='true'] {
    .indicator {
      transform: translateX(24px);
    }
  }

  .indicator {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }
}

.locked {
  color: $gray-500;
  font-size: 16px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;

    :global(.icon-light) {
      fill: $gray-50;
    }
    :global(.icon-dark) {
      stroke: $gray-300;
    }
  }
}
