.empty {
  text-align: center;
  padding: 50px 0;
  border: 1px solid $gray-100;
  background-color: $gray-A1;
  font-size: 18px;
  line-height: 34px;
  overflow: auto;
  color: $gray-400;
  border-radius: 5px;
  box-shadow: $box-shadow;

  img {
    box-shadow: $box-shadow;
    border: 1px solid $gray-100;
  }

  h2 {
    color: $jade-400;
    &.blue {
      color: $indigo-500;
    }
  }
}

.list-as-table > li.empty {
  border-bottom: 0;
}

.empty-project {
  margin-top: 30px;
  background-color: $gray-50;
  border: 0;
  box-shadow: none;

  .fa-paper-plane {
    margin-right: 5px;
  }
}
