.run-duration.parallelization-disabled {
  font-size: 0.95em;
  display: flex;
  i {
    top: -1px;
    font-size: 0.85em;
  }
  span {
    line-height: 20px;
  }
}
