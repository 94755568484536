@import '@frontend/design-system/src/styles/_variables';

.attemptsAndErrors {
  margin-top: 10px;
  width: 100%;
}

.row {
  display: grid;
  grid-template-columns: 20px calc(100% - 20px - 16px);
  grid-template-areas:
    'icon issueHeader'
    '. issueDescription';
  margin: 0 16px;
  padding: 16px 0;
  grid-column-gap: 16px;
  border-bottom: 1px solid $gray-100;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.row:last-child {
  border-bottom: none;
}

.icon {
  grid-area: icon;
  text-align: center;
  align-self: center;
  color: $gray-500;
}

.issueHeader {
  grid-area: issueHeader;
  display: flex;
  justify-content: space-between;
}

.issueTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $gray-900;
}

.issueStatus {
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: $gray-500;
}

.issueDescription {
  grid-area: issueDescription;
  font-size: 12px;
  line-height: 14px;
  color: $gray-500;
  img {
    height: 16px;
    width: 16px;
    border-radius: 50%;
  }
}

.assignedUser {
  padding: 0 4px;
}
