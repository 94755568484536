.invite-page {
  min-height: 100vh;
  padding: 0 0 96px;
  background-color: #fff;
  position: relative;
  .page-header stacked-layout--header {
    width: 100%;
  }
  .invite-page-inner {
    width: 90%;
    max-width: 700px;
    margin: 0 auto;
    .invite-page-header {
      margin-top: 64px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .copy-invite-link {
        margin-top: 15px;
      }
    }
  }
  .h3 {
    margin-top: 0;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 45px;
    color: #000;
  }
  .h5 {
    margin-top: 0;
    margin-bottom: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $gray-600;
  }
  img {
    height: 32px;
  }
}

@media (max-width: 700px) {
  .invite {
    .invite-page-inner .invite-page-header {
      margin-top: 32px;
    }
    .h5 {
      margin-bottom: 16px;
    }
  }
}

.onboarding-panel {
  z-index: 2;
  section {
    margin-bottom: 32px;
  }
  &__question {
    font-size: 16px;
  }
  &__email {
    margin-top: 8px;
  }
  .onboarding-footer {
    text-align: center;
    font-size: 12px;
    background-color: #fff;
    color: $gray-500;
    .btn {
      margin-top: 20px;
      margin-bottom: 10px;
      width: 100%;
      &.btn-link {
        width: auto;
      }
    }
  }
}
