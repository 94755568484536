.color-breakdown {
  height: 8px;
  margin-bottom: 32px;
  // NOTE: grid-template-columns determined in .tsx
  display: grid;
  &__section {
    display: inline-block;
    height: 100%;
    padding: 0;
    position: relative;
    margin: 0 2px;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      margin-left: 0;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-right: 0;
    }
  }
  &__hover-ref {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
