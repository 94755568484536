@import '@frontend/design-system/src/styles/_variables';

.shareInviteLinkCard {
  border: 1px solid $gray-100;

  :global(label.title) {
    font-size: 16px;
    color: #2e3247;
    margin-bottom: 8px;
  }

  :global(p) {
    font-size: 14px;
    margin-top: 32px;
    margin-bottom: 0;

    :global(&:first-of-type) {
      margin-top: 0;
    }
  }
}
