@import '@frontend/design-system/src/styles/_variables';

.popover {
  max-width: 411px;
  min-width: 290px;
  background-color: $gray-900;
  padding: 12px 16px;

  :global {
    .arrow::after {
      border-bottom-color: $gray-900 !important;
    }
    .popover-content {
      padding: 0;
    }
  }
}

.popoverTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $gray-A1;
}

.popoverDescription {
  font-size: 14px;
  line-height: 24px;
  color: $gray-200;
  a {
    color: $indigo-300;
    &:hover,
    &:focus {
      color: $indigo-300;
    }
  }
}

.popoverCta {
  padding-top: 4px;
  display: flex;
  justify-content: space-between;
}

.popoverLearnMore {
  align-self: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-decoration: none;
  color: $indigo-300;
  &:hover,
  &:focus {
    color: $indigo-300;
  }
}

.contactOwnerOrAdmin {
  font-weight: 500;
}
