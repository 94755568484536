@import '@frontend/design-system/src/styles/_variables';

.radio {
  [type='radio'] {
    position: absolute;
    left: 0;
    margin: 0;
    z-index: -1;
    opacity: 0.01;
  }
}

.label {
  cursor: pointer;
  font-weight: 400;
  padding-left: 28px;
  position: relative;

  &:before {
    content: '';
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    background-image: url('@DS/lib/img/radio.svg');
    background-repeat: no-repeat;
    position: absolute;
  }

  &.checked:before {
    content: '';
    background-image: url('@DS/lib/img/radio-checked.svg');
  }

  &.disabled {
    color: $gray-500;
    cursor: not-allowed;
    &:before {
      background-image: url('@DS/lib/img/radio-disabled.svg');
    }

    &.checked {
      &:before {
        background-image: url('@DS/lib/img/radio-checked-disabled.svg');
      }
    }
  }

  &:not(.checked):not(.disabled):hover:before,
  &:not(.checked):not(.disabled):focus:before {
    background-image: url('@DS/lib/img/radio-hover.svg');
  }
}

.subLabel {
  font-size: 12px;
}
