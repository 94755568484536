.cypress-integration-organization {
  overflow-x: visible;
  h4 {
    margin-top: 32px;
  }

  .table {
    text-align: left;
    margin: 8px 0 0;
    max-width: 100%;
    table-layout: fixed;

    tbody {
      tr {
        min-height: 48px;
      }
    }

    thead,
    tbody {
      $configure-width: 160px;

      th,
      td {
        padding: 8px;

        &:first-child {
          padding-left: 0 !important;
        }
        &:last-child {
          padding-right: 0 !important;
        }
      }

      th:last-child,
      td:last-child {
        text-align: right;
        width: $configure-width;
      }
      .repo-url {
        color: rgba(55, 55, 60, 0.5);
        font-size: 13px;
        line-height: 13px;
      }
      .truncate-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
      }
    }
  }

  .repo-autocomplete {
    width: 100%;
    .repo-empty-state {
      margin: 10px 0 0 0;
      padding: 10px 0 0 0;
      border-top: 1px solid $gray-100;
    }
    .repo-with-icon {
      display: flex;
      align-items: center;
    }
    .repo-autocomplete__menu {
      margin-top: 0;
    }
    .repo-autocomplete__option:not(:first-child) {
      border-top: 1px solid $gray-200;
    }
    .repo-autocomplete__option--is-selected {
      .text-muted {
        color: $gray-A1;
      }
    }
  }
}

.remove-cypress-installation {
  color: $red-800;
  &:hover {
    color: $red-800;
  }
}

.contact-an-admin-lock {
  padding: 16px;
  background-color: $indigo-100;
  color: $indigo-700;
  font-weight: 500;

  .cy-icon {
    color: $indigo-500;
  }

  a {
    color: $indigo-700;
    text-decoration: underline;
  }

  .admin-lock-icon {
    margin-right: 8px;
  }
}

.gitlab-integration-project {
  .panel-heading {
    padding-left: 10px;
  }
}
.sso-integration-icon {
  color: $gray-500;
  path {
    fill: $gray-50;
  }
}
