@import '@frontend/design-system/src/styles/_variables';

.container {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $gray-100;

  &.darkTheme {
    border: 1px solid $gray-800;

    .pillContent {
      svg > path {
        fill: $gray-400;
      }

      > div {
        border-color: $gray-800;

        > svg path {
          fill: $gray-900;
          stroke: $gray-400;
        }
      }
    }
  }
}

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  gap: 4px;
  padding: 0 6px;
  font-size: 14px;
  color: $gray-700;
  font-weight: 500;
  margin: 0;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $indigo-100;
  }
}

.pillContent {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
