@import '@frontend/design-system/src/styles/_variables';

.header-control-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray-900;
  padding-right: 16px;

  .action-buttons {
    display: flex;
    align-items: center;
    flex: none;
    margin-left: 16px;

    .close-button {
      flex: none;
      background: transparent;
      padding: 0px;
      height: 32px;
      width: 32px;
      margin-left: 16px;
      .icon {
        margin-left: 7px;
      }
    }
  }
}

.test-replay-modal {
  * ::selection {
    background-color: rgba(208, 210, 224, 0.3);
  }

  :global {
    .modal-dialog {
      margin: 0px;
      overflow: hidden;
      border-radius: 8px;
      margin-top: 2vh;
      margin-left: 2vw;
      height: 96vh;
      width: 96vw;
      .modal-content {
        background-color: $gray-1000;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.test-replay-modal--processing-content {
  height: 100%;
  background-color: $gray-900;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    height: 56px;
    width: 56px;
    path {
      fill: $gray-500 !important;
    }
    circle {
      fill: $gray-200 !important;
    }
  }

  .heading {
    margin-top: 24px;
    color: $gray-A1;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    padding: 0 24px;
  }

  .details {
    margin-top: 4px;
    color: $gray-200;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.errorContentOverride {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .errorContentWrapper {
    // Workaround margin to make the error content div appear centered (compensates for header margin)
    margin-top: -70px;
    width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    h2 {
      color: $gray-200;
      font-size: 18px;
      margin-top: 30px;
    }
    span {
      color: $gray-200;
      margin-top: 10px;
    }
    button {
      color: $gray-200;
      margin-top: 24px;
      background-color: inherit;
      border: 1px solid $gray-600;
    }
    a {
      text-decoration: none;
      :hover {
        text-decoration: none;
      }
    }
  }
}
