.convert-org-modal {
  .convert-org-form {
    .form-bottom {
      margin-top: 0;
      padding: 16px;
      border-top: 1px solid $gray-100;
      p {
        margin-bottom: 16px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      ul {
        margin: 0 0 16px 16px;
      }
    }
  }
}

.org-settings-form {
  .personal-checkbox-group .input-form-group {
    border-top: none;
    padding-top: 0;
  }
}
