@import '@frontend/design-system/src/styles/_variables';

.flakyBadge {
  background: $orange-50;
  padding: 4px;
  border-radius: 2px;
  color: $orange-600;
  font-weight: 500;
  font-size: 10px;
  white-space: nowrap;
  height: fit-content;
}
