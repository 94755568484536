.stacked-layout.no-content-padding {
  display: flex;
  flex-direction: column;
  height: 100%;

  .stacked-layout--header {
    position: relative;
  }
  .stacked-layout--content {
    flex-grow: 1;
    overflow-y: auto;
  }
}
