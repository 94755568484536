@import '@frontend/design-system/src/styles/_variables';

.container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.borderedContainer {
  padding: 0;
  margin: 0;
  border: 1px solid $gray-100;
  border-radius: 4px;
  font-size: 14px;
  display: inline-flex;
  width: 100%;
}

.icon {
  margin-right: 0.5rem;
}

.label {
  margin-right: 1rem;
}

.labelVariant {
  padding: 8px 16px;
  color: $purple-500;
  font-family: $font-mono;
  border-right: 1px solid $gray-100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyTextHolder {
  width: auto;
  display: flex;
  align-items: center;
}

.copyTextHolderVariant {
  display: inline-flex;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
  align-items: center;
}

.copyText {
  font-family: $font-mono;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 0px 8px;
}

.copyTextVariant {
  color: $gray-800;
  overflow: hidden;
  font-family: monospace;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0;
  :global(.code-icon) {
    display: none;
  }
}

.copyButtonHolder {
  margin: 3px;
}

.copyButton {
  background-color: $indigo-50;
  color: $indigo-500;
  height: 32px;
  display: flex;
  align-items: center;
  // TODO: What do we want the hover logic to look like? Figma mocks don't appear to demonstrate this
  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    background-color: $indigo-50 !important;
    color: $indigo-500 !important;
  }
  div {
    margin-left: 0.5rem;
  }
}

.copyButtonVariant {
  background-color: white;
  height: 32px;
  display: flex;
  align-items: center;
  div {
    // hide "Copy" text
    display: none;
  }
  &:hover,
  &:focus,
  &:hover {
    outline: none !important;
    background-color: white !important;
    color: $indigo-500 !important;
  }
}
