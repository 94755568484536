.embed-responsive {
  margin-bottom: 40px;
  box-shadow: none;
  background-color: $gray-900;
  border: 1px solid $gray-100;
}

.video-content {
  min-height: 400px;
}

.video-preview {
  border: none;
  padding: 0;
  margin: 0;
  font-size: 40px;
  position: relative;
  line-height: 0;
  color: $gray-700;
  &:focus {
    outline: none;
  }
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $gray-100;
  }
  &__overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:after {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: $gray-100;
      z-index: -1;
    }
  }
}

.video-js {
  background-color: $gray-900;
}

.download-vid-btn {
  margin-left: 10px;
}

/**
 * videojs-vjsdownload
 */
.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload {
  cursor: pointer;
  display: block;
  font-family: FontAwesome;
}

.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload:before {
  content: '\f019';
  font-size: 1.4em;
}
