.container {
  display: flex;
  align-items: center;
}

.name {
  display: inline-block;
  max-width: 260px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
