.chart-legend {
  .chart-legend--item {
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    margin-left: 16px;
  }
  .chart-legend--marker {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    display: inline-block;
    margin-right: 4px;
  }
}
