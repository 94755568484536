@import '@frontend/design-system/src/styles/_variables';

.onboardingChecklist {
  background: rgba(0, 0, 0, 0.29);
  padding: 15px;
}

.onboardingChecklistHeader {
  color: $gray-A1;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;

  :global(.react-chart) {
    margin-left: 5px;
    margin-right: 9px;

    circle {
      stroke: $gray-800;
    }

    text {
      text-anchor: middle;
      dominant-baseline: central;
      // Overrides library to center "33%" and "67%" in the CirclePie
      transform: translate(0.69em, -0.35em);
    }
  }
}

.onboardingChecklistHeaderCompleted {
  :global(.react-chart) {
    text {
      // Overrides library to center "100%" in the CirclePie
      transform: translate(0.96em, -0.35em);
    }
  }
}

.onboardingComplete {
  flex-grow: 1;
}

.onboardingClose {
  color: $gray-A1;
  padding: 0 2px 2px 2px;
  align-self: flex-center;

  &:active,
  &:hover,
  &:focus {
    color: $gray-A1;
  }
}

.completionPercent {
  font-size: 12px;
}

.inviteUsers {
  margin-left: 58px;
  color: $gray-100;

  :global(.copy-invite-link) {
    margin-left: -10px;
    margin-top: 4px;
    font-size: 14px;
    text-decoration: underline;
    color: $gray-A1;

    &:active,
    &:hover,
    &:focus {
      color: $gray-A1;
    }
  }
}

.onboardingChecklistItem {
  display: block;
  margin-left: 40px;
  padding: 6px 8px;
  list-style: none;
  width: fit-content;
  color: $gray-300;

  &:first-child {
    margin-top: 4px;
  }

  &:nth-child(n + 3) {
    margin-top: 10px;
  }

  &:active,
  &:hover,
  &:focus {
    color: $gray-300;
    text-decoration: none;
  }

  i {
    margin-right: 9px;
  }
}

.onboardingChecklistItemActive {
  background-color: $gray-900;
  border: 1px solid $jade-400;
  border-radius: 6px;
  color: $gray-A1;

  &:active,
  &:hover,
  &:focus {
    color: $gray-A1;
  }
}

.checkmarkIcon,
.circleIcon {
  color: $jade-400;
}

.chevronRightIcon {
  margin-left: 10px;
  font-weight: 600;
}
