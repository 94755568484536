$run-details-title-height: 45px;

.run-header {
  .page-header--content {
    min-height: 100px;
    height: auto;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      'title stats'
      'tabs tabs';
    align-items: center;
    padding: 0px;
    .run-tabs {
      background-color: $gray-A1;
      border-top: 1px solid $gray-200;
      padding: 0px 24px;
      button {
        display: block;
      }
      .cy-icon {
        float: right;
        cursor: pointer;
        color: $gray-500;
      }
    }
  }

  .run-list-item {
    padding: 8px 24px;
    height: 64px;
    &--more {
      width: 40px;
    }

    &--attributes {
      overflow: hidden;
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    .page-header--content {
      grid-template-columns: 24px 1fr auto;
      grid-template-areas:
        'icon title stats'
        'tabs tabs tabs';
    }
  }

  h1 {
    .status-icon {
      vertical-align: middle;
      margin-left: 8px;
      margin-bottom: 4px;
      font-size: 18px;
    }
  }

  .run-stats {
    grid-area: stats;
    opacity: 1;
    display: flex;
  }

  .run-tabs {
    grid-area: tabs;
    opacity: 1;
    display: block;
    margin-top: 0px;

    .run-tab-icon {
      height: 16px;
      display: inline-block;
      margin-left: 8px;
      vertical-align: text-top;
    }
  }
}

#run-show {
  height: 100%;
  .status-subtitle {
    color: $gray-400;
    font-size: 0.8em;
    margin-left: 5px;
    font-style: italic;

    a {
      margin-left: 5px;
      cursor: pointer;
    }
  }

  #run-tab-content {
    height: 100%;

    > div {
      height: 100%;
    }
    .empty {
      background-color: $gray-50;
      color: $gray-600;
      p {
        color: $gray-600;
      }
    }
  }

  .run-tabs-wrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-100;
    min-height: 38px;

    .nav-tabs {
      margin-top: 0;
    }
  }

  & > .empty {
    margin-top: 30px;
  }

  .tab-pane {
    padding: 20px 24px $beacon-padding;
  }

  .specs-header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 16px;
    align-items: center;
    margin-bottom: 16px;
  }

  .test-results-tab {
    .specs-running-notice {
      margin: 0 0 16px 0;
    }
  }

  .chart-toggle {
    justify-self: end;
    .toggle-label {
      margin-right: 10px;
      font-weight: 500;
      color: $gray-500;
      top: 1px;
      position: relative;
    }

    .btn.btn-light {
      font-weight: normal;
      color: $gray-700;
      border-color: $gray-300;
      font-size: 0.95em;

      i {
        margin-right: 3px;
      }
    }

    .btn.active,
    .btn:hover {
      background-color: $gray-200;
    }

    .btn:disabled,
    .btn:disabled:hover {
      background-color: $gray-100;
    }

    .btn,
    .btn:focus,
    .btn:active,
    .btn:hover {
      outline: 0;
    }
  }

  .specs-list {
    display: block;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 0;
    list-style: none;
    padding: 0;

    .screenshot-count {
      font-size: 0.85em;
      line-height: 20px;
      height: 20px;
      vertical-align: top;
      display: inline-block;
      padding-left: 4px;
    }

    .graph-info {
      overflow: hidden;
      &--canceled {
        margin: 8px 0 4px 0;
        font-size: 12px;
        color: $gray-600;
      }
    }

    .timeline-tooltip-wrapper {
      display: flex;
      margin: 8px 0 5px;
      position: relative;
      min-height: 15px;

      & > span {
        display: flex;
      }
    }

    .loader-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .eta {
        font-size: 0.85em;
        color: $gray-300;
        margin-top: -4px;

        i {
          margin-right: 5px;
          font-size: 0.8em;
          top: -1px;
          position: relative;
        }
      }
    }

    .graph-loader-holder {
      width: 220px;
      height: 28px;
      overflow: hidden;
    }

    .test {
      background: $jade-400;
      height: 15px;
      width: 100%;
      border-left: solid 1px $gray-A1;
      border-right: solid 1px $gray-A1;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }

      &.state-passed {
        border-left-color: $jade-400;
        background: $jade-400;
      }

      &.state-failed {
        border-left-color: $red-400;
        background: $red-400;
      }

      &.state-pending {
        border-left-color: $gray-400;
        background: $gray-400;
      }

      &.state-skipped {
        border-left-color: $gray-400;
        background: $gray-400;
      }
    }

    .width-helper {
      display: block;

      &.machine-graph {
        position: relative;
        height: 30px;

        .timeline-tooltip-wrapper {
          position: absolute;
        }
      }
    }

    .machine-view {
      border-bottom: 1px solid $gray-100;
    }
  }

  .single-spec.instance-title {
    box-sizing: border-box;
    width: 100%;
    border: 0;
    border-top: 1px solid $gray-100;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: $gray-A1;

    &:first-child {
      border-top: 0;
    }

    & > div:last-child {
      margin-top: auto;
    }

    .spec-prioritized-by-failed-specs {
      color: $gray-500;
      display: flex;
      align-items: center;
      border-left: 1px solid $gray-50;
      padding: 0 16px;
    }
  }

  .spec-list {
    & > .alert {
      margin-bottom: 20px;
      font-size: 0.9em;
      padding: 6px 15px;
      border-radius: 0;
      border: 0;
      line-height: 18px;
      i {
        margin-right: 5px;
      }
    }
  }

  .spec-group {
    box-shadow: $box-shadow;
    list-style: none;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    & > .alert {
      margin-bottom: 0;
    }

    &.is-collapsed .specs-list {
      display: none;
    }

    .group-info {
      align-content: center;
      border-top: solid 1px $gray-100;
      border-bottom: solid 1px $gray-100;
      display: flex;
      align-items: center;
      background-color: $gray-50;

      .run-stats {
        padding: 0 16px;
        border-left: 1px solid $gray-100;
      }

      .label {
        margin: 0 10px 0 0;
      }

      .group-arrow {
        display: flex;
        flex: 0 0 30px;
        background-color: $gray-100;
        justify-content: center;
        align-self: normal;
        align-items: center;
        cursor: pointer;
      }

      .group-name {
        display: flex;
        flex: 0 0 175px;
        align-items: flex-end;
        text-align: right;
        justify-content: flex-end;
        padding-right: 15px;
      }

      .duration {
        font-size: 0.9em;
        white-space: nowrap;

        a {
          color: $gray-700;
          border-bottom: 1px dotted $gray-600;

          &:hover,
          &:active,
          &:visited {
            text-decoration: none;
            color: $gray-900;
            border-bottom: 1px solid $gray-900;
          }
        }

        .btn {
          background-color: $gray-100;
          font-size: 0.9em;
          padding: 2px 5px;
          position: relative;
          top: -1px;

          i {
            margin-left: 3px;
            margin-right: 0;
          }
        }

        i {
          margin-right: 5px;
        }
      }

      .env {
        display: flex;
        flex: 0 0 235px;
        flex-direction: row;
        border-right: 1px solid #eff0f0ee;

        & > div {
          border-left: 1px solid $gray-100;
          display: flex;
          padding: 9px 12px;
          align-items: center;
        }

        .total-dur {
          color: $gray-700;
          flex: 0 0 95px;
          text-align: right;
          justify-content: center;
          font-size: 0.9em;

          .cy-duration-icon {
            vertical-align: bottom;
          }
        }

        .os,
        .browser,
        .testing-type {
          color: $gray-700;
          flex-grow: 1;
          font-size: 0.9em;
          justify-content: center;

          .browser-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            i {
              align-self: center;
            }
            img,
            svg {
              margin-bottom: 3px;
              margin-right: 3px;
            }
          }
        }

        .os {
          font-size: 1em;
        }
      }
    }
  }

  .group-detail-wrapper {
    display: flex;
    flex: 1 0;
    min-width: 0;
    flex-direction: row;
  }

  .group-detail {
    display: flex;
    align-items: center;
    flex: 1 0;
    padding: 14px 15px;
    min-width: 0;
    justify-content: space-between;

    .group-name {
      display: flex;
      padding-right: 10px;
      margin-right: 10px;
      font-size: 0.9em;
    }
  }

  .group-detail-name {
    width: 65%;
  }

  .stats-cols-4 .stats {
    flex: 0 0 175px !important;
  }

  .stats-cols-3 .stats {
    flex: 0 0 135px !important;
  }

  .stats-cols-2 .stats {
    flex: 0 0 105px !important;
  }

  .stats-cols-1 .stats {
    flex: 0 0 75px !important;
  }

  .stats-cols-0 .stats {
    flex: 0 0 0 !important;
    padding: 0 !important;
    border-left: 0 !important;
  }

  .tooltip-trigger {
    cursor: pointer;
  }

  .archived-run-info {
    margin: 26px auto;
    text-align: center;
    font-weight: normal;
    color: $gray-400;

    h3 {
      color: $gray-400;
    }

    a {
      color: rgb(51, 134, 212);
      font-weight: 500;
      cursor: pointer;
    }

    .icon-archived {
      color: rgb(218, 218, 222);
    }
    .icon-unarchive {
      color: rgb(51, 134, 212);

      svg {
        vertical-align: middle;
      }
    }
  }

  .archive-run-tag {
    color: $orange-900;
    &:hover {
      color: $orange-900;
      text-decoration: none;
    }
  }

  .error-panel {
    width: 100%;

    & > .stats.alert.alert-warning {
      padding: 0 12px;
      margin: 0;
    }
  }

  .error-stack {
    color: $red-500;
    & > span:not(:first-child) {
      padding-left: 15px;
    }

    .url {
      color: $gray-500;
    }
  }

  .machine-header {
    .main-panel {
      padding: 8px 15px;
    }

    .label.label-machine {
      margin-left: 0;
    }

    .top-details {
      display: flex;
      justify-content: space-between;
      padding: 0;

      .running-spec {
        font-size: 0.95em;
        i {
          margin-right: 3px;
        }
      }

      .num-specs {
        font-weight: 500;
        font-size: 0.9em;
      }
    }
  }

  .errored,
  .noTests,
  .timedOut,
  .overLimit,
  .canceled {
    border-left-color: $orange-400;
  }

  .failed {
    border-left-color: $red-400;
  }

  .passed {
    border-left-color: $jade-400;
  }

  .running {
    border-left-color: $indigo-400;
  }

  .null {
    border-left-color: $gray-400;
  }
}

.instance-title {
  background-color: $gray-50;
  border: 1px solid $gray-100;
  line-height: 32px;
  overflow: auto;
  padding: 0;

  .label.label-machine {
    margin-left: 10px;
    padding-top: 4px;
    i {
      margin-right: 5px;
    }
  }

  .label-default {
    background-color: $gray-500;
  }

  &.unclaimed {
    .spec-name {
      color: $gray-400;
    }

    .envs i {
      color: $gray-200;
    }
  }

  .instance-left-title {
    padding: 8px 15px;
    font-family: $font-mono;
    width: 55%;
    overflow: auto;
    display: flex;

    .label {
      margin-left: 5px;

      i {
        margin-right: 3px;
      }
    }
  }

  .vid-duration {
    color: $gray-500;
    margin-left: 5px;
    font-size: 0.95em;
  }

  .stats {
    list-style: none;
    flex: 0 0 197px;
    display: flex;
    align-items: center;
    text-align: right;
    font-size: 0.9em;
    justify-content: flex-end;
    color: $gray-600;

    li {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    i {
      margin-right: 3px;
    }
  }

  a.spec-expand {
    margin: 0 8px 0 0;
    color: $gray-800;

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:focus:hover {
      text-decoration: none;
      color: $gray-800;
    }
  }

  .pull-left {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .btn-light {
    margin-right: 10px;
  }

  .copy-share-link {
    margin-left: 10px;
    cursor: pointer;
  }
}

.env-data {
  width: 45%;
  line-height: 32px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > div {
    border-left: 1px solid $gray-100;
    padding: 8px 16px;
  }

  .cy-duration-icon {
    vertical-align: text-bottom;
  }
}

.env-browser {
  font-size: 0.9em;
  margin-left: 5px;
  display: flex;

  svg {
    width: 12px;
  }
  span {
    font-size: 0.9em;
    i {
      margin-right: 5px;
    }
  }

  span:last-child {
    margin-left: 10px;
  }
}

.instance-title .single-spec-header,
.machine-header {
  display: flex;
  flex-direction: row;

  .main-panel {
    display: flex;
    flex-direction: column;
    flex: 1 0;
  }

  .details-panel {
    display: flex;
    flex-direction: row;
    line-height: 14px;
    border-right: 0;
  }

  .status {
    display: flex;
    flex: 0 0 30px;
    align-items: center;

    .status-text {
      width: 30px;
      text-align: center;
    }

    &.failed {
      background-color: $red-400;
    }

    &.passed {
      background-color: $jade-400;
    }

    &.running {
      background-color: $indigo-400;
    }

    &.canceled,
    &.overLimit,
    &.timedOut,
    &.noTests,
    &.errored {
      background-color: $orange-400;
    }

    &.null,
    &.unclaimed {
      background-color: $gray-400;
    }
  }

  .spec-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    flex: 1 0;
    min-width: 0;
    padding: 8px 15px 8px 15px;
    color: inherit;
    text-decoration: none !important;
  }

  .spec-name {
    display: flex;
    font-family: $font-mono;
    padding-top: 7px;
    font-size: 0.85em;
    align-items: center;

    .label-warning {
      margin-right: 5px;
      margin-left: 8px;
    }
  }

  .new-badge {
    font-style: normal;
    line-height: 8px;
    padding: 0 0 0 8px;
  }

  .spec-flaky-badge {
    font-style: normal;
    line-height: 8px;
    padding: 0 8px;
  }

  .all-details {
    display: flex;
    flex-direction: row;
    flex: 1 0;
    align-items: stretch;
    min-width: 0;
  }

  .all-envs {
    display: flex;
    flex-direction: row;
    flex: 0 0 95px;
  }

  .envs {
    flex: 0 0 60px;
    align-content: center;
    display: flex;
    padding: 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-left: 1px solid $gray-50;

    .browser,
    .os {
      color: $gray-400;
      line-height: 12px;
    }
  }

  .env-duration-wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 15px 12px;
    text-align: right;
    color: $gray-700;
    flex: 0 0 95px;
    border-left: 1px solid $gray-50;
    font-size: 0.95em;

    .cy-duration-icon {
      vertical-align: bottom;
    }
  }

  .spec-actions {
    flex: 0 0 140px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-left: 1px solid $gray-50;
    color: $gray-700;

    & > div {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .btn {
      &:disabled {
        // Had to add this due to tooltip bug
        // https://github.com/react-component/tooltip/issues/18
        pointer-events: none;
        color: $gray-400;
      }
    }
  }

  .run-stats {
    padding: 0px 16px;
    border-left: 1px solid $gray-50;
  }
}

.label.label-machine {
  background-color: $gray-A1;
  color: $gray-600;
  margin-left: 10px;
  line-height: 16px;
  vertical-align: middle;
  position: relative;
  padding-bottom: 2px;
  padding: 1px 4px;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid $gray-500;
  top: -3px;
  opacity: 0.8;
  transition: background-color 0.2s ease;
  transition: color 0.2s ease;
  transition: opacity 0.2s ease;
  cursor: pointer;

  &.highlight {
    background-color: $gray-500;
    opacity: 1;
    color: $gray-A1;
  }
}

.instance-title,
.spec-header-bar {
  display: flex;
  justify-content: space-between;
}

.text-muted.italic {
  color: $gray-400;
  em {
    border-bottom: 1px solid $gray-300;
  }
}

.timeline-tooltip-data {
  .timeline-tooltip-data-item:not(:last-child) {
    margin-bottom: 12px;
  }
  .failed-test-link {
    color: $indigo-600;
  }
}
