.test-trend-branch {
  margin: 0 0 18px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  &__icon {
    margin: 0 4px 0 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  &__edit-branch {
    color: $gray-500;
    opacity: 0;
    padding-left: 4px;
    cursor: pointer;
  }
  &__branch-container {
    display: flex;
    align-items: center;
    flex: 1;
  }
  &__branch-container:hover {
    .test-trend-branch__edit-branch {
      opacity: 1;
    }
  }
  &__branch-name {
    margin: 0;
  }
  &__stat-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
  &__add-integration-branch {
    color: $indigo-500;
    cursor: pointer;
    margin-top: 4px;
  }
  &__add-integration-branch-btn {
    padding-left: 0;
  }
}
