@import '@frontend/design-system/src/styles/_variables';

.none {
  color: $gray-500;
}

.low {
  color: $orange-400;
}

.medium {
  color: $orange-600;
}

.high {
  color: $orange-900;
}
