.nav-tabs {
  margin-top: 10px;

  & > li {
    & > a,
    & > button {
      border: 0;
      border-bottom: 3px solid transparent;
      border-radius: 0;
      cursor: pointer;
      margin-right: 0;
      text-transform: capitalize;
      color: $gray-800;

      i {
        margin-right: 5px;
        cursor: pointer;
      }

      &:focus,
      &:hover {
        background-color: rgba($indigo-600, 0.15);
      }
    }

    & > a.active,
    &.active > a,
    & > button.active,
    &.active > button {
      border: 0;
      border-bottom: 3px solid $indigo-500;
      cursor: pointer;
      font-weight: 700;
      color: $indigo-600;

      &:hover,
      &:focus {
        background-color: rgba($indigo-600, 0.15);
        border: 0;
        border-bottom: 3px solid $indigo-600;
        cursor: pointer;
      }
    }

    & > button {
      background: none;
      position: relative;
      display: block;
      padding: 8px 16px;
      margin: 0;
    }
    & > button,
    & > button:focus,
    & > button:active {
      border: none;
      outline: none;
    }
  }

  .run-tab-badge {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 8px;
    margin-bottom: 1.5px;
    margin-left: 8px;
  }

  .run-tab-badge-gray {
    background-color: $gray-50;
    color: $gray-700;
  }

  .run-tab-badge.badge {
    border-radius: 4px;
    padding: 3px 4px;
  }

  li.active {
    .run-tab-badge-gray {
      background-color: $indigo-50;
      color: $indigo-600;
      font-weight: 700;
    }
  }
}
