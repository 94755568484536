.stacked-layout {
  width: 100%;
  min-width: 375px;
  .app--banners {
    z-index: 3;
  }

  .stacked-layout--header {
    position: sticky;
    top: 0;
    width: 100%;
  }

  .stacked-layout--content {
    padding: 24px 24px $beacon-padding;
    height: 100%;
  }

  &.no-content-padding {
    .stacked-layout--content {
      padding: 0;
    }
  }
}

// TODO: possibly remove shim class once we have globally set background-color as $gray-A1.
// Used in between style transitions to Cloud 2.0,for overriding background color of v1 view.
.shim-background-color {
  .stacked-layout--content {
    // account for page header
    min-height: calc(100vh - 80px);
    background-color: $gray-A1;
    overflow-x: hidden;
  }
}

.shim-background-color-login {
  .stacked-layout--content {
    // account for page header
    min-height: calc(100vh - 70px);
  }
}
