.ll-recommendation--cta-block {
  display: flex;
  box-sizing: border-box;
  width: 33.33%;
  padding: 50px;
  flex-direction: column;
  justify-content: center;

  & > div {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
  }

  h4 {
    color: $gray-100;
    font-size: 1.1em;
    line-height: 1.3em;
  }

  p {
    font-size: 0.8em;
    color: $gray-100;
  }

  .btn {
    align-self: self-start;
  }
}
