@import '@frontend/design-system/src/styles/_variables';

.large-thunder-ellipse {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background-color: $gray-100;
}

.empty-recommendations {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  .empty-title {
    align-self: flex-start;
    justify-self: flex-start;
    color: $gray-700;
    font-weight: 400;
    font-size: 16px;
  }

  .icon-box {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.recommendations-tooltip {
  &.run-status-tooltip {
    .rc-tooltip-inner {
      // margin-top: -4px;
      background-color: $gray-900;
    }

    .rc-tooltip-arrow {
      // margin-top: -4px;
      border-bottom-color: $gray-900;
      filter: drop-shadow(0px -1px 1px $gray-400);
    }

    .recommendation-tooltip-overlay {
      color: $gray-A1;
      text-align: center;
      padding: 12px 16px;
      font-weight: 300;
      font-size: $font-base-size;
      max-width: 270px;

      p {
        margin: 0;
      }

      a {
        width: 100%;
        display: block;
        text-decoration: none;
        color: $indigo-300;
      }

      a:hover {
        color: $indigo-300;
        text-decoration: none;
      }
    }
  }
}

.recommendation-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;

  border-bottom: 1px solid $gray-100;

  &:last-child {
    border-bottom: 1px solid transparent;
  }

  p {
    display: inline-block;
    font-size: $font-base-size;
    font-weight: 400;
    margin: 0;

    &.count-delta-chip {
      height: 16px;
      font-size: 12px;
      font-weight: 400;
      padding: 1px 3px 17px 3px;
      background-color: $gray-100;
      border-radius: 4px;
      text-align: center;
    }
  }

  .row-body {
    display: flex;
    align-items: center;

    .row-icon {
      margin-right: 14px;
    }

    .row-content {
      .row-title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: $gray-900;

        .row-info {
          margin-left: 8px;
        }
      }

      .row-details {
        font-weight: 400;
        font-size: $font-base-size;
        color: $gray-700;
      }
    }
  }

  .single-estimate {
    display: flex;
    align-items: center;

    // prevent large group names from
    // breaking the Recommendations panel:
    .parallelization-group-name {
      max-width: 60px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    // allow more space when the
    // RunOverview panel is column layout:
    @media (max-width: 1250px) {
      .parallelization-group-name {
        max-width: 250px;
      }
    }

    .dot-divider {
      margin-bottom: -4px;
    }

    > * {
      margin-right: 5px;
    }
  }
}

.feature-cta-button {
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 10px;
  border: 1px solid $gray-100;

  &.icon-right {
    svg {
      margin-left: 8px;
    }
  }

  &.icon-left {
    flex-direction: row-reverse;
    svg {
      margin-right: 8px;
    }
  }

  &:hover {
    border: 1px solid $gray-300;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
}
