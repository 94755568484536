@import '@frontend/design-system/src/styles/_variables';

.container {
  padding: 16px;
  margin: 0;
  border-radius: 4px !important;
  background-color: $gray-A1;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none !important;
  cursor: pointer;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: $gray-800;
  line-height: 24px;
  margin-bottom: 4px;
}

.contentRow {
  color: $gray-600;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.canceled,
.default {
  border-top: 4px solid $gray-300;
}

.running {
  border-top: 4px solid $indigo-400;
}

.timedOut,
.errored,
.overLimit {
  border-top: 4px solid $orange-400;
}

.failed {
  border-top: 4px solid $red-400;
}

.passed {
  border-top: 4px solid $jade-400;
}
