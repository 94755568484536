@import '~normalize.css/normalize.css';
@import '@frontend/design-system/src/styles/index.scss';

// Plugins
@import '~video.js/dist/alt/video-js-cdn.css';
@import '~react-photoswipe/lib/photoswipe.css';
@import '~react-vis/dist/style.css';
@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';

// Fonts
$fa-font-path: '../../../node_modules/font-awesome/fonts';
@import 'font-awesome/scss/font-awesome.scss';
// NOTE: Temp fix as we should look into upgrading font-awesome to 6.0 to align with test-runner
.fas {
  @extend .fa;
}
.far {
  @extend .fa;
}
@import '~roboto-fontface/css/roboto/roboto-fontface.css';
@import '~@fontsource/poppins/latin.css';
