.cypress-integration-project {
  .btn-primary-gitlab {
    background-color: $indigo-500;
    color: white;
  }
  .panel-heading {
    .panel-title {
      display: flex;
      vertical-align: middle;
      align-items: center;
    }
    .icon {
      margin-right: 16px;
      height: 32px;
      svg {
        height: 32px;
        width: 32px;
      }
    }
  }
  .alert {
    margin-bottom: 0;
    border-radius: 0;
  }
  form {
    overflow: visible;
  }
  .description-container {
    padding: 0 25px;
    display: flex;
    margin-bottom: 16px;
    .description {
      margin-left: 30px;
    }
  }
  .table {
    tbody {
      border: none;
    }
    td {
      padding: 11px 0;
      position: relative;
    }
  }
  .repo-autocomplete {
    width: 100%;
    .repo-with-icon {
      display: flex;
      align-items: center;
    }
    .repo-autocomplete__menu {
      margin-top: 0;
    }
    .repo-autocomplete__option:not(:first-child) {
      border-top: 1px solid $gray-200;
    }
    .repo-autocomplete__option--is-selected {
      .text-muted {
        color: $gray-A1;
      }
    }
  }
  .section-heading {
    border-bottom: none;
  }
  .left-column {
    max-width: 460px;
  }
  .col-sm-6 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .cypress-empty-state-cta {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: $gray-600;
  }

  .cypress-integration-title {
    display: flex;
    align-items: center;
    img {
      max-width: 32px;
      margin: 0 16px 0 0;
    }
  }
}
