.oss-plan-request {
  margin-top: 16px;
  &--cta {
    color: $gray-900;
  }
  &--show-form {
    cursor: pointer;
    color: $indigo-600 !important;
  }
  &--list {
    padding-left: 16px;
    padding-bottom: 24px;
  }
}

.oss-plan-request--form {
  // This is a longer form, so add some extra room between rows to help break it up.
  .form-group {
    margin-bottom: 24px;
  }
}
