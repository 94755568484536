.page-header {
  z-index: 4;
  position: relative;
  padding: 0;
  margin: 0;
  border-bottom: 0;

  .page-header--content {
    z-index: 10;
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: 'title actions';
    align-items: center;
    height: $page-header-height;
    padding: 0 20px;
    background-color: $gray-A1;
    border-bottom: 1px solid $gray-100;

    .page-header--title {
      display: flex;
      h1 {
        grid-area: title;
        font-weight: 500;
        font-size: 16px;
        margin: 0;
        padding: 0;
        width: 100%;
      }
      .page-header--title-beta {
        font-weight: 400;
        color: $orange-500;
        padding-left: 3px;
      }
      .cy-security-lock-locked-icon {
        vertical-align: baseline;
      }
    }
  }

  .page-header--fancy-shadow {
    z-index: 9;
    position: absolute;
    left: 2.5%;
    bottom: 0px;
    width: 95%;
    height: 10px;
    border-radius: 100%;
  }

  .page-header--actions {
    grid-area: actions;
    margin-left: auto;
  }

  .page-header--nav-trigger {
    display: none;
  }

  @media screen and (max-width: $screen-sm-max) {
    .page-header--content {
      grid-template-columns: 24px 1fr auto;
      grid-template-areas: 'icon title actions';
    }

    .page-header--nav-trigger {
      grid-area: icon;
      display: block;
    }
  }
}
