.project-tags-filter {
  &--footer {
    text-align: center;
    svg {
      width: 12px;
      height: 12px;
    }
    .btn-link {
      .dropdown.open & {
        text-decoration: none;
      }
    }
  }
  &--editing-option {
    width: 100%;
  }
  .select__menu,
  .select__menu-list .select__option:last-child {
    border-radius: 0;
  }
}

.notification-integrations {
  .project-tags-filter {
    .label {
      font-size: inherit;
      font-weight: inherit;
    }
  }
}
