@import '@frontend/design-system/src/styles/_variables';

.container {
  border-bottom: 1px solid $gray-200;
}

.header {
  margin: unset;
  button {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: unset;
    border: unset;
  }
}

.icon {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: $gray-100;
  }
  svg {
    color: $gray-900;
    path {
      stroke-width: 1.5px;
    }
  }
}

.title {
  display: flex;
}

.subtitleContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 0 2px;
}

.subtitleContainerMaxWidth {
  max-width: 248px;
}

.subtitle {
  margin: 0 2px 0 0;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: $gray-500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
