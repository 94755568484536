@import '@frontend/design-system/src/styles/_variables';

.runStats {
  display: flex;
  pointer-events: auto;

  &.fullWidth {
    width: 100%;
  }

  a,
  a:hover,
  a:focus,
  a:active,
  a:visited {
    text-decoration: none;
  }

  :global(.spec-group) & {
    display: block;
    height: 24px;
    margin-right: 8px;
  }

  :global(.group-info) & {
    margin-top: 12px;
  }

  :global(.single-spec-header) & {
    margin-top: 20px;
  }

  &.darkTheme {
    .flakyIconV2::before {
      content: '';
      border-right: 1px solid $gray-800;
      height: 12px;
      margin-right: 2px;
    }
  }
}

.flakyBadge {
  margin: 2px 8px 0px 0px;

  :global(.row-content__title) {
    border-radius: 4px;
    font-size: 14px;
    padding: 4px 6px;
  }
}

.testResultStatuses {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  list-style: none;
  color: $gray-600;
  border: 1px solid $gray-100;
  border-radius: 4px;
  display: flex;
  align-items: center;

  li {
    height: 100%;
    line-height: 20px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    > * {
      padding: 0px 6px 0px 2px;
    }
    &:last-child {
      margin-right: 0;
    }

    svg {
      margin: 0px 4px;
    }
  }

  li > a,
  li > span {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  :global(.status-icon) {
    margin: 0px 4px;
    vertical-align: middle;
  }

  :global(.fa-circle-o-notch) {
    font-size: 12px;
  }

  a,
  a:hover,
  a:focus,
  a:active,
  a:visited {
    color: $gray-700;
  }

  a:hover,
  a:focus,
  a:active {
    background-color: $indigo-100;
  }
}

.flakyIconV2 {
  padding-right: 8px;

  svg {
    padding: 0;
  }
}
