.labs {
  font-size: 16px;
  &__description {
    margin: 0;
  }
  &__roadmap {
    font-weight: 500;
  }
  &__roadmap-link {
    margin: 0 0 0 6px;
  }
  &__loader {
    margin: 0;
    padding: 16px 0 0 0;
  }
  &__warning {
    font-size: 14px;
  }
  .panel-body {
    border-top: 1px solid $gray-200;
  }
  .panel-footer {
    background: $gray-A1;
  }
  .empty {
    margin: 32px 0;
  }
}
