.loading-list-container {
  margin: 133px 80px 80px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);

  &.project-list-loader {
    margin-top: 1px;
  }

  &.projects-nav-loader {
    margin: 0;
    width: 300px;
    border-right: 1px solid $gray-100;
  }
}

.loader-bg {
  background-color: $gray-A1;
  width: 100%;
  border-bottom: 1px solid $gray-50;
}
