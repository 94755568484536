@import '@frontend/design-system/src/styles/_variables';

@mixin row-hover {
  outline: 2px solid transparent;
  transition: 0.3s ease;
  &:hover {
    border: 1px solid $indigo-300;
    outline: 2px solid $indigo-100;
    transition: 0.3s ease;
    z-index: 1;
  }
}

.branches-list {
  .branches-section {
    margin-top: 32px;
    .row-wrapper {
      padding: 0 17px 0 17px;
      border: 1px solid $gray-100;
      position: relative;
      margin-top: -1px;
      @include row-hover();

      &:focus-visible {
        outline: 2px solid #005fcc;
      }
    }
    .branches-section-header {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      .section-title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }
      .section-subtitle {
        color: $gray-500;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
    }

    .section-list {
      > :only-child {
        border-radius: 4px;
      }
      > :first-child:not(:only-child) {
        border-radius: 4px 4px 0 0 !important;
      }
      > :last-child:not(:only-child) {
        border-radius: 0 0 4px 4px !important;
        &.has-load-more-row {
          border-radius: 0 !important;
        }
      }
    }
  }

  #finish-project-setup-cta {
    margin-top: 40px;
  }
}

.load-more-branches-row {
  cursor: pointer;
  width: 100%;
  max-height: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $indigo-500;
  background-color: $indigo-50;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  visibility: hidden;
  border-radius: 0 0 4px 4px !important;
  .cy-status-icon {
    height: 20px;
    width: 20px;
  }
  &.visible {
    height: 48px;
    max-height: 48px;
    visibility: visible;
  }
  outline: 2px solid $gray-100;
  position: relative;
  margin-top: -1px;
  border: 1px solid $gray-100;
  @include row-hover();
}

.stale-results {
  opacity: 50%;
}
