@import '@frontend/design-system/src/styles/_variables';

$perimeter-padding: 16px;

@mixin statusStyles($color) {
  .tooltip {
    border-bottom: 4px solid $color;
  }

  .runNumberContainer {
    color: $color;
    font-weight: 600;
  }

  a.runInfo {
    &:hover,
    &:focus-visible {
      border-color: $color;
    }
  }
}

.runInfoLink {
  max-width: fit-content;
  min-width: 60px;
  overflow: hidden;
  font-size: 14px;
}

.listItem {
  display: inline-flex;
  align-items: center;
}

.tooltip {
  color: $gray-700;
  border-radius: 4px;
  min-width: 325px;
  display: flex;
  flex-direction: column;
  padding: $perimeter-padding;
  margin: -8px;

  &.tooltipDarkTheme {
    color: $gray-100;
    background-color: $gray-900;

    .runNumberContainer {
      background-color: $gray-950;
      border: 1px solid $gray-800;
    }

    .runStats {
      ul {
        color: $gray-400;
        border: 1px solid $gray-800;
      }
    }

    .commitMessage {
      span {
        color: $gray-100;
      }
    }

    .commitAuthor {
      color: $gray-300;
    }
  }

  .basicDetails {
    padding-bottom: $perimeter-padding;
  }

  .runNumberContainer {
    justify-content: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid $gray-100;
    background-color: $gray-50;
    font-size: 14px;
    font-weight: 500;
    padding: 2px 8px;
    height: 24px;
  }

  .runStats {
    padding: 0px $perimeter-padding;
  }

  .flakeCountBadge {
    height: 24px;
    background: $orange-50;
    border: 1px solid $orange-100;
    border-radius: 4px;
    color: $orange-600;
    font-size: $font-base-size;

    .flakeCount,
    .flakeLabel {
      padding: 2px 8px;
      justify-content: center;
    }

    .flakeCount {
      opacity: 0.6;
    }

    .flakeLabel {
      font-weight: 700;
      border-left: 1px solid $orange-100;
    }
  }

  .commitMessage {
    margin-bottom: 8px;

    span {
      font-size: 16px;
      font-weight: 500;
      color: $gray-900;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .commitAuthor {
    font-size: $font-base-size;
    font-weight: 400;
    gap: 10px;

    .elapsedTime {
      font-size: $font-base-size;
      font-weight: 400 !important;
    }

    .dotDivider {
      font-size: 25px;
      font-weight: 400;
      color: $gray-500;
    }
  }
}

.runInfo {
  display: flex;
  align-items: center;

  border: 1px solid $gray-100;
  border-radius: 4px;
  padding: 0 8px;
  background-color: $gray-50;
  height: 26px;
  user-select: none;
}

a.runInfo {
  cursor: pointer;

  &:focus,
  &:focus-visible,
  &:hover {
    border-color: $gray-400;
    text-decoration: none !important;
    color: inherit !important;
  }
}

.runNumberContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  color: currentColor;

  &.hasBorder {
    border: 1px solid $gray-100;
    border-radius: 4px;
    padding: 0 4px;
  }
}

.runStatusIcon {
  flex: 0 0 auto;
}

.runNumber {
  flex: 0 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.runStatusPassed {
  @include statusStyles($jade-400);
  color: $jade-400;
}

.runStatusFailed {
  @include statusStyles($red-400);
  color: $red-400;
}

.runStatusRunning {
  @include statusStyles($indigo-400);
  color: $indigo-400;

  .darkTheme {
    circle:first-of-type {
      stroke: $gray-800;
    }
  }
}

.runStatusCanceled {
  @include statusStyles($gray-400);
  color: $gray-400;
}

.runStatusOther {
  @include statusStyles($orange-400);
  color: $orange-400;
}

.divider {
  border-left: 1px solid;
  border-color: $gray-200;
  margin: 4px 8px;
  height: 16px;

  .darkTheme {
    border-color: $gray-800;
  }
}

.branchNameContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;

  color: $gray-800;
}

.branchIcon {
  flex: 0 0 auto;

  color: $gray-500;
}

.branchName {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 400px;
}

.runningIcon {
  color: $indigo-400;
}

.flagIcon {
  margin-right: 4px;
  flex: 0 0 auto;
}

.runInfo.darkTheme {
  background-color: $gray-950;
  border-color: $gray-800;

  .branchNameContainer {
    color: $gray-300;
  }

  .branchIcon {
    color: $gray-300;
  }

  .darkTheme {
    border-color: $gray-800;
  }
}

.tooltipPopper {
  &.tooltipDarkTheme {
    > div > svg {
      fill: $gray-900;
    }
  }
}

.intelligenceScores {
  gap: 4px;
  padding: 2px 8px;
  color: $gray-400;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  height: 24px;
}
