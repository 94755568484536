li.run-list-item-container {
  position: relative;
  &:hover {
    background-color: $indigo-50;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
    color: $gray-700;
  }

  .run-list-item {
    &--details {
      position: absolute;
      bottom: 0;
      width: calc(100% - 44px - 16px);
      display: flex;
      justify-content: space-between;
      margin-left: 44px;
      margin-right: 16px;
      margin-bottom: 16px;
      pointer-events: none;
    }
    &--attributes {
      flex: 1;
      .horizontal-list > span {
        pointer-events: auto;
      }
    }
    &--results {
      padding-left: 16px;
      margin-top: -26px;
      a,
      button {
        pointer-events: auto;
      }
    }
  }
}

.run-list-item {
  position: relative;
  display: grid;
  grid-template-columns: 20px 1fr auto;
  grid-template-rows: auto;
  grid-template-areas:
    'icon title results'
    'icon attributes results';
  grid-gap: 4px 8px;
  padding: 16px;

  color: inherit;

  &.with-circle-icons {
    grid-template-columns: 28px auto 1fr auto;
    grid-template-areas:
      'icon title title results'
      'icon attributes more results';
  }

  &--status {
    align-self: center;
    grid-area: icon;
  }

  &--title {
    grid-area: title;
    font-weight: 500;
    font-size: 16px;
    color: $gray-1000;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;

    div:first-child {
      margin-right: 3px;
    }
  }

  &--tag {
    display: inline-block;
    vertical-align: 2px;
    line-height: 12px;
    font-size: 10px;
    height: 16px;
  }

  &--tag-container {
    flex-wrap: wrap;
    display: flex;
    gap: 4px;
  }

  &--results {
    grid-area: results;
    align-self: center;
  }

  &--attributes {
    grid-area: attributes;
    font-size: 12px;
    color: $gray-600;
    .cy-icon {
      vertical-align: middle;
    }
    .horizontal-list {
      flex-wrap: wrap;
    }
  }

  &--more {
    grid-area: more;
    margin-top: -8px;
    margin-bottom: -4px;
    > div {
      position: absolute;
    }
  }

  @media (max-width: $screen-md) {
    grid-template-columns: 20px 1fr auto;
    .run-list-item--title {
      font-size: 15px;
    }

    .run-list-item--status {
      align-self: center;
    }

    .run-list-item--attributes {
      font-weight: 300;
    }
  }

  @media (max-width: $screen-sm) {
    grid-template-columns: 20px 1fr;
    grid-auto-rows: auto;
    grid-template-areas:
      'icon title'
      '. results'
      'icon attributes';

    .run-list-item--results {
      ul {
        justify-content: inherit;
      }
    }

    .run-list-item--attributes {
      font-weight: 400;

      .horizontal-list {
        flex-direction: column;
        align-items: baseline;
        div {
          padding: 3px 0;
        }
        &__item-divider {
          display: none;
        }
      }
    }
  }
}

a.run-list-item {
  border-bottom: 1px solid $gray-100;
  &:hover {
    text-decoration: none;
  }

  &:before {
    content: '';
    position: absolute;
    height: calc(100% + 1px);
    background-color: $gray-100;
    width: 3px;
    top: 0;
    left: 0;
  }

  &.canceled:before,
  &.errored:before,
  &.overLimit:before,
  &.timedOut:before,
  &.noTests:before {
    background-color: $orange-400;
  }

  &.failed:before {
    background-color: $red-500;
  }

  &.passed:before {
    background-color: $jade-400;
  }

  &.running:before {
    background-color: $indigo-500;
  }

  &.null:before {
    background-color: $gray-300;
  }
}
