.profile-page {
  .profile-form {
    &__name {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8px;
      margin-top: 8px;
      .form-control:last-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
}

.security-group a {
  cursor: pointer;
}

.change-password-modal {
  .current-password-group input {
    height: 38px;
  }
}
