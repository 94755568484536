.banner {
  &.grace-period-banner {
    .upgrade-section {
      white-space: nowrap;
      .btn-upgrade {
        color: $jade-600;
        margin-right: 12px;
        margin-left: 6px;
      }
    }
  }

  .banner--close {
    .app--banners & {
      margin-right: -12px;
    }
  }
}
