.test-trend-modifications {
  min-height: 14px;
  margin: 0 6px 40px 40px;
  position: relative;
  &--empty {
    margin: 0 0 18px 0;
  }
  &__icon {
    width: 14px;
    height: 14px;
    position: absolute;
  }
  &__icon-label {
    display: inline-block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    font-size: 10px;
  }
}

.test-trend-modifications-tooltip {
  &__inner-arrow {
    width: 0;
    height: 0;
    top: 1px;
    position: absolute;
    border-width: 0 5px 5px;
    margin-left: -5px;
    border-color: transparent;
    border-style: solid;
    border-bottom-color: $gray-A1;
  }
  &__inner-link {
    display: inline-block;
    position: relative;
    padding: 8px 10px;
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 15px;
      background: transparent;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
    }
  }
  &__branch {
    font-weight: 500;
    color: $gray-800;
  }
  &__build-number {
    color: $gray-500;
  }
  .rc-tooltip-arrow {
    border-bottom-color: $orange-100;
  }
  .rc-tooltip-inner {
    background: $gray-A1;
    box-shadow: none;
    border: 1px solid $orange-100;
    padding: 0;
    p {
      display: inline-block;
      margin: 0 4px 0 0;
    }
  }
  &--new {
    .rc-tooltip-arrow {
      border-bottom-color: $jade-100;
    }
    .rc-tooltip-inner {
      border-color: $jade-100;
    }
  }
}
