.kpis {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  grid-gap: 16px;
  margin-bottom: 16px;
  .kpi {
    padding: 16px;
    min-height: 136px;
    height: 100%;
    margin-bottom: 0;
    text-align: center;
    &--value {
      font-size: 40px;
      font-weight: 500;
      margin-top: 16px;
      margin-bottom: 8px;
      .loader {
        height: 40px;
        width: 80px;
      }
    }
    &--subtitle {
      display: block;
      font-size: 14px;
      font-weight: normal;
    }
    &--change {
      text-align: center;
      font-size: 20px;
      .loader {
        height: 16px;
        width: 64px;
      }
    }
    &--change-direction {
      margin-right: 4px;
      margin-top: 2px;
    }
    &--change-direction,
    &--change-percentage {
      vertical-align: top;
      display: inline-block;
      font-size: 16px;
    }
    &--name {
      color: $gray-600;
      font-weight: 500;
      i.danger {
        font-size: 12px;
        margin-right: 4px;
        color: $red-600;
      }
    }
  }
}
