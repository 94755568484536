@import '@frontend/design-system/src/styles/_variables';

.red {
  @apply text-red-500;
}

.jade {
  @apply text-jade-500;
}

.gray {
  @apply text-gray-700;
}

.purple {
  @apply text-purple-500;
}

.indigo {
  @apply text-indigo-500;
}

.cloudStatusChip {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $gray-100;
  padding: 8px 4px 8px 3px;
  height: 20px;
  gap: 4px;
  font-size: $font-base-size;
  font-weight: 500;
}
