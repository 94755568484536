.pitch {
  background-color: $gray-50;
  display: flex;
  width: 100%;
  border: 1px solid $indigo-300;
  color: $gray-600;
  flex-direction: column;
  text-align: center;
  border-radius: 5px;
  padding: 25px 25px 25px;
  margin-bottom: 20px;

  &.pitch-warning {
    border: 1px solid $orange-300;
    box-shadow: 0 0 6px 0 rgba(198, 178, 148, 0.461);

    p {
      strong {
        font-weight: 500;
        border-bottom: 1px solid $orange-300;
      }
      em {
        font-weight: 500;
        font-style: normal;
      }
    }

    i {
      margin-right: 5px;
    }
  }

  .close {
    top: -10px;
    right: -10px;
  }

  h4 {
    color: $gray-600;
    border-bottom: 1px solid $gray-100;
    margin-bottom: 15px;
    padding-bottom: 10px;

    i {
      margin-right: 5px;
      color: $indigo-600;
    }
  }

  p {
    color: $gray-600;
    font-size: 1.1em;

    strong {
      font-weight: 500;
      border-bottom: 1px solid $indigo-500;
      margin: 0 5px;
    }
  }
}

.alert-banner {
  display: flex;
  justify-content: space-between;

  .close {
    position: initial;
  }
}
