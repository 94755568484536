@import '@frontend/design-system/src/styles/_variables';

.message-setting-filter {
  button.filter.dropdown-toggle {
    box-shadow: none;
  }

  .dropdown-menu {
    min-width: 280px;
  }

  .select__menu-list {
    display: flex;
    flex-direction: column;
    padding: 12px 8px;
    gap: 12px;
    max-height: unset;

    .group-header {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $gray-700;
    }

    .group-content {
      padding-top: 4px;
      display: flex;
      flex-direction: column;

      label,
      .group-item {
        display: flex;
        white-space: nowrap;
        align-items: center;
        margin: 0;
        padding: 0 8px;
        gap: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $gray-700;
      }

      .group-item {
        justify-content: space-between;
        height: 32px;

        // Needed because some icons in the design system don't have size 16 available.
        svg {
          width: 16px;
          height: 16px;
        }
      }

      label {
        input[type='radio'] {
          width: 16px;
          height: 16px;
          margin: 0;
          padding: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
