.summary-row {
  color: $gray-A1;
  margin: 0;
  display: flex;
  padding: 10px 0;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .summary-icon {
    display: flex;
    flex: 0 0 30px;
    align-items: center;
    text-align: center;
    padding: 0 7px;
  }

  p {
    margin: 0;
    flex: 1 0;
    align-items: stretch;
    padding: 0 15px;
    font-size: 0.95em;

    strong {
      margin: 0 3px;
      border-bottom: 1px dashed $gray-A1;
      cursor: pointer;
    }
  }

  .summary-btns {
    display: flex;
    margin-right: 15px;

    .btn {
      margin-left: 7px;
      font-size: 0.95em;

      i {
        margin-left: 3px;
      }
    }
  }
}
