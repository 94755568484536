.side-nav-container {
  position: relative;
  grid-area: sidebar;

  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 64px auto 1fr;
  grid-auto-columns: 100%;
  height: 100vh;

  overflow: hidden;

  color: $gray-50;
  background-color: $gray-1000;
  box-shadow: inset -10px 0px 10px -4px rgba(0, 0, 0, 0.5);

  .organization-switcher-container {
    border-bottom: 1px solid rgba($gray-900, 80%);
  }

  .nav-switcher {
    // Allows the CSS Grid container to collapse below the size of the content
    min-height: 0;

    // Stacks the org and project navs side-by-side
    display: flex;
    width: 200%;

    // Allows sliding the project nav in and out
    transition: transform 200ms ease-in-out;
    > * {
      transition: visibility 200ms linear;
    }

    > *:nth-child(1) {
      visibility: visible;
    }
    > *:nth-child(2) {
      visibility: hidden;
    }
    &.project {
      transform: translateX(-50%);
      > *:nth-child(1) {
        visibility: hidden;
      }
      > *:nth-child(2) {
        visibility: visible;
      }
    }

    // Disable transitions entirely in Cypress
    .is-cypress & {
      transition: none !important;
    }
  }

  .nav--project-container {
    margin: 0 16px 0 20px;
  }

  .skip-nav-link {
    position: absolute;
    top: auto;
    left: -999px;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;

    &:focus,
    &:active {
      color: $gray-A1;
      background-color: $gray-900;
      left: auto;
      width: auto;
      height: auto;
      overflow: auto;
      z-index: 999;
    }
  }

  .nav-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin: 8px 0;
      &.nav-item-bottom-border {
        border-bottom: 1px solid $gray-900;
        padding-bottom: 8px !important;
      }
    }

    .nav-list {
      margin: 6px 0 0 32px;
      padding: 2px 0;
      border-left: 1px solid $gray-900;
      li {
        margin: 0;
      }
    }
  }

  .nav-link {
    color: $gray-400;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $gray-300;
      text-decoration: none;
    }
  }

  .nav-item {
    display: block;
    font-size: 16px;
    color: $gray-400;
    padding: 8px 16px 8px 24px;
    text-shadow: 0 1px 0px rgba(0, 0, 0, 0.12);
    position: relative;
    cursor: pointer;
    font-weight: 500;
    transition: color 150ms ease, background-color 150ms ease;
    text-decoration: none;

    // Nav-Item: Border
    &-analytics.active::after {
      display: none;
    }
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;
      background-color: $gray-300;
      border-radius: 0 4px 4px 0;
      transition: background-color 150ms ease, opacity 150ms ease;
      opacity: 0;
    }

    &:hover {
      color: $gray-200;
      &::after {
        opacity: 1;
      }
    }

    &.active {
      color: $indigo-300;
      &::after {
        background-color: $indigo-400;
        opacity: 1;
      }
    }

    // Nav-Item: Icon
    .fa,
    .cy-icon {
      width: 16px;
      height: 16px;
      margin: 0 16px 0 0 !important;
      text-align: center;
    }

    &.sub-nav-item {
      font-size: 14px;
      font-weight: 500;
      padding: 0 8px 0 16px;
      height: 32px;
      transition: color 150ms ease, background-color 150ms ease;
      &::after {
        height: 28px;
        top: 2px;
        bottom: auto;
        left: -2.5px;
        border-radius: 4px;
      }

      div {
        display: inline-block;
        max-width: 100%;
        padding: 0 8px;
        border-radius: 4px;
        line-height: 32px;
      }

      &:hover,
      &:focus {
        &::after {
          opacity: 1;
        }
      }
    }

    &.nav-addon {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .nav-item--beta-tag {
      font-size: 0.8em;
      color: $orange-500;
      vertical-align: super;
      padding-left: 3px;
    }
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }
}

.nav-container {
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-rows: min-content;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.nav--project-name {
  font-size: 16px;
  display: grid;
  grid-template-columns: 24px auto;
  grid-gap: 12px;
  font-weight: 600;
  color: #fff;
}

.nav-switcher.showing-onboarding .nav--project-name {
  margin-top: 16px;
}

.nav-switcher.no-onboarding .nav--project-name {
  margin-top: 16px;
}

.nav--back-link a {
  color: $gray-500 !important;
  margin-left: 36px;
  transition: color 150ms ease;
  &:hover {
    color: $indigo-300 !important;
  }
}

.nav--org-onboarding {
  border-bottom: 1px solid $gray-900;
  padding-top: 8px;
  padding-bottom: 8px;
}
