@import '@frontend/design-system/src/styles/_variables.scss';

.search-input-container {
  transition: all;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  padding: 0px 14px;
  border-radius: 4px;
  border: 1px solid $gray-100;
  background-color: $gray-50;
  height: 32px;

  input {
    all: unset;
    width: 100%;
    height: 100%;
    color: $gray-700;
  }
}

.icon-container {
  height: 16px;
  width: 16px;

  svg {
    fill: $gray-50;
    path {
      stroke: $gray-500 !important;
    }
  }

  button {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 16px;
    width: 16px;
    &:active {
      svg {
        path {
          stroke: $gray-100 !important;
        }
      }
    }
  }
}
