.container {
  display: flex;
  align-items: center;
}

.durationText {
  // Uses current font-size as line height to ensure
  // proper alignment with the icon
  line-height: 1;
  padding-left: 3px;
}
