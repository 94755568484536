#login .auth0-lock.auth0-lock {
  .auth0-lock-header {
    display: none;
  }

  .auth0-lock-content {
    padding: 20px 20px 40px 20px;
  }

  .auth0-lock-form {
    .auth0-lock-pane-separator + div > p,
    p > a {
      cursor: pointer;
      color: $indigo-500;

      &:hover {
        color: $gray-800;
      }
    }

    .auth0-lock-pane-separator + div > p {
      margin-bottom: 5px;
    }

    div.auth0-lock-pane-separator {
      padding-top: 5px;
    }

    .auth0-lock-alternative {
      margin-top: 10px;
    }
  }

  .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
    padding-left: 0;
  }

  .auth0-lock-badge-bottom {
    opacity: 0 !important;
    display: none !important;
  }

  .auth0-lock-social-button.auth0-lock-social-big-button
    .auth0-lock-social-button-text {
    text-transform: none;
    font-size: 14px;
  }

  .auth0-lock-tabs-container {
    position: absolute;
    bottom: 0;
    left: 43%;
  }

  .auth0-lock-tabs {
    border: 0;
    border-radius: 0;

    .auth0-lock-tabs-current {
      display: none;
    }

    li {
      width: auto;

      a {
        padding: 0;
        color: $indigo-500;
        text-transform: none;

        &:hover {
          color: $gray-800;
        }
      }
    }
  }
}
