.latest-test-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-200;
  }

  > i {
    flex: 0 0 auto;
  }

  > :nth-child(2) {
    flex: 1 1 auto;
    min-width: 0;
    overflow: hidden;
  }

  > :nth-child(3) {
    flex: 0 0 auto;
  }

  &__commit-summary {
    color: $gray-800;
    font-size: 14px;
    padding-bottom: 4px;
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .horizontal-list {
    flex-wrap: wrap;
    color: $gray-500;
    font-size: 12px;

    .commit-sha {
      display: flex;
      align-items: center;
    }
  }
}
