@media (min-width: 0) and (max-width: 1200px) {
  .details-panel {
    flex-direction: column !important;

    .spec-actions,
    .run-duration,
    .all-details,
    .all-envs {
      flex: 0 !important;
      border-left: 0 !important;
    }

    .all-details,
    .all-envs {
      border-bottom: 1px solid $gray-50;
    }

    .run-duration > span {
      display: flex;

      i {
        top: 2px !important;
      }
    }

    .envs {
      border: 0 !important;
    }

    .all-envs {
      justify-content: space-around;
    }

    .spec-info {
      padding: 15px 15px 10px 15px;
    }

    .envs {
      width: 50%;
      float: left;
    }

    .spec-actions {
      padding: 12px;
      justify-content: space-around !important;

      & > .btn {
        width: 33% !important;
      }
    }
  }
}

@media (max-width: 1200px) {
  #run-show {
    .instance-title,
    .instance-left-title,
    .env-data {
      width: 100% !important;
    }

    .instance-title,
    .instance-left-title {
      display: block !important;
    }

    .env-data {
      border-top: 1px solid $gray-200;
      & > div {
        text-align: center;
      }

      & > .env-browser {
        width: 55% !important;
        text-align: left;
      }

      & > .run-duration {
        width: 25% !important;
      }

      & > div:last-child {
        width: 20% !important;
      }

      & > .btn {
        margin-left: 10px !important;
        width: 15% !important;
      }

      .env-browser span {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 900px) {
  .login-content,
  .signup-content {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: stretch;
    flex-direction: column;
  }

  .login-left,
  .signup-left {
    display: flex;
    overflow-y: auto;
    position: relative;

    .login-img-wrapper,
    .signup-img-wrapper {
      display: flex !important;
    }
  }

  .login-right,
  .signup-right {
    display: none !important;
  }

  .nav-tabs > li {
    & > a,
    & > button {
      padding: 10px;
    }
  }

  #run-show .panel-heading-title,
  .instance-title .instance-left-title,
  .env-data,
  .single-spec,
  .specs-list > .empty {
    width: 100% !important;
  }

  .video-control-bar,
  .instance-title,
  .spec-header-bar {
    display: block !important;
  }

  #run-show .panel-body .list-group .list-group-item > a {
    padding: 0 15px 10px !important;
  }

  #run-show {
    .env-data {
      border: 0;
    }

    .env-data > div {
      display: none;
    }
  }
}

@media (max-width: 700px) {
  .organization-container {
    padding-left: 0 !important;
  }

  #run-show {
    .env-data > div {
      display: none;
    }
  }

  #run-show .panel-heading-title,
  .instance-title .instance-left-title,
  .env-data,
  .single-spec,
  .specs-list > .empty {
    width: 100% !important;
  }

  .single-spec,
  .specs-list > .empty {
    margin-right: 0 !important;
  }

  .video-control-bar,
  .instance-title,
  .spec-header-bar {
    display: block !important;
  }

  #project-settings {
    .col-sm-2 .btn-primary {
      margin-top: 10px;
    }
  }

  #run-show .nav-tabs {
    & > li {
      & > a,
      & > button {
        margin-right: 3px !important;
        padding: 10px !important;
      }
    }
  }

  .empty,
  .video-control-bar,
  .embed-responsive-16by9 {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 400px) {
  #run-show .nav-tabs > li {
    & > a,
    & > button {
      font-size: 12px;
    }
  }

  #runs,
  #run-show > .empty {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #run-show .tab-pane,
  #project-settings,
  .run-tabs-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
