.notification-integrations-table {
  border-bottom: none;
  border-top: none !important;

  .notification-setting-row {
    height: 55px;
  }

  th,
  td {
    padding: 0 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    width: 50%;

    &:first-child {
      width: unset;
    }
  }

  .project-notification-integrations & {
    th,
    td {
      width: 25%;

      &:first-child {
        width: unset;
      }
    }
  }
}

.column-heading {
  font-size: 10px;
  color: $gray-600;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

.notification-integrations-table {
  padding: 0 16px;
}
