@import '@frontend/design-system/src/styles/_variables';

.dot-divider {
  line-height: 0;
  font-size: 25px;
  font-weight: 400;
  color: $gray-500;
}

.run-duration-tooltip {
  .rc-tooltip-inner {
    background-color: $gray-A1;
  }

  .rc-tooltip-arrow {
    border-bottom-color: $gray-A1;
    filter: drop-shadow(0px -1px 1px $gray-400);
  }

  .run-duration-overlay {
    $perimeter-padding: 16px;
    $left-column-width: 50%;

    color: $gray-700;
    border-radius: 4px;
    border-bottom: 4px solid transparent;
    min-width: 325px;

    p {
      margin: 0;
      font-size: $font-base-size;
      font-weight: 400;
      display: inline-block;
    }

    .count-delta-chip {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.2;
      padding: 0px 4px;
      border-radius: 4px;
      border: 1px solid $gray-100;
      color: $purple-500;
    }

    .elapsed-time {
      font-size: 14px;
      font-weight: 400 !important;
    }

    .run-build-number {
      all: unset;
      box-sizing: border-box;
    }

    .run-build-number.past-run {
      cursor: pointer;
    }

    &.run-status-passed {
      border-bottom-color: $jade-400;

      .run-build-number {
        color: $jade-400;
      }

      .run-build-number.past-run:hover {
        border-color: $jade-400;
      }
    }

    &.run-status-failed {
      border-bottom-color: $red-400;

      .run-build-number {
        color: $red-400;
      }

      .run-build-number.past-run:hover {
        border-color: $red-400;
      }
    }

    &.run-status-running {
      border-bottom-color: $indigo-400;

      .run-build-number {
        color: $indigo-400;
      }

      .run-build-number.past-run:hover {
        border-color: $indigo-400;
      }
    }

    &.run-status-canceled {
      border-bottom-color: $gray-400;

      .run-build-number {
        color: $gray-400;
      }

      .run-build-number.past-run:hover {
        border-color: $gray-400;
      }
    }

    &.run-status-other {
      border-bottom-color: $orange-400;

      .run-build-number {
        color: $orange-400;
      }

      .run-build-number.past-run:hover {
        border-color: $orange-400;
      }
    }

    .run-build-number {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 4px;
      border: 1px solid $gray-100;
      background-color: $gray-50;
      font-size: 14px;
      font-weight: 500;
      padding: 2px 8px;
      height: 24px;
    }

    .basic-details {
      display: flex;
      flex-direction: column;
      padding: 0px $perimeter-padding;

      .details-row {
        gap: 10px;
        display: inline-flex;
        align-items: center;

        &.details-status-row {
          margin: $perimeter-padding 0px;
        }

        &.details-message-row {
          margin-bottom: 8px;
        }

        &.details-committer-row {
          margin-bottom: 12px;
          font-size: 14px;
          img {
            margin: 0;
          }
        }
      }

      .run-commit-message {
        font-size: 16px;
        font-weight: 400;
        color: $gray-900;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .flake-count-badge {
        height: 24px;
        display: flex;
        background: $orange-50;
        border: 1px solid $orange-100;
        border-radius: 4px;
        color: $orange-600;
        font-size: $font-base-size;

        .flake-count,
        .flake-label {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px 8px;
        }

        .flake-count {
          opacity: 0.6;
        }

        .flake-label {
          line-height: 0;
          font-weight: 700;
          border-left: 1px solid $orange-100;
        }
      }
    }

    .counts-section {
      border-top: 1px solid $gray-100;
      border-bottom: 1px solid $gray-100;
      display: flex;
      align-items: center;
      padding: 12px $perimeter-padding;

      .tests-count,
      .specs-count {
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
          width: 17px;
          color: $gray-500;
          margin-top: -2px;
        }
      }

      .specs-count {
        width: $left-column-width;
      }
    }

    .configurations {
      padding: 12px $perimeter-padding;

      .config-section {
        display: flex;
        flex-direction: column;
        gap: 12px;

        h4 {
          margin: 0;
          all: unset;
          font-size: $font-base-size;
          font-weight: 600;
          color: $gray-700;
        }

        .config-row {
          display: flex;
          align-items: center;
          height: 20px;

          .feat-label {
            display: flex;
            width: $left-column-width;
            align-items: center;

            svg {
              display: inline-block;
              margin-right: 5px;
            }
          }
        }

        .parallelization-row {
          display: flex;
          flex-direction: column;

          .group-row {
            display: flex;
            align-items: center;
          }

          .group-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: $left-column-width;
          }
        }

        .parallelization-body {
          margin-top: 10px;
        }

        .smart-orch-loading {
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
        }

        .hidden-groups-count {
          padding-left: 22px;
          margin: 10px 0;

          .count-delta-chip {
            margin-right: 4px;
          }
        }

        .config-group {
          padding-left: 8px;

          .group-border {
            border-left: 1px dotted $gray-300;
            padding: 4px 0 4px 16px;
          }
        }
      }
    }
  }
}
