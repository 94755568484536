.scrollable-menu-container {
  display: grid;
  position: relative;

  &__items {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    scrollbar-width: none;
    padding: 0px 4px;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent;
    }
    > div {
      display: inline-block;
    }
    > div:not(:last-child) {
      margin-right: 8px;
    }
    .btn-group {
      button {
        &:hover,
        &:focus,
        &:active {
          z-index: auto;
        }
      }
    }
    &.child-open {
      // NOTE: terrible hack
      // allow filters to be visibile even though this element has overflow-x: scroll
      // https://stackoverflow.com/a/39554003
      padding-bottom: 440px;
      margin-bottom: -440px;
    }
  }

  &__button-left,
  &__button-right {
    position: absolute;
    top: 4px;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    padding: 4px 2px !important;
    color: $indigo-600;
    background-color: $gray-A1;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background-color: $gray-50;
      color: $indigo-600;
      outline: 0;
    }
  }
  &__button-left {
    left: 4px;
  }
  &__button-right {
    right: 4px;
  }
}
