#billing {
  h3 {
    margin-bottom: 0;
  }
}

#billing {
  .panel {
    margin-bottom: 24px;
  }
  .plans-wrapper {
    min-width: 800px;

    &__header {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      h2 {
        margin: 8px 0px 16px;
      }
    }

    .legacy {
      .label {
        position: relative;
        top: -2px;
        margin-right: 10px;
      }
    }
  }

  .custom-message {
    display: flex;
    flex-direction: row;

    .contact-freeform {
      width: 65%;
      margin-right: 5%;

      .alert {
        padding: 40px;
      }

      h4 {
        margin: 30px 0;
      }
    }
  }
}
