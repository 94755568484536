.screenshot-wrapper {
  -webkit-box-sizing: border-box;
  padding: 10px;
  background-color: $gray-50;
  border: 1px solid $gray-100;
  margin-bottom: 25px;
  border-radius: 5px;

  &:nth-of-type(even) {
    margin-right: 0;
  }
}

.screenshot-test-title {
  color: $gray-700;
  font-size: 14px;
  margin-bottom: 2px;
  font-weight: 400;

  .red {
    color: $red-500;
  }

  .fa-chevron-right {
    margin: 0 5px;
    color: $gray-500;
    font-size: 11px;
  }
}

.screenshot-name {
  font-family: $font-mono;
  color: $gray-400;
  font-size: 13px;
  margin-bottom: 5px;
}

.screenshot-test-title + .screenshot-name {
  margin-left: 23px;
}

.screenshot {
  border: 1px solid $gray-200;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.screenshots {
  display: flex;
  &__thumbnail {
    margin: 0 16px 0 0;
    button {
      position: relative;
      border: none;
      margin: 0;
      padding: 0;
      &:focus {
        outline: none;
      }
    }
    img {
      width: 100%;
    }
    &:last-child {
      margin: 0;
    }
    &--overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($gray-100, 0.9);
      p {
        margin: 0;
      }
    }
  }
}
