@import '@frontend/design-system/src/styles/_variables';

.container {
  border: 1px solid $gray-100;
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
  ul {
    display: inline-flex;
    list-style-type: none;
    :not(:last-child) {
      margin-right: 15px;
    }
  }
}

.runStat {
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    margin-right: 4px;
  }
}
