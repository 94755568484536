.project-container {
  height: 100%;

  > div {
    height: 100%;

    > div {
      height: 100%;
    }
  }
}
