@import '@frontend/design-system/src/styles/_variables';

.container {
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  .labelWrapper {
    display: inline-flex;
    align-items: center;
    > span {
      font-size: 16px;
      font-weight: 500;
      margin-right: 8px;
    }
    > div {
      margin-left: 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      padding: 2px 4px;
      border-radius: 4px;
      background-color: $indigo-100;
      span {
        font-size: 12px;
        font-weight: 500;
      }
      &.alert {
        color: $red-600;
        background-color: $red-100;
      }
      &.default {
        color: $indigo-600;
      }
    }
  }
  .icon {
    color: $indigo-300;
    flex-shrink: 0;
  }
  .progressBar {
    height: 6px;
    width: 50px;
    margin: 0;
    > div {
      background-color: $indigo-400;
    }
  }
}

.progressWrapper {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: white;
  span {
    margin-left: 6px;
  }
}
