.block-panel {
  footer.pagination {
    display: flex;
    height: auto;
    justify-content: space-between;
    position: static;
    margin-bottom: 40px;
    margin-top: 20px;

    a {
      display: none;
    }
  }

  &.has-newer .newer-runs,
  &.has-older .older-runs {
    display: block;

    &.btn:active {
      box-shadow: inset 0 0 0 $gray-A2;
    }
  }
}

.newer-runs,
.older-runs {
  z-index: 2;
}

.older-runs {
  margin: 0 0 0 auto;
}

#runs {
  margin-left: 0;
  margin-right: 0;
  padding: 24px 24px 40px;

  // Adjust bottom padding for pendo badge.
  .pendo-active & {
    padding-bottom: 92px;
  }

  .panel {
    margin-bottom: 0;
  }
}

#runs-list-page {
  .empty {
    clear: both;
    margin-top: 0;
  }

  .last-updated-widget {
    .last-updated {
      color: $gray-600;
      font-size: 12px;
      font-weight: 500;
    }

    button {
      vertical-align: initial;
      cursor: pointer;
      padding: 0 8px;

      &[disabled]:hover,
      &[disabled]:focus {
        color: $gray-500;
      }
    }
  }

  footer.pagination {
    margin-bottom: 0;
  }
}

.run-filters {
  i.fa-spin {
    animation: none !important;
  }

  .status-dropdown {
    .dropdown-menu li {
      cursor: pointer;

      i {
        margin-right: 5px;
      }

      &:hover {
        background-color: $gray-100;
      }
    }
  }

  .btn.btn-light {
    .dropdown-selection {
      color: $gray-600;

      i {
        font-size: 0.9em;
        margin-right: 3px;
      }
    }

    .dropdown-label {
      color: $gray-400;
      font-weight: normal;
    }

    .caret {
      margin-left: 5px;
    }
  }
}
