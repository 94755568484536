@import '@frontend/design-system/src/styles/_variables';

#finish-project-setup-cta {
  margin: auto;
  width: 100%;
  justify-content: center;
  position: relative; // allows it to be selected despite overlap w/ .scrollable-menu-container
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: $gray-900;
  p {
    color: $gray-700;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.finish-project-setup-ci-buttons-wrapper {
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  width: 600px;
}

.finish-project-setup-modal {
  .modal-title {
    font-size: 18px;
    line-height: 28px;
  }

  .modal-dialog {
    width: 90%;
    height: 90%;
    max-width: 800px;
  }

  .modal-body {
    padding: 0;
  }

  #progressive-project-setup {
    padding-bottom: 1px;
  }
  #promote-ci-run-recordings {
    padding: 24px;
    padding-top: 0;
    max-width: unset; // modal already has max width
    header {
      display: none;
    }

    h2 {
      margin-top: 32px;
    }

    #step-1 {
      h2 {
        margin-top: 24px;
      }
    }
  }
}
