@keyframes toast-enter {
  0% {
    opacity: 0;
    transform: translateX(150%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.notification-bar {
  .notification-bar-wrapper {
    align-items: center;
    display: flex;
  }

  &.notification-bar-active {
    animation: toast-enter 300ms ease-in-out;
  }

  &.failure {
    padding-left: 24px !important;

    &:before {
      content: ' ';
      z-index: -1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 8px;
      background-image: repeating-linear-gradient(
        45deg,
        $red-800,
        $red-800 8px,
        $red-700 8px,
        $red-700 16px
      );
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }

  .notification-bar-action:hover {
    color: $gray-A1 !important;
  }

  .notification-bar-message {
    align-items: center;
    display: flex;
    margin-right: 16px;

    .notification-message {
      line-height: 1;
      margin-left: 8px;
    }
  }

  .notification-bar-action {
    svg {
      vertical-align: middle;
    }
  }
}
