.test-type-icon {
  margin-right: 8px;
}

.test-type-banner-load-icon {
  color: $indigo-400;
}

.test-type-status-loading {
  color: $indigo-400;
  margin: 0 5px 0 2px;
}

.test-type-filter-label {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;

  .test-type-filter-count {
    color: $gray-500;
    padding-left: 10px;
  }
}

// potentially reusable
.filter-empty-state-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  font-size: 14px;
  font-weight: 400;
  padding: 30px;

  .icon-container {
    color: $gray-700;
  }
  .empty-state-title {
    font-weight: 700;
    color: $gray-800;
  }
  .empty-state-description {
    color: $gray-600;
  }
  .empty-state-link {
    margin-top: 15px;
    color: $indigo-500;
    a {
      text-decoration: none;
      cursor: pointer;

      // something is inserting
      // an unwanted bottom border:
      &:after {
        content: none !important;
      }
    }
  }
}

// TestType filter is the right-most in the list. The menu boxes are clipped by
// the browser. This ensures the dropdown menu box is flush with the right-hand
// side of the dropdown button, instead of the left side. The aria attributes are
// generated by `react-select` based on the `id` attribute on <TestTypeFilter/>
// This didn't work: https://github.com/JedWatson/react-select/issues/3491

// Only enforces offset when the
// banners would fall off-screen
@media (max-width: 1830px) {
  .offset-test-type-banner-e2e {
    ul[aria-labelledby='filter-test-result-type-filter'] {
      left: -232px;
    }
  }
  .offset-test-type-banner-comp {
    ul[aria-labelledby='filter-test-result-type-filter'] {
      left: -187px;
    }
  }
}

@media (max-width: 1700px) {
  .offset-test-type-options-none {
    ul[aria-labelledby='filter-test-result-type-filter'] {
      left: -26px;
    }
  }
  .offset-test-type-options-e2e {
    ul[aria-labelledby='filter-test-result-type-filter'] {
      left: -57px;
    }
  }
  .offset-test-type-options-e2e-plus {
    ul[aria-labelledby='filter-test-result-type-filter'] {
      left: -38px;
    }
  }
  .offset-test-type-options-comp {
    ul[aria-labelledby='filter-test-result-type-filter'] {
      left: -8px;
    }
  }
  .offset-test-type-options-comp-plus {
    ul[aria-labelledby='filter-test-result-type-filter'] {
      left: 11px;
    }
  }
}
