$filter-padding: 8px;

.unstyled-filter {
  box-shadow: none;
  padding: 0;
  height: auto;
  &:hover,
  &:focus {
    background: $gray-A1;
    box-shadow: none;
  }
}

.unstyled-filter--open {
  background: $gray-A1;
  border-color: transparent;
}

.noshadow-filter {
  box-shadow: none;
  &:hover,
  &:focus {
    box-shadow: none;
  }
}

.filter {
  &__icon {
    color: $gray-500;
    vertical-align: baseline;
    height: 20px;
    margin-right: 4px;
    img,
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &__dropdown-caret {
    margin-left: 4px;
    width: 10px;
    height: 10px;
    path {
      stroke: $indigo-600;
    }
  }
  &__selected-label {
    color: $indigo-600;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
  }
  &__checkbox {
    margin: 0 4px 0 0;
  }
}

.filter.dropdown-toggle {
  height: 40px;
  display: flex;
  align-items: center;
  padding: $filter-padding 8px;
  background: #ffffff;
  border-color: $gray-100;
  font-weight: normal;
  color: $gray-900;

  &:hover,
  &:focus,
  &:active {
    background: $gray-50;
    border-color: $gray-100;
    color: $gray-900;
  }

  .open & {
    background: $gray-50;
    border-color: $gray-300;
    color: $gray-900;

    &:hover,
    &:focus,
    &:active {
      background: $gray-50;
      border-color: $gray-300;
      color: $gray-900;
    }
  }

  & + .dropdown-menu {
    padding: 0;
    li {
      &:last-child {
        a {
          &:after {
            width: 0;
          }
        }
      }
    }
    a {
      padding: $filter-padding;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        width: calc(100% - #{$filter-padding * 2});
        transform: translate(-50%);
        border-bottom: 1px solid #dadade;
        z-index: 1;
      }
    }
  }

  .caret {
    position: relative;
    margin: 0 0 0 4px;
    color: $indigo-600;
    border-radius: 4px;
  }
}

.multi-select-filter--select {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  .select__indicators {
    display: none;
  }
  .multi-select-filter--buttons {
    padding: 0 4px 8px;
    .button {
      border-width: 0;
      padding: 0 4px;
    }
  }
  .select__placeholder {
    color: $gray-300;
  }
  .select__control {
    border: none;
    min-height: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .select__control--is-focused {
    box-shadow: none;
  }
}

.filters-container {
  // Override Bootstrap defaults to avoid inset shadows
  .btn-group.open .dropdown-toggle {
    box-shadow: none;
  }

  // Override Bootstrap button:active styles
  .btn {
    &:active,
    &.active {
      box-shadow: none;
    }
  }
}

.multi-select-filter--select--disabled {
  .select__control {
    display: none;
  }
}

.multi-select-filter--custom-footer {
  .select__menu,
  .select__option:last-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.multi-select-filter {
  .select__value-container {
    cursor: text;
    padding: 4px 8px;
  }
  .dropdown-menu {
    border-bottom-width: 0;
    border: none;
  }
  .select__menu {
    position: relative;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .select__menu-list {
    padding: 0;
    .select__option {
      display: flex;
      align-items: center;
      white-space: nowrap;
      cursor: pointer;
      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.rdrDefinedRangesWrapper {
  max-width: 170px;
}

.multi-select-filter-with-categories {
  .select__option {
    display: flex;
    justify-content: space-between;
    mark {
      padding: 0;
    }
    .fa-check-square {
      color: $indigo-600;
    }
    .fa-square-o {
      color: $gray-300;
    }
  }
  .select__option--is-selected {
    color: currentColor;
  }
  .h6 {
    cursor: default;
    margin: 0;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: $gray-50;
    padding: 4px 8px;
    border-bottom: 1px solid $gray-200;
    &.unselected {
      border-top: 1px solid $gray-200;
      padding: 8px;
    }
    .btn-link {
      font-size: 12px;
      font-weight: 500;
      padding: 0px;
      text-decoration: none;
    }
  }
}
