.record-keys-table.table {
  border-top: 1px solid $gray-200;
  & > tbody {
    & > tr > td {
      line-height: 32px;
      padding: 8px 0;
      font-size: 13px;
      border-bottom: 0;
    }
  }

  .record-key-date {
    color: $gray-500;
  }

  .key-delete {
    cursor: pointer;
  }

  .record-key {
    font-family: $font-mono;

    .record-key-wrap {
      position: relative;
    }

    // we need this for copy-to-clipboard, don't want to show it, because it can't
    // wrap lines like the .record-key-value span. it can't be display: none or
    // visibility: hidden or it will be un-selectable for copy-to-clipboard
    // so we make it small and hide it behind .record-key-value
    .record-key-copy-backer {
      background-color: transparent;
      border: none;
      font-size: 6px;
      position: absolute;
      top: 2px;
      left: 2px;
      width: 10px;
    }

    .record-key-value {
      background: $gray-A1;
      padding-left: 5px;
      position: relative;
    }

    .input-group-addon {
      cursor: pointer;
      border: 1px solid $gray-200;
      border-radius: 5px;

      &:hover {
        color: $gray-800;
      }
    }
  }
}
