.projects-list-controls {
  display: flex;
  margin-bottom: 2em;
  height: 40px;
  .btn-primary {
    background-color: $indigo-500;
    transition: 0.1s ease;
    &:hover {
      background-color: $indigo-500;
      box-shadow: 0px 0px 5px $indigo-300;
      transition: 0.1s ease;
    }
  }
  .add-project-icon {
    color: $indigo-300;
    margin-right: 0.5em;
  }
  > div {
    margin-right: 16px;
  }
}

.projects-list-v2 {
  .projects-list-v2-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(450px, 100%), 1fr));
    grid-gap: 24px;

    .project-item-v2 {
      box-shadow: none;
      border: 1px solid $gray-100;
      margin-bottom: 0;
      transition: 0.3s ease;

      &:hover {
        transition: 0.3s ease;
        border: 1px solid $indigo-300;
        box-shadow: 0 0 0 2px $indigo-100;
      }

      .heading-container {
        text-decoration: none;
      }

      .project-heading {
        min-height: 72px;
        padding: 14px;
        background-color: white;

        .title-container {
          display: flex;
          align-items: center;
        }

        .title-text {
          color: $indigo-500;
          margin-right: 8px;
          line-height: 28px;
          font-size: 18px;
        }

        .sub-title {
          color: $gray-600;
          font-size: 14px;
          font-weight: 400;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          // NOTE: re-centers the Avatars component content
          // and removes excess margins from Avatars component.
          .project-contributors {
            margin-top: -3px;
            margin-right: 6px;
            * {
              margin-bottom: 0 !important;
            }
          }

          .sub-title-highlight {
            color: $gray-700;
            font-weight: 500;
          }
        }

        .settings-project-public {
          font-size: 12px;
          line-height: 16px;
          font-weight: 700;
          text-align: center;
          color: $indigo-600;
          background-color: $indigo-100;
          padding: 0px 4px;
        }
      }

      // When displaying equi-distant rows vertically,
      // we need to set top/bottom padding to 0.
      .project-body-row-spacing {
        padding: 0 16px;
      }

      .project-body {
        height: 158px;
        border-radius: 0px 0px 3px 3px;
        padding: 0;
        background-color: rgba($indigo-100, 0.3);

        // NOTE: overrides default Panel.Body border.
        // required for styling migration to cloud 2.0
        border-top: 1px solid $gray-100 !important;

        .no-runs-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding: 0 5%;

          .code-sample {
            pre {
              overflow: hidden;
              white-space: nowrap;
            }
          }

          .cta-title {
            font-size: 16px;
            color: $gray-800;
            font-weight: 400;
            line-height: 20px;
          }

          .cta-description {
            font-size: 14px;
            color: $gray-600;
            font-weight: 400;
          }

          .setup-recording-button {
            margin-top: 16px;

            .setup-recording-button-icon {
              margin-right: 8px;
            }
          }
        }

        .runs-content {
          height: 100%;
          display: flex;
          flex-direction: column;

          .branch-row-container {
            display: flex;
            align-items: center;
            height: 100%;
            text-decoration: none;

            &:not(:last-child) {
              .branch-row-content {
                border-bottom: 1px solid $gray-100;
              }
            }

            &:last-child {
              border-radius: 0px 0px 3px 3px;
            }

            .branch-row-content {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-grow: 1;
              margin: 0 17px;
              height: 100%;
              min-width: 0;
            }
            transition: 0.3s ease;
            &:hover {
              transition: 0.3s ease;
              background-color: rgba($indigo-100, 0.4);
            }
          }
        }
      }
    }
  }
}

.run-stats-row {
  display: flex !important;
  margin-top: 1rem;
  ul {
    margin-right: 1rem;
  }
  > span {
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    background-color: $orange-100;
    color: $orange-600;
    padding: 0px 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  li {
    margin-top: auto;
    margin-bottom: auto;
  }
}
