// Style Accordion
details.notification-setting-channel {
  border: 1px solid $gray-100;
  border-radius: 4px;

  &[open] {
    border-color: #afb3c7;
  }

  &:hover,
  &:focus {
    border: 1px solid $gray-100;
    border-color: #afb3c7;
    box-shadow: 0px 0px 0px 3px rgba(239, 239, 240, 1);
    animation: none;
  }

  &[open] summary {
    border-width: 0 0 1px 0 !important;
  }

  summary {
    height: 40px;

    // Override tailwind styles
    border-width: 0 !important;
    border-color: #e2e3ed !important;
    box-shadow: none !important;

    > span {
      min-height: 40px;
      max-height: 40px;
      padding: 0 16px;
    }
  }

  summary .chevron {
    --tw-rotate: -90deg;
  }

  &[open] summary .chevron {
    --tw-rotate: 0deg;
  }

  > div {
    > div {
      padding: 0;
    }
  }

  .notification-setting-channel-detail {
    height: 64px;

    div & {
      padding: 0 16px;
    }
  }
}

.global-tag-tooltip-message {
  max-width: 240px;
}
