.email-input-component {
  :global([class*='-a11yText']) {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }

  // Component base layout and padding
  :global(.email-input__control) {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 16px;

    :global(.email-input__value-container) {
      padding-left: 0;
    }
  }

  // Component cursor assignments
  :global(.email-input__control:hover) {
    cursor: text;

    :global(.email-input__clear-indicator) {
      cursor: pointer;
    }

    :global(.email-input__dropdown-indicator) {
      cursor: pointer;
    }
  }

  // Tag (MultiValue) styling
  :global(.email-input__multi-value) {
    border-radius: 4px;

    :global(.email-input__multi-value__remove-wrap) {
      display: flex;
      border-bottom-right-radius: inherit;
      border-top-right-radius: inherit;
    }

    :global(.email-input__multi-value__remove) {
      cursor: pointer;
      border-bottom-right-radius: inherit;
      border-top-right-radius: inherit;
    }
  }

  // Dropdown menu
  :global(.email-input__menu-list) {
    padding: 6px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;

    display: flex;
    flex-direction: column;

    :global(.email-input__multi-value) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: max-content;
      max-width: 90%;
      align-items: stretch;
    }
  }

  // Component should not grow and should properly overflow
  :global(.email-input__value-container) {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;

    :global(.email-input__multi-value) {
      flex-shrink: 0;
      overflow: hidden;
    }

    :global(.email-input__multi-value__label) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
