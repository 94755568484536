.code-sample {
  width: 100%;
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid $gray-100;
  background-color: $gray-A1;
  color: $gray-500;
  display: flex;
  font-weight: 400;
  color: $gray-700;
  &:hover {
    box-shadow: 0 0 0 2px $gray-50;
  }

  .decorative-icons {
    padding: 10px 12px;
    padding-right: 0;
    white-space: nowrap;
    .code-icon {
      color: $gray-500;
      margin-right: 8px;
    }
    .command-prompt {
      color: $purple-600;
      font-family: monospace;
    }
  }

  pre {
    white-space: pre-wrap;
    margin: unset;
    margin-left: 5px;
    padding: 10px 0;
    flex: 1;
  }

  .cli-sample-copy-btn {
    display: flex;
    outline: none;
    align-items: center;
    margin-left: 5px;
    border-radius: inherit;

    background-color: $indigo-50;
    color: $indigo-500;
    font-weight: normal;

    cursor: pointer;

    .copy-icon {
      margin-right: 4px;
    }
    &:hover {
      box-shadow: 0 0 0 1px $indigo-100;
    }

    .decorative-icons {
      padding: 10px 12px;
      padding-right: 0;
      white-space: nowrap;
      .code-icon {
        color: $gray-500;
        margin-right: 8px;
      }
      .command-prompt {
        color: $purple-600;
        font-family: monospace;
      }
    }

    pre {
      white-space: pre-wrap;
      margin: unset;
      margin-left: 5px;
      padding: 10px 0;
      flex: 1;
    }

    .cli-sample-copy-btn {
      display: flex;
      outline: none;
      align-items: center;
      margin-left: 5px;
      border-radius: inherit;

      background-color: $indigo-50;
      color: $indigo-500;
      font-weight: normal;

      cursor: pointer;

      .copy-icon {
        margin-right: 4px;
      }
      &:hover {
        box-shadow: 0 0 0 1px $indigo-100;
      }
    }
  }
}
