.test-definition-accordion {
  &__toggle {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__title {
    margin: 0;
  }
}
