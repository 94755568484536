ul.dropdown-menu {
  .active > a,
  .active > a:hover,
  .active > a:focus {
    background-color: $gray-100;
    color: $gray-800;
  }

  & > li > a {
    padding: 3px 12px;
  }

  & > li > ul {
    list-style: none;
    padding-left: 0;

    & > li > a {
      display: block;
      padding: 3px 12px;
      clear: both;
      color: $gray-800;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
        color: $gray-900;
        background-color: $gray-50;
      }
    }
  }
}

.dropdown.open .btn-link {
  text-decoration: underline;
}
