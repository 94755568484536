.color-picker-popover {
  &--row {
    height: 16px;
    margin-top: 8px;
  }
  .popover-content {
    padding: 8px;
  }
  .color-picker-color-sample:not(:last-of-type) {
    margin-right: 8px;
  }
}

.color-picker-color-sample {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  display: inline-block;
  text-align: right;
}
