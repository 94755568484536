.organization-selection {
  max-width: 700px;
  margin: 0 auto;
  &__description {
    margin: 0 0 24px 0;
    h1 {
      margin: 0 0 18px 0;
      font-weight: 700;
    }
    p {
      font-size: 16px;
    }
  }
  &__loader {
    margin: 0;
    .loader-bg {
      margin: 0 0 24px 0;
    }
  }
}
