@import '@frontend/design-system/src/styles/_variables';

.ciProviderGrid {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;

  .providerItem {
    flex: 0 0 auto;
    height: 128px;
    width: 128px;
    border: 1px solid $gray-100;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin-bottom: 0px;

    .icon {
      height: 48px;
      width: 48px;
    }

    .itemLabel {
      color: $indigo-500;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-top: 12px;
    }

    &:focus-within {
      border-color: $indigo-500;
    }

    &.selected {
      border: 1px solid $jade-300;
      // box-shadow color is $jade-300 with an opacity
      box-shadow: 0px 0px 0px 2px #69d3a759;

      .itemLabel {
        color: #005f39;
      }
    }

    &:hover {
      cursor: pointer;
    }

    input {
      appearance: none;
    }
  }
}
