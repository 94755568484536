.filters-container {
  display: grid;
  column-gap: 16px;
  row-gap: 4px;
  width: 100%;
  padding-bottom: 16px;
  // grid-template-areas & grid-template-columns conditionally set in .tsx

  &__view-by-header {
    grid-area: viewByHeader;
  }

  &__filters-header {
    grid-area: filtersHeader;
  }

  &__view-by-header,
  &__filters-header {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    color: $gray-600;
    display: flex;
    align-items: flex-end;
  }

  &__extra {
    grid-area: extra;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__view-by {
    grid-area: viewBy;
    margin: 0 16px 0 0;
    position: relative;
    &::after {
      content: '';
      width: 1px;
      height: 120%;
      background: $gray-200;
      position: absolute;
      right: -16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__filters {
    grid-area: filters;
  }

  .browsers-filter__label {
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  }

  .scrollable-menu-container {
    height: 40px; // Firefox
  }

  .dropdown-menu {
    z-index: 2;
  }
}
