.project-analytics {
  min-height: 100%;
  .analytic {
    padding: 24px 24px $beacon-padding;

    &__chart {
      margin-bottom: 16px;
      position: relative;
      z-index: 1;
      .panel-body {
        padding-top: 32px;
      }
      min-height: 40px;
    }
  }

  .rv-xy-plot__inner {
    overflow: visible;
  }
}

.analytic__download {
  text-align: right;
  padding: 0;
  border: none;
  background: transparent;
  height: fit-content;
  svg {
    height: 16px;
    width: 16px;
  }
}

.branches-filter,
.browses-filter,
.cypress-versions-filter,
.os-filter,
.run-groups-filter {
  .select__menu {
    min-width: 240px;
  }
}

.runs-over-time-chart {
  .panel-heading {
    display: grid;
    grid-template-columns: auto auto;
    align-content: space-between;
    align-items: center;
  }
  .chart-legend {
    text-align: right;
    display: inline-block;
    color: $gray-700;
    font-size: 12px;
  }
}

#runs-over-time-chart__tooltip,
#run-duration-chart__tooltip,
#test-suite-size-chart__tooltip,
#top-failures-chart__tooltip,
#flaky-tests-chart__tooltip {
  th {
    font-weight: 500;
    padding: 8px;
    text-align: center;
  }
  td:last-of-type {
    padding-left: 8px;
    text-align: right;
  }
  .bullet {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    display: inline-block;
    margin-right: 4px;
  }
  .color-bullet {
    background-color: $indigo-400;
  }
}
