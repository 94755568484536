@import '@frontend/design-system/src/styles/_variables';

.container {
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.darkTheme {
  .elements {
    border: 1px solid $gray-800;
  }

  .divider {
    border-top: 1px solid $gray-800;
  }
}

.row {
  display: inline-flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 0;
}

.elements {
  display: inherit;
  border-radius: 4px;
  gap: 2px;
  padding: 0 4px;
}

.divider {
  margin: 12px 0;
  padding: 0;
}
