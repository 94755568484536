.user-cell {
  .user-avatar,
  .user-info {
    display: inline-block;
    vertical-align: middle;
  }
  .user-name {
    font-weight: 500;
  }
  .user-info {
    margin-left: 4px;
  }
}

.privacy-radio {
  p.text-muted {
    margin-top: 0;
  }

  &.disabled p.text-muted {
    color: $gray-200;
  }
}

.users-stacked-layout .copy-invite-link {
  margin-right: 15px;
}
