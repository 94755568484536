@import '@frontend/design-system/src/styles/_variables';

.body {
  min-height: 204px;
  input {
    z-index: 0 !important; // .input-group .form-control have z-index 2, making the select list not visible
  }
  textarea {
    z-index: 0 !important; // .input-group .form-control have z-index 2, making the select list not visible
  }
}

.fullRowInput {
  :global(.input-form-group) {
    grid-template-columns: 1fr;
  }
}

.bodyForm {
  border-top: 1px solid $gray-200;
  :global(.input-form-group) {
    border-top: none;
  }
  :global(.input-form-group .left-column) {
    margin: 0;
  }
  :global(.input-form-group .input-form-group-contents) {
    margin: 0;
  }
}

.selectWithLabel {
  padding: 8px 0;
}

.label {
  color: $gray-800;
  font-size: 16px;
  padding: 4px 0;
}

.submitContainer {
  border-top: 1px solid $gray-200;
}

.submitBtn {
  margin-top: 8px;
  float: right;
}
