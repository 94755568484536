@import '@frontend/design-system/src/styles/_variables';

.layeredLogoWrapper {
  display: flex;
  list-style-type: none;
  margin-right: 0;
  :not(:first-child) {
    margin-left: -8px;
  }
}

.genericIconStyle {
  color: $gray-500;
}

.browserLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-50;
  border-radius: 100%;
  height: 20px;
  width: 20px;
}
