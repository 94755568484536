.list-as-table {
  width: 100%;
  clear: both;
  padding-left: 0;
  overflow: auto;
  margin-bottom: 0;

  & > li,
  & > li > a {
    background-color: $gray-A1;
    overflow: visible;
    clear: both;
    border-bottom: 1px solid $gray-50;
    display: block;
    position: relative;
    cursor: pointer;

    &:first-child {
      border-top: 1px solid $gray-50;
    }

    &:last-child {
      border-bottom: 0;
    }

    a:hover {
      text-decoration: none;
      background-color: $gray-50;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      color: $gray-700;
    }

    &.th {
      background-color: transparent;
      border-top: 1px solid $gray-200;
      color: $gray-400;
      text-transform: uppercase;
      font-size: 0.75em;

      .th-padding {
        padding: 12px 5px;
      }
    }

    & > div {
      @include text-overflow-custom;
      min-height: 1px;
      vertical-align: top;
      float: left;

      &:last-child {
        border-right: 0;
      }

      & > div {
        padding: 10px 4px;
      }
    }

    &:last-child > div > div {
      border-bottom: 0;
    }
  }
}

.preconditions {
  margin-top: 15px;
  margin-left: 20px;

  .precondition-check {
    list-style: none;
  }
}
