@import '@frontend/design-system/src/styles/_variables';

.container {
  white-space: nowrap;
}

.title {
  color: $gray-700;
  font-size: 14px;
  line-height: 16px;
}

.subtitle {
  font-size: 12px;
  line-height: 22px;
  color: $gray-600;
}
