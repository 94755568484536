.organization-switcher-summary {
  height: 64px;
  padding: 10px 16px;
  cursor: pointer;
  display: grid;
  grid-template:
    'img organization icon'
    'img user icon';
  grid-template-columns: 32px 1fr 24px;
  grid-template-rows: 26px 14px;
  grid-gap: 0 12px;
  align-items: center;

  .img {
    grid-area: img;
  }

  .org-avatar-default,
  .cy-avatar {
    margin-top: 16px;
  }

  .organization {
    grid-area: organization;
    font-size: 16px;
    font-weight: 600;
    color: $gray-50;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .user {
    grid-area: user;
    font-size: 14px;
    color: $gray-500;
  }

  svg.cy-icon {
    grid-area: icon;
    align-self: center;
    path {
      stroke: $gray-600;
    }
  }
  svg.close-icon {
    color: $gray-500;
    justify-self: center;
    align-self: center;
  }
}

.organization-switcher--menu-icon {
  grid-area: icon;
  align-self: center;
  .ripple {
    background: $orange-400;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: relative;
    border: 1px solid transparent;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      border: 1px solid $orange-400;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      animation: ripple 2s ease-out infinite;
    }
  }

  @keyframes ripple {
    13% {
      opacity: 1;
    }
    50%,
    100% {
      opacity: 0;
      height: 250%;
      width: 250%;
    }
  }
}

.organization-switcher-button {
  display: block;
  width: 100%;
  height: 64px;
  font-size: inherit;
  text-align: left;
  color: inherit;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  transition: background-color 150ms ease;

  &:hover,
  &:focus {
    color: inherit;
    background-color: $gray-900;
    text-decoration: none;
    outline: none;
  }
}

#organization-switcher-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  min-height: 300px;

  background-color: $gray-A1;
  color: $gray-800;
  margin: 8px;
  border-radius: 4px;
  box-shadow: 0 3px 8px rgba($gray-900, 0.12);
  pointer-events: none;
  transition: visibility 160ms linear, opacity 160ms ease-in-out,
    transform 160ms ease-in-out;

  opacity: 0;
  transform: translateY(8px);
  visibility: hidden;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: initial;
    visibility: visible;
  }

  .organization-switcher--section {
    margin: 16px 0;
    border-top: 1px solid $gray-200;
  }

  .organization-switcher--section-header {
    margin: 16px 16px 8px;
  }

  .organization-switcher--section-text {
    margin: 16px 16px 8px;
  }

  .organization-switcher--section-link {
    margin: 8px 0 -8px;
  }

  .organization {
    color: $gray-900 !important;
  }

  .user {
    color: $gray-700 !important;
  }

  .in-menu {
    border-radius: 4px 4px 0 0;

    &:hover,
    &:focus {
      background-color: $gray-100;
    }
  }

  .nav-link {
    color: $gray-800;
    cursor: pointer;
    padding: 8px 16px;
    display: block;

    &:hover,
    &:focus {
      color: inherit;
      background-color: $gray-50;
      text-decoration: none;
    }
  }

  .nav-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .media {
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-template-rows: min-content;
    grid-template-areas: 'img title';
    grid-gap: 0 8px;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 16px;

    &.active {
      grid-template-areas: 'img title icon';
      font-weight: 500;
    }

    .img {
      grid-area: img;
    }
    .title {
      grid-area: title;
      color: $gray-800;
    }
    .subtitle {
      grid-area: subtitle;
      color: $gray-400;
    }
  }

  .organization-switcher-container {
    padding: 4px 0;
    border-bottom: 1px solid $gray-800;
  }

  .organization-switcher-menu--content {
    overflow-y: auto;
    max-height: calc(100vh - 80px - 16px);
    border-top: 1px solid $gray-100;
    section:first-child {
      border-top: none;
    }
  }

  .organization-switcher--footer {
    padding: 8px 0;
    font-size: 0.85em;
    text-align: center;
    border-top: 1px solid $gray-200;

    li {
      display: inline-block;
    }

    .nav-link {
      padding: 8px;
    }
  }

  .pending-notifications-wrapper {
    display: grid;
    grid-template-columns: 20px 1fr;
  }

  .pending-notifications-indicator-column {
    margin-left: 15px;
    margin-top: 8px;
    .indicator-circle {
      color: $orange-400;
    }
  }

  .pending-notifications-nav-list-container {
    .notification-list {
      margin: 0;
      padding: 0;
      list-style-type: none;

      .notification-item {
        color: $gray-800;
        padding: 8px 16px;
        display: block;
        max-width: 280px;

        .form-inline {
          margin-right: 0 !important;

          .pending-installation {
            display: flex;
            margin-top: 10px;

            select:not([multiple]) {
              -webkit-appearance: none;
              -moz-appearance: none;
              background-position: right 50%;
              background-repeat: no-repeat;
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
              padding: 0.5em;
              padding-right: 1.5em;
              border-radius: 4px 0 0 4px;
              max-width: 185px;
            }

            button {
              height: 34px;
              padding: 0 5px;
              border-radius: 0 4px 4px 0;
            }
          }
        }

        .invitation-buttons {
          margin-top: 10px;

          button {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
