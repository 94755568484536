@import '@frontend/design-system/src/styles/_variables';

.container {
  border: 1px solid $gray-800;
  border-radius: 4px;
  color: $gray-500;
  font-size: 16px;
  font-weight: 500;
  margin: 12px;

  li {
    list-style-type: none;
  }
}

.titleContainer {
  height: 40px;
  margin: 8px 8px 0;
  padding: 8px 0px 0px 12px;
}

.title {
  color: $gray-300;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.tag {
  margin-left: 10px;
}

.dismiss {
  background-color: inherit;
  border: none !important;
  color: $gray-500 !important;
  float: right;
}

.item {
  color: $gray-500;
  display: block;
  height: 40px;
  padding: 8px 4px 8px 12px;
  margin: 8px;
  text-decoration: none;
  text-shadow: 0 1px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition:
    color 150ms ease,
    background-color 150ms ease;

  &:hover {
    color: $gray-500;
    text-decoration: none;

    .openLinkIcon {
      visibility: visible;
    }
  }

  &:active,
  &:focus {
    color: $indigo-300;
    text-decoration: none;

    .openLinkIcon {
      visibility: visible;
    }
  }

  .productIcon {
    height: 16px;
    margin: 0 16px 0 0 !important;
    text-align: center;
    vertical-align: middle;
    width: 16px;
  }

  .openLinkIcon {
    float: right;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    visibility: hidden;
  }
}
