@import '@frontend/design-system/src/styles/_variables';

.inviteUserModal {
  * ::selection {
    background-color: rgba(208, 210, 224, 0.3);
  }

  :global(.modal-dialog) {
    width: 960px;
  }

  :global(.modal-body) {
    padding: 0;
  }

  :global(.input-form-group-container:first-child .input-form-group) {
    border-top: none;
  }
}

.modalFooter {
  background-color: #f3f4fa;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.usersLimitMessage {
  line-height: 40px;
  font-size: 0.9em;
}
