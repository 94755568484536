@import '@frontend/design-system/src/styles/_variables';

@keyframes bounce {
  0%,
  6%,
  100% {
    transform: translateY(0);
  }
  3% {
    transform: translateY(-25%);
  }
}

.icon-waiting {
  circle {
    fill: $gray-400;
  }
  &-dot-1 {
    animation: bounce 5s ease-in-out 0s infinite;
  }
  &-dot-2 {
    animation: bounce 5s ease-in-out 200ms infinite;
  }
  &-dot-3 {
    animation: bounce 5s ease-in-out 400ms infinite;
  }
}
