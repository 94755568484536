.sidebar-footer {
  &__links-container {
    display: flex;
    justify-content: space-around;
    padding: 16px 0;
  }
  &__link-container {
    margin: 0;
    color: $gray-A1;
  }
  &__link-icon {
    margin: 0 8px 0 0;
  }
  &__link {
    color: $gray-A1;
    font-weight: 400;
    &:hover {
      color: $gray-A1;
    }
  }
  &__nav-logo {
    text-align: center;
    padding: 0 0 24px 0;
    border-bottom: 1px solid rgba($gray-900, 80%);
    img {
      max-width: 100px;
    }
  }
  &__pendo-target-test-replay-enabled {
    position: absolute;
    background: transparent;
    width: 1px;
    height: 1px;
  }
}
