form {
  overflow: auto;

  .radio label {
    padding-left: 21px;
  }
}

.form-bottom {
  clear: both;
  overflow: auto;
  margin-top: 20px;
  padding: 0;

  .btn-primary {
    margin-top: 0;
  }
}

input:invalid {
  border-color: $red-700;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(204, 66, 57, 0.6);
}

.form-group.is-required {
  .control-label::after {
    content: '*';
    margin-left: 2px;
    color: $state-danger-text;
  }
}

.form-group.label-optional {
  font-size: 15px;
  color: $gray-400;
  margin-left: 4px;
}
