.verify-page {
  min-height: 100vh;
  background-color: #fff;
  position: relative;

  .verify-page-inner {
    width: 90%;
    max-width: 437px;
    margin: 80px auto 0;

    .btn-resend {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 0;
    }
  }
}
