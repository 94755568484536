.flakyBadge {
  margin: 0 0 0 10px;
}

.specFile {
  display: flex;
  align-items: center;
  word-break: break-all;
}

.specFileListItem {
  position: relative;
  left: -2.14286em;
}
