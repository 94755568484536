.drill-in-drawer--resizable {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  min-width: 0;
  height: 100vh;
  min-height: 100vh;
  z-index: 3;
  transform: translateX(-50%);
  &.draggable {
    width: 12px;
    min-width: 12px;
    cursor: ew-resize;
  }
}

.drill-in-drawer {
  position: relative;
  overflow-y: scroll;
  &__close-btn-container {
    position: relative;
  }
  &__close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    color: $gray-400;
  }
  &__inner {
    padding-bottom: $beacon-padding;
  }
}
