@import '@frontend/design-system/src/styles/_variables';

.testDrawerHeader {
  background-color: $gray-A1;
  border-bottom: 1px solid $gray-200;
  position: sticky;
  top: 0;
  z-index: 2;
}

.testDrawerHeaderShadow {
  box-shadow: $box-shadow;
}

.titleContainer {
  padding: 16px 32px 16px 16px; // leave space for close button
}

.title {
  display: flex;
  margin-bottom: 4px;
  :global(.status-icon) {
    margin: 2px 4px 0 0;
  }
}

.status {
  height: fit-content;
}

.specFile {
  color: $gray-700;
  font-size: 12px;
  margin-left: 20px;
}
