.over-recordings-notice.banner {
  display: inline-block;
  > div {
    display: inline;
  }
  .btn-link {
    color: $gray-A1;
    padding: 6px 16px 8px;
  }
}
