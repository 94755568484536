.welcome-page {
  min-height: 100vh;
  background-color: #fff;
  position: relative;
  .page-header stacked-layout--header {
    width: 100%;
  }
  .welcome-page-inner {
    width: 90%;
    max-width: 700px;
    margin: 0 auto;
  }
  .h3 {
    margin-top: 64px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 45px;
    color: #000;
  }
  .h5 {
    margin-top: 0;
    margin-bottom: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $gray-600;
  }
  img {
    height: 32px;
  }
  .well {
    margin-top: -16px;
  }
}

@media (max-width: 700px) {
  .welcome {
    .h3 {
      margin-top: 32px;
    }
    .h5 {
      margin-bottom: 16px;
    }
  }
}

.onboarding-panel {
  z-index: 2;
  section {
    margin-bottom: 32px;
  }
  &__question {
    font-size: 16px;
  }
  &__name {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    margin-top: 8px;
  }
  &__job-responsibilities {
    .text-muted {
      font-size: 12px;
    }
  }
  .onboarding-footer {
    text-align: center;
    font-size: 12px;
    background-color: #fff;
    color: $gray-500;
    .btn {
      margin-top: 20px;
      width: 100%;
    }
  }
  .job-responsibilities {
    margin-top: 16px;
  }
}
