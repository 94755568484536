.org-logo-upload {
  border: 1px solid $gray-200;
  border-radius: $border-radius-base;

  // Generated checkerboard background
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg fill='%23f7f7fa' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h8v8H0V0zm8 8h8v8H8V8z'/%3E%3C/g%3E%3C/svg%3E");

  .org-logo-upload--logo {
    border-bottom: 1px dotted $gray-200;
    padding: 16px;
    text-align: center;
  }

  .org-logo-upload--actions {
    display: flex;
    padding: 8px;
    background-color: $gray-50;
    border-bottom-left-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
  }
}

.ReactCrop--rounded {
  div {
    border-radius: 50%;
  }
}
