.test-result-modification-types {
  &__container {
    display: flex;
    align-items: center;
    background: $gray-50;
    padding: 16px;
    i {
      color: $indigo-600;
    }
    p {
      color: $gray-700;
      font-size: 12px;
      font-weight: 700;
      margin: 0 8px 0 0;
    }
  }
  &__type {
    background: transparent;
    outline: none;
    border: none;
    margin: 0 4px 0 0;
  }
  &__type-label {
    color: $gray-A1;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 10px;
    border-radius: 9px;
  }
}
